// stubs
import {
  ClosedDateWithReason,
  GetAvailabilityResponse,
  GetClosedDatesResponse,
  GetHolidaysResponse,
  OpenDayOfWeek,
} from '@nxcr-org/web-api-interface/lib/dealer_pb'
import { AvailableTime } from '@nxcr-org/web-api-interface/lib/appointment_pb'

export const mockOpenDaysOfWeek: OpenDayOfWeek.AsObject[] = [
  {
    dayOfWeek: AvailableTime.DayOfWeek.MONDAY,
    startTime: '09:00',
    endTime: '17:00',
    timezone: 'UTC'
  }, {
    dayOfWeek: AvailableTime.DayOfWeek.TUESDAY,
    startTime: '09:00',
    endTime: '17:00',
    timezone: 'UTC'
  }, {
    dayOfWeek: AvailableTime.DayOfWeek.WEDNESDAY,
    startTime: '09:00',
    endTime: '17:00',
    timezone: 'UTC'
  }, {
    dayOfWeek: AvailableTime.DayOfWeek.THURSDAY,
    startTime: '09:00',
    endTime: '17:00',
    timezone: 'UTC'
  }, {
    dayOfWeek: AvailableTime.DayOfWeek.FRIDAY,
    startTime: '09:00',
    endTime: '17:00',
    timezone: 'UTC'
  }
]

export const mockCalendar = {
  year: 2022,
  month: 7,
  datesList: [
    {
      description: 'I should not be visible',
      datetype: 'Autonomy Holiday A',
      datestring: '2021-06-18',
      dow: 4,
      month: 6,
      day: 18,
      year: 2022
    },
    {
      description: 'Autonomy Holiday B',
      datetype: 'Holiday',
      datestring: '2022-09-29',
      dow: 4,
      month: 6,
      day: 18,
      year: 2022
    },
    {
      description: 'Autonomy Holiday C',
      datetype: 'Holiday',
      datestring: '2022-10-29',
      dow: 4,
      month: 6,
      day: 19,
      year: 2022
    }
  ],
  timezone: 'UTC',
  dateformat: 'yyyy-MM-dd'
}

export const mockClosedDateWithReasonList: ClosedDateWithReason.AsObject[] = [
  {
    date: '2022-10-10',
    reason: 'Extreme weather'
  },
  {
    date: '2022-10-20',
    reason: 'Special reason'
  }
]

export const mockClosedDatesResponse: GetClosedDatesResponse.AsObject = {
  closedDatesList: [], // deprecated
  closedDatesWithReasonsList: mockClosedDateWithReasonList,
  dealershipId: 'b1d68de5-0f55-4470-9030-a5d3d7b3d84b',
  error: undefined,
  errorCode: undefined,
  success: true
}

export const mockGetHolidaysResponse: GetHolidaysResponse.AsObject = {
  calendar: mockCalendar,
  error: undefined,
  errorCode: undefined,
  success: true
}

export const getAvailabilityResponse: GetAvailabilityResponse.AsObject = {
  success: true,
  dealershipId: 'b1d68de5-0f55-4470-9030-a5d3d7b3d84b',
  openDaysOfWeekList: mockOpenDaysOfWeek,
  error: undefined,
  errorCode: undefined
}

