import { useState } from 'react'
import { useCustomerContext } from './CustomerContext'
import toast from 'react-hot-toast'
import { NexusService } from 'global-apis/nexus-service'
import { Address } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { useQueryClient } from 'react-query'

function useCustomerInfo() {
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [isUpdateAddOpen, setIsUpdateAddOpen] = useState<string | boolean>(
    false
  )
  const queryClient = useQueryClient()
  const handleCloseFakePhone = (val) => setPhoneNumber(val)

  const { customerId, updateCustomerWithFakePhone, customerInfo } =
    useCustomerContext()

  const onUpdateFakePhone = async () => {
    try {
      const result = await updateCustomerWithFakePhone(customerId)
      if (result) {
        setPhoneNumber(null)
      }
    } catch (error) {
      console.error('Error updating: ', error)
      toast.error('An error occurred while updating the record.')
    }
  }

  const onToggleUpdateAddress = (val: string | boolean) => {
    setIsUpdateAddOpen(val)
  }

  const onHandleUpdateAddress = async (data) => {
    try {
      const result = await NexusService.editCustomerAddresses(customerId, data)
      if (result) {
        queryClient.invalidateQueries('/customers')
        toast.success(`${isUpdateAddOpen} has been updated successfully.`)
        setIsUpdateAddOpen(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAddress = (address: string, id: string, addressType: number) => {
    const breakAdd = address.split(',')
    return {
      street: breakAdd[0]?.trim() || '',
      street2: breakAdd[1]?.trim() || '',
      city: breakAdd[2]?.trim() || '',
      state: breakAdd[3]?.trim() || '',
      postal: breakAdd[4]?.trim() || '',
      id: id,
      addressType: addressType,
    }
  }

  const getDefaultValues = () => {
    if (isUpdateAddOpen === 'Parking Address') {
      return getAddress(
        customerInfo?.parkingAddress,
        customerInfo.parkingAddressId,
        Address.AddressType.PARKING
      )
    }
    if (isUpdateAddOpen === 'License Address') {
      return getAddress(
        customerInfo?.licenseAddress,
        customerInfo.licenseAddressId,
        Address.AddressType.LICENSE
      )
    }
    if (isUpdateAddOpen === 'Contact Address') {
      return getAddress(
        customerInfo?.contactAddress,
        customerInfo.contactAddressId,
        Address.AddressType.CONTACT
      )
    }
    return {}
  }

  return {
    onUpdateFakePhone,
    handleCloseFakePhone,
    phoneNumber,
    onToggleUpdateAddress,
    isUpdateAddOpen,
    onHandleUpdateAddress,
    getDefaultValues,
  }
}

export default useCustomerInfo
