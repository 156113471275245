import { useQuery, useIsFetching } from 'react-query'
import { map } from 'lodash'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { ReservationListItem } from '@nxcr-org/web-api-interface/lib/subscription_service_pb'
import { ReservationService } from '../../api/reservation-service'

export function useReservationsTable() {
  const [{ page, rowsPerPage, search, programId }, setQueryParams] =
    useQueryParams({
      search: StringParam,
      page: withDefault(NumberParam, 0),
      rowsPerPage: withDefault(NumberParam, 100),
      programId: withDefault(StringParam, 'all'),
    })
  // note: we should useQuery here later on when we move to NexusService?

  const setPage = (nextPage: number) => {
    setQueryParams({ page: nextPage })
  }

  const setRowsPerPage = (nextRowsPerPage: number) => {
    setQueryParams({ rowsPerPage: nextRowsPerPage })
  }

  const setQuery = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams({ search: evt.target.value })
  }

  const setProgram = (newProgramId: string) => {
    setQueryParams({ programId: newProgramId ? newProgramId : 'all' })
  }

  const isFetching = useIsFetching()

  const { data: programs } = useQuery(
    ['/listPrograms'],
    () => {
      return ReservationService.listPrograms()
    },
    {
      placeholderData: {
        programsList: [],
      },
    }
  )

  const { data: reservations } = useQuery(
    ['/reservations', { search, page, rowsPerPage, programId }],
    () => {
      return ReservationService.getReservations({
        // calculate the index of the last item on the requested page
        pageState: (rowsPerPage * page).toString(10),
        pageSize: rowsPerPage,
        programId,
        query: search,
      }).then((res) => {
        return {
          total: res.total,
          reservations: createReservationRows(res.reservationsList),
        }
      })
    },
    {
      placeholderData: {
        total: 0,
        reservations: [],
      },
    }
  )

  return {
    total: reservations?.total || 0,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    reservations: reservations?.reservations || [],
    setQuery,
    setProgram,
    search,
    programs: programs || { programsList: [] },
    programId,
    isLoading: isFetching > 0,
  }
}

function createReservationRows(reservations: ReservationListItem.AsObject[]) {
  return map(reservations, (reservation) => {
    const customerName = `${reservation?.firstName} ${reservation?.lastName}`
    const customerId = `${reservation?.customerId}`
    return {
      id: reservation.reservationId,
      accountNumber: reservation.accountNumber,
      customerId,
      customerName,
      make: reservation.make,
      model: reservation.model,
      isPending: false,
      status: reservation.status,
      reservationId: reservation.reservationId,
      subscriptionId: reservation.customerId,
      waitlistStart: reservation.waitlistStart,
      color: reservation.color || 'N/A',
      zipcode: reservation.postal,
    }
  })
}
