import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { FormattedReservationResponse } from '../../useReservation'
import { ActivityState } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { find } from 'lodash'
import { NexusService } from 'global-apis/nexus-service'
import { PricingOption } from '@nxcr-org/web-api-interface/lib/subscription_pb'

export function useProgramPricingBlock(
  reservation: FormattedReservationResponse
) {
  const vehicleOptions = reservation?.vehicle

  const [isEdit, setIsEdit] = useState(false)
  const [monthlyPayment, setMonthly] = useState(vehicleOptions.monthly)
  const [startFee, setStartFee] = useState(vehicleOptions.startFee)
  const [selectedOption, setSelectedOption] =
    useState<PricingOption.AsObject>(null)

  useEffect(() => {
    setMonthly(vehicleOptions.monthly)
    setStartFee(vehicleOptions.startFee)
  }, [vehicleOptions.monthly, vehicleOptions.startFee])

  const { data: programOptions, error: programOptionsError } = useQuery(
    ['/programOptions'],
    () => {
      return NexusService.listProgramPricing()
    },
    {
      placeholderData: {
        programsList: [],
      },
      onError() {
        console.log('Error: ', programOptionsError)
      },
    }
  )

  const handleChange = (e) => {
    const selectedOption = find(
      getPricingOptionsForProgram(
        programOptions?.programsList,
        reservation?.programId
      ),
      {
        id: e.target.value,
      }
    )

    setSelectedOption(selectedOption)
    setMonthly(selectedOption.monthly)
    setStartFee(selectedOption.startFee)
  }

  const cancelChanges = () => {
    setIsEdit(false)
    setSelectedOption(null)
    setMonthly(vehicleOptions.monthly)
    setStartFee(vehicleOptions.startFee)
  }

  const isValidSubscriptionStatus = (reservation) => {
    return (
      reservation.customer.activityState ===
        ActivityState.SUBSCRIPTION_ACTIVATED ||
      reservation.customer.activityState ===
        ActivityState.SUBSCRIPTION_CANCELLED
    )
  }

  const savePricing = useMutation(() => {
    if (!selectedOption?.id) {
      return Promise.reject('Select a new pricing option or cancel')
    }

    return NexusService.UpdateProgramReservation({
      reservationId: reservation?.reservation.id,
      deposit: vehicleOptions?.deposit,
      color: reservation?.reservation?.color, // using model options object , in previous implimetation only model3 properties were used.
      secondaryColor: reservation?.reservation?.secondaryColor,
      startFee,
      monthlyRent: monthlyPayment,
      make: reservation?.reservation.make,
      model: reservation?.reservation.model,
      postal: reservation?.reservation?.postal,
      trackingId: reservation?.reservation?.id,
      programId: reservation?.programId,
    })
  })
  return {
    isEdit,
    setIsEdit,
    monthlyPayment,
    setMonthly,
    startFee,
    setStartFee,
    handleChange,
    cancelChanges,
    savePricing,
    isValidSubscriptionStatus,
    programPricingOptions: getPricingOptionsForProgram(
      programOptions?.programsList,
      reservation?.programId
    ),
  }
}

export function getPricingOptionsForProgram(programOptions, programId: string) {
  if (!programOptions?.length || !programId) {
    return []
  }

  const programOption = programOptions?.find(
    (option) => option?.id === programId
  )
  const pricingOptions =
    programOption?.pricingOptionsList[0]?.pricingOptionsList

  return pricingOptions
}
