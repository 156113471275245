// source: dealer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
goog.exportSymbol('proto.dealer.Calendar', null, global);
goog.exportSymbol('proto.dealer.ClosedDateWithReason', null, global);
goog.exportSymbol('proto.dealer.Date', null, global);
goog.exportSymbol('proto.dealer.Dealership', null, global);
goog.exportSymbol('proto.dealer.Dealership.Activity', null, global);
goog.exportSymbol('proto.dealer.DealershipCreated', null, global);
goog.exportSymbol('proto.dealer.DealershipUpdated', null, global);
goog.exportSymbol('proto.dealer.DealershipUser', null, global);
goog.exportSymbol('proto.dealer.DealershipUser.Activity', null, global);
goog.exportSymbol('proto.dealer.DealershipUserCreated', null, global);
goog.exportSymbol('proto.dealer.DealershipUserUpdated', null, global);
goog.exportSymbol('proto.dealer.GetAvailabilityResponse', null, global);
goog.exportSymbol('proto.dealer.GetClosedDatesResponse', null, global);
goog.exportSymbol('proto.dealer.GetHolidaysResponse', null, global);
goog.exportSymbol('proto.dealer.OpenDayOfWeek', null, global);
goog.exportSymbol('proto.dealer.OpenDayOfWeek.DayOfWeek', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.Dealership = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.Dealership, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.Dealership.displayName = 'proto.dealer.Dealership';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.DealershipUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer.DealershipUser.repeatedFields_, null);
};
goog.inherits(proto.dealer.DealershipUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.DealershipUser.displayName = 'proto.dealer.DealershipUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.ClosedDateWithReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.ClosedDateWithReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.ClosedDateWithReason.displayName = 'proto.dealer.ClosedDateWithReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.OpenDayOfWeek = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.OpenDayOfWeek, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.OpenDayOfWeek.displayName = 'proto.dealer.OpenDayOfWeek';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.Calendar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer.Calendar.repeatedFields_, null);
};
goog.inherits(proto.dealer.Calendar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.Calendar.displayName = 'proto.dealer.Calendar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.Date = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.Date, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.Date.displayName = 'proto.dealer.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.GetClosedDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer.GetClosedDatesResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer.GetClosedDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.GetClosedDatesResponse.displayName = 'proto.dealer.GetClosedDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.GetAvailabilityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dealer.GetAvailabilityResponse.repeatedFields_, null);
};
goog.inherits(proto.dealer.GetAvailabilityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.GetAvailabilityResponse.displayName = 'proto.dealer.GetAvailabilityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.GetHolidaysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.GetHolidaysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.GetHolidaysResponse.displayName = 'proto.dealer.GetHolidaysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.DealershipCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.DealershipCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.DealershipCreated.displayName = 'proto.dealer.DealershipCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.DealershipUserCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.DealershipUserCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.DealershipUserCreated.displayName = 'proto.dealer.DealershipUserCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.DealershipUserUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.DealershipUserUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.DealershipUserUpdated.displayName = 'proto.dealer.DealershipUserUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dealer.DealershipUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dealer.DealershipUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dealer.DealershipUpdated.displayName = 'proto.dealer.DealershipUpdated';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.Dealership.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.Dealership.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.Dealership} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.Dealership.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: (f = msg.getAddress()) && domain_pb.Address.toObject(includeInstance, f),
    latitude: jspb.Message.getFieldWithDefault(msg, 5, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 6, ""),
    timeZone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    version: jspb.Message.getFieldWithDefault(msg, 8, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.Dealership}
 */
proto.dealer.Dealership.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.Dealership;
  return proto.dealer.Dealership.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.Dealership} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.Dealership}
 */
proto.dealer.Dealership.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 4:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 9:
      var value = /** @type {!proto.dealer.Dealership.Activity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.Dealership.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.Dealership.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.Dealership} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.Dealership.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dealer.Dealership.Activity = {
  DEACTIVATED: 0,
  ACTIVATED: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.dealer.Dealership.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dealer.Dealership.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_number = 3;
 * @return {string}
 */
proto.dealer.Dealership.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional domain.Address address = 4;
 * @return {?proto.domain.Address}
 */
proto.dealer.Dealership.prototype.getAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 4));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.dealer.Dealership} returns this
*/
proto.dealer.Dealership.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer.Dealership.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string latitude = 5;
 * @return {string}
 */
proto.dealer.Dealership.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string longitude = 6;
 * @return {string}
 */
proto.dealer.Dealership.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string time_zone = 7;
 * @return {string}
 */
proto.dealer.Dealership.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 version = 8;
 * @return {number}
 */
proto.dealer.Dealership.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Activity activity = 9;
 * @return {!proto.dealer.Dealership.Activity}
 */
proto.dealer.Dealership.prototype.getActivity = function() {
  return /** @type {!proto.dealer.Dealership.Activity} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.dealer.Dealership.Activity} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional int64 updated_time = 10;
 * @return {number}
 */
proto.dealer.Dealership.prototype.getUpdatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Dealership} returns this
 */
proto.dealer.Dealership.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer.DealershipUser.repeatedFields_ = [7,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.DealershipUser.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.DealershipUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.DealershipUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    workEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    responsibilityList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    nxcrInvitation: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    dealerAppInvitation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    version: jspb.Message.getFieldWithDefault(msg, 10, 0),
    activity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    termsOfService: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    privacyPolicy: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    eSignature: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
    vendorAccountsList: jspb.Message.toObjectList(msg.getVendorAccountsList(),
    domain_pb.VendorAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.DealershipUser}
 */
proto.dealer.DealershipUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.DealershipUser;
  return proto.dealer.DealershipUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.DealershipUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.DealershipUser}
 */
proto.dealer.DealershipUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addResponsibility(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNxcrInvitation(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDealerAppInvitation(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 11:
      var value = /** @type {!proto.dealer.DealershipUser.Activity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTermsOfService(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivacyPolicy(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setESignature(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedTime(value);
      break;
    case 16:
      var value = new domain_pb.VendorAccount;
      reader.readMessage(value,domain_pb.VendorAccount.deserializeBinaryFromReader);
      msg.addVendorAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.DealershipUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.DealershipUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.DealershipUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWorkEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResponsibilityList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getNxcrInvitation();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDealerAppInvitation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getTermsOfService();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPrivacyPolicy();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getESignature();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getVendorAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      domain_pb.VendorAccount.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.dealer.DealershipUser.Activity = {
  DEACTIVATED: 0,
  ACTIVATED: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.dealer.DealershipUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer.DealershipUser.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.dealer.DealershipUser.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.dealer.DealershipUser.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cell_phone = 5;
 * @return {string}
 */
proto.dealer.DealershipUser.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string work_email = 6;
 * @return {string}
 */
proto.dealer.DealershipUser.prototype.getWorkEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setWorkEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string responsibility = 7;
 * @return {!Array<string>}
 */
proto.dealer.DealershipUser.prototype.getResponsibilityList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setResponsibilityList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.addResponsibility = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.clearResponsibilityList = function() {
  return this.setResponsibilityList([]);
};


/**
 * optional bool nxcr_invitation = 8;
 * @return {boolean}
 */
proto.dealer.DealershipUser.prototype.getNxcrInvitation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setNxcrInvitation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool dealer_app_invitation = 9;
 * @return {boolean}
 */
proto.dealer.DealershipUser.prototype.getDealerAppInvitation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setDealerAppInvitation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 version = 10;
 * @return {number}
 */
proto.dealer.DealershipUser.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Activity activity = 11;
 * @return {!proto.dealer.DealershipUser.Activity}
 */
proto.dealer.DealershipUser.prototype.getActivity = function() {
  return /** @type {!proto.dealer.DealershipUser.Activity} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.dealer.DealershipUser.Activity} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool terms_of_service = 12;
 * @return {boolean}
 */
proto.dealer.DealershipUser.prototype.getTermsOfService = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setTermsOfService = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool privacy_policy = 13;
 * @return {boolean}
 */
proto.dealer.DealershipUser.prototype.getPrivacyPolicy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setPrivacyPolicy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool e_signature = 14;
 * @return {boolean}
 */
proto.dealer.DealershipUser.prototype.getESignature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setESignature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int64 updated_time = 15;
 * @return {number}
 */
proto.dealer.DealershipUser.prototype.getUpdatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated domain.VendorAccount vendor_accounts = 16;
 * @return {!Array<!proto.domain.VendorAccount>}
 */
proto.dealer.DealershipUser.prototype.getVendorAccountsList = function() {
  return /** @type{!Array<!proto.domain.VendorAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.VendorAccount, 16));
};


/**
 * @param {!Array<!proto.domain.VendorAccount>} value
 * @return {!proto.dealer.DealershipUser} returns this
*/
proto.dealer.DealershipUser.prototype.setVendorAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.domain.VendorAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.VendorAccount}
 */
proto.dealer.DealershipUser.prototype.addVendorAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.domain.VendorAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer.DealershipUser} returns this
 */
proto.dealer.DealershipUser.prototype.clearVendorAccountsList = function() {
  return this.setVendorAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.ClosedDateWithReason.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.ClosedDateWithReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.ClosedDateWithReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.ClosedDateWithReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.ClosedDateWithReason}
 */
proto.dealer.ClosedDateWithReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.ClosedDateWithReason;
  return proto.dealer.ClosedDateWithReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.ClosedDateWithReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.ClosedDateWithReason}
 */
proto.dealer.ClosedDateWithReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.ClosedDateWithReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.ClosedDateWithReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.ClosedDateWithReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.ClosedDateWithReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.dealer.ClosedDateWithReason.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.ClosedDateWithReason} returns this
 */
proto.dealer.ClosedDateWithReason.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.dealer.ClosedDateWithReason.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.ClosedDateWithReason} returns this
 */
proto.dealer.ClosedDateWithReason.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.OpenDayOfWeek.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.OpenDayOfWeek.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.OpenDayOfWeek} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.OpenDayOfWeek.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayOfWeek: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.OpenDayOfWeek}
 */
proto.dealer.OpenDayOfWeek.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.OpenDayOfWeek;
  return proto.dealer.OpenDayOfWeek.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.OpenDayOfWeek} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.OpenDayOfWeek}
 */
proto.dealer.OpenDayOfWeek.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dealer.OpenDayOfWeek.DayOfWeek} */ (reader.readEnum());
      msg.setDayOfWeek(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.OpenDayOfWeek.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.OpenDayOfWeek.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.OpenDayOfWeek} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.OpenDayOfWeek.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDayOfWeek();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.dealer.OpenDayOfWeek.DayOfWeek = {
  DAY_OF_WEEK_UNSPECIFIED: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};

/**
 * optional DayOfWeek day_of_week = 1;
 * @return {!proto.dealer.OpenDayOfWeek.DayOfWeek}
 */
proto.dealer.OpenDayOfWeek.prototype.getDayOfWeek = function() {
  return /** @type {!proto.dealer.OpenDayOfWeek.DayOfWeek} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dealer.OpenDayOfWeek.DayOfWeek} value
 * @return {!proto.dealer.OpenDayOfWeek} returns this
 */
proto.dealer.OpenDayOfWeek.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string start_time = 2;
 * @return {string}
 */
proto.dealer.OpenDayOfWeek.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.OpenDayOfWeek} returns this
 */
proto.dealer.OpenDayOfWeek.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_time = 3;
 * @return {string}
 */
proto.dealer.OpenDayOfWeek.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.OpenDayOfWeek} returns this
 */
proto.dealer.OpenDayOfWeek.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.dealer.OpenDayOfWeek.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.OpenDayOfWeek} returns this
 */
proto.dealer.OpenDayOfWeek.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer.Calendar.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.Calendar.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.Calendar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.Calendar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.Calendar.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.dealer.Date.toObject, includeInstance),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateformat: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.Calendar}
 */
proto.dealer.Calendar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.Calendar;
  return proto.dealer.Calendar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.Calendar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.Calendar}
 */
proto.dealer.Calendar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = new proto.dealer.Date;
      reader.readMessage(value,proto.dealer.Date.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateformat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.Calendar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.Calendar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.Calendar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.Calendar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dealer.Date.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateformat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.dealer.Calendar.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Calendar} returns this
 */
proto.dealer.Calendar.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.dealer.Calendar.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Calendar} returns this
 */
proto.dealer.Calendar.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Date dates = 3;
 * @return {!Array<!proto.dealer.Date>}
 */
proto.dealer.Calendar.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.dealer.Date>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer.Date, 3));
};


/**
 * @param {!Array<!proto.dealer.Date>} value
 * @return {!proto.dealer.Calendar} returns this
*/
proto.dealer.Calendar.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dealer.Date=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.Date}
 */
proto.dealer.Calendar.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dealer.Date, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer.Calendar} returns this
 */
proto.dealer.Calendar.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};


/**
 * optional string timeZone = 4;
 * @return {string}
 */
proto.dealer.Calendar.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Calendar} returns this
 */
proto.dealer.Calendar.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dateFormat = 5;
 * @return {string}
 */
proto.dealer.Calendar.prototype.getDateformat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Calendar} returns this
 */
proto.dealer.Calendar.prototype.setDateformat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.Date.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.Date.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.Date} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.Date.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datestring: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dow: jspb.Message.getFieldWithDefault(msg, 4, 0),
    month: jspb.Message.getFieldWithDefault(msg, 5, 0),
    day: jspb.Message.getFieldWithDefault(msg, 6, 0),
    year: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.Date}
 */
proto.dealer.Date.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.Date;
  return proto.dealer.Date.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.Date}
 */
proto.dealer.Date.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestring(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.Date.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.Date.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatetype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatestring();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDow();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.dealer.Date.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateType = 2;
 * @return {string}
 */
proto.dealer.Date.prototype.getDatetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setDatetype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dateString = 3;
 * @return {string}
 */
proto.dealer.Date.prototype.getDatestring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setDatestring = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 dow = 4;
 * @return {number}
 */
proto.dealer.Date.prototype.getDow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setDow = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 month = 5;
 * @return {number}
 */
proto.dealer.Date.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 day = 6;
 * @return {number}
 */
proto.dealer.Date.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 year = 7;
 * @return {number}
 */
proto.dealer.Date.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.Date} returns this
 */
proto.dealer.Date.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer.GetClosedDatesResponse.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.GetClosedDatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.GetClosedDatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.GetClosedDatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.GetClosedDatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closedDatesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    closedDatesWithReasonsList: jspb.Message.toObjectList(msg.getClosedDatesWithReasonsList(),
    proto.dealer.ClosedDateWithReason.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.GetClosedDatesResponse}
 */
proto.dealer.GetClosedDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.GetClosedDatesResponse;
  return proto.dealer.GetClosedDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.GetClosedDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.GetClosedDatesResponse}
 */
proto.dealer.GetClosedDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addClosedDates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = new proto.dealer.ClosedDateWithReason;
      reader.readMessage(value,proto.dealer.ClosedDateWithReason.deserializeBinaryFromReader);
      msg.addClosedDatesWithReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.GetClosedDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.GetClosedDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.GetClosedDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.GetClosedDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClosedDatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getClosedDatesWithReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.dealer.ClosedDateWithReason.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer.GetClosedDatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer.GetClosedDatesResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string closed_dates = 3;
 * @return {!Array<string>}
 */
proto.dealer.GetClosedDatesResponse.prototype.getClosedDatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.setClosedDatesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.addClosedDates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.clearClosedDatesList = function() {
  return this.setClosedDatesList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer.GetClosedDatesResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer.GetClosedDatesResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated ClosedDateWithReason closed_dates_with_reasons = 6;
 * @return {!Array<!proto.dealer.ClosedDateWithReason>}
 */
proto.dealer.GetClosedDatesResponse.prototype.getClosedDatesWithReasonsList = function() {
  return /** @type{!Array<!proto.dealer.ClosedDateWithReason>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer.ClosedDateWithReason, 6));
};


/**
 * @param {!Array<!proto.dealer.ClosedDateWithReason>} value
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
*/
proto.dealer.GetClosedDatesResponse.prototype.setClosedDatesWithReasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dealer.ClosedDateWithReason=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.ClosedDateWithReason}
 */
proto.dealer.GetClosedDatesResponse.prototype.addClosedDatesWithReasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dealer.ClosedDateWithReason, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer.GetClosedDatesResponse} returns this
 */
proto.dealer.GetClosedDatesResponse.prototype.clearClosedDatesWithReasonsList = function() {
  return this.setClosedDatesWithReasonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dealer.GetAvailabilityResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.GetAvailabilityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.GetAvailabilityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.GetAvailabilityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.GetAvailabilityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dealershipId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    openDaysOfWeekList: jspb.Message.toObjectList(msg.getOpenDaysOfWeekList(),
    proto.dealer.OpenDayOfWeek.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.GetAvailabilityResponse}
 */
proto.dealer.GetAvailabilityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.GetAvailabilityResponse;
  return proto.dealer.GetAvailabilityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.GetAvailabilityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.GetAvailabilityResponse}
 */
proto.dealer.GetAvailabilityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealershipId(value);
      break;
    case 3:
      var value = new proto.dealer.OpenDayOfWeek;
      reader.readMessage(value,proto.dealer.OpenDayOfWeek.deserializeBinaryFromReader);
      msg.addOpenDaysOfWeek(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.GetAvailabilityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.GetAvailabilityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.GetAvailabilityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.GetAvailabilityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDealershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOpenDaysOfWeekList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dealer.OpenDayOfWeek.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer.GetAvailabilityResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.GetAvailabilityResponse} returns this
 */
proto.dealer.GetAvailabilityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string dealership_id = 2;
 * @return {string}
 */
proto.dealer.GetAvailabilityResponse.prototype.getDealershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.GetAvailabilityResponse} returns this
 */
proto.dealer.GetAvailabilityResponse.prototype.setDealershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OpenDayOfWeek open_days_of_week = 3;
 * @return {!Array<!proto.dealer.OpenDayOfWeek>}
 */
proto.dealer.GetAvailabilityResponse.prototype.getOpenDaysOfWeekList = function() {
  return /** @type{!Array<!proto.dealer.OpenDayOfWeek>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dealer.OpenDayOfWeek, 3));
};


/**
 * @param {!Array<!proto.dealer.OpenDayOfWeek>} value
 * @return {!proto.dealer.GetAvailabilityResponse} returns this
*/
proto.dealer.GetAvailabilityResponse.prototype.setOpenDaysOfWeekList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dealer.OpenDayOfWeek=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dealer.OpenDayOfWeek}
 */
proto.dealer.GetAvailabilityResponse.prototype.addOpenDaysOfWeek = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dealer.OpenDayOfWeek, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dealer.GetAvailabilityResponse} returns this
 */
proto.dealer.GetAvailabilityResponse.prototype.clearOpenDaysOfWeekList = function() {
  return this.setOpenDaysOfWeekList([]);
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.dealer.GetAvailabilityResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.GetAvailabilityResponse} returns this
 */
proto.dealer.GetAvailabilityResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 error_code = 5;
 * @return {number}
 */
proto.dealer.GetAvailabilityResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.GetAvailabilityResponse} returns this
 */
proto.dealer.GetAvailabilityResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.GetHolidaysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.GetHolidaysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.GetHolidaysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.GetHolidaysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    error: jspb.Message.getFieldWithDefault(msg, 2, ""),
    calendar: (f = msg.getCalendar()) && proto.dealer.Calendar.toObject(includeInstance, f),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.GetHolidaysResponse}
 */
proto.dealer.GetHolidaysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.GetHolidaysResponse;
  return proto.dealer.GetHolidaysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.GetHolidaysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.GetHolidaysResponse}
 */
proto.dealer.GetHolidaysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 3:
      var value = new proto.dealer.Calendar;
      reader.readMessage(value,proto.dealer.Calendar.deserializeBinaryFromReader);
      msg.setCalendar(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.GetHolidaysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.GetHolidaysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.GetHolidaysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.GetHolidaysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCalendar();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dealer.Calendar.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.dealer.GetHolidaysResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dealer.GetHolidaysResponse} returns this
 */
proto.dealer.GetHolidaysResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.dealer.GetHolidaysResponse.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dealer.GetHolidaysResponse} returns this
 */
proto.dealer.GetHolidaysResponse.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Calendar calendar = 3;
 * @return {?proto.dealer.Calendar}
 */
proto.dealer.GetHolidaysResponse.prototype.getCalendar = function() {
  return /** @type{?proto.dealer.Calendar} */ (
    jspb.Message.getWrapperField(this, proto.dealer.Calendar, 3));
};


/**
 * @param {?proto.dealer.Calendar|undefined} value
 * @return {!proto.dealer.GetHolidaysResponse} returns this
*/
proto.dealer.GetHolidaysResponse.prototype.setCalendar = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer.GetHolidaysResponse} returns this
 */
proto.dealer.GetHolidaysResponse.prototype.clearCalendar = function() {
  return this.setCalendar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer.GetHolidaysResponse.prototype.hasCalendar = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 error_code = 4;
 * @return {number}
 */
proto.dealer.GetHolidaysResponse.prototype.getErrorCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dealer.GetHolidaysResponse} returns this
 */
proto.dealer.GetHolidaysResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.DealershipCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.DealershipCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.DealershipCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && proto.dealer.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.DealershipCreated}
 */
proto.dealer.DealershipCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.DealershipCreated;
  return proto.dealer.DealershipCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.DealershipCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.DealershipCreated}
 */
proto.dealer.DealershipCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dealer.Dealership;
      reader.readMessage(value,proto.dealer.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.DealershipCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.DealershipCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.DealershipCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dealer.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.dealer.DealershipCreated.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, proto.dealer.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.dealer.DealershipCreated} returns this
*/
proto.dealer.DealershipCreated.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer.DealershipCreated} returns this
 */
proto.dealer.DealershipCreated.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer.DealershipCreated.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.DealershipUserCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.DealershipUserCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.DealershipUserCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUserCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.dealer.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.DealershipUserCreated}
 */
proto.dealer.DealershipUserCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.DealershipUserCreated;
  return proto.dealer.DealershipUserCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.DealershipUserCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.DealershipUserCreated}
 */
proto.dealer.DealershipUserCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dealer.DealershipUser;
      reader.readMessage(value,proto.dealer.DealershipUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.DealershipUserCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.DealershipUserCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.DealershipUserCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUserCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dealer.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional DealershipUser user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealer.DealershipUserCreated.prototype.getUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, proto.dealer.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealer.DealershipUserCreated} returns this
*/
proto.dealer.DealershipUserCreated.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer.DealershipUserCreated} returns this
 */
proto.dealer.DealershipUserCreated.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer.DealershipUserCreated.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.DealershipUserUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.DealershipUserUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.DealershipUserUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUserUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.dealer.DealershipUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.DealershipUserUpdated}
 */
proto.dealer.DealershipUserUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.DealershipUserUpdated;
  return proto.dealer.DealershipUserUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.DealershipUserUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.DealershipUserUpdated}
 */
proto.dealer.DealershipUserUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dealer.DealershipUser;
      reader.readMessage(value,proto.dealer.DealershipUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.DealershipUserUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.DealershipUserUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.DealershipUserUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUserUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dealer.DealershipUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional DealershipUser user = 1;
 * @return {?proto.dealer.DealershipUser}
 */
proto.dealer.DealershipUserUpdated.prototype.getUser = function() {
  return /** @type{?proto.dealer.DealershipUser} */ (
    jspb.Message.getWrapperField(this, proto.dealer.DealershipUser, 1));
};


/**
 * @param {?proto.dealer.DealershipUser|undefined} value
 * @return {!proto.dealer.DealershipUserUpdated} returns this
*/
proto.dealer.DealershipUserUpdated.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer.DealershipUserUpdated} returns this
 */
proto.dealer.DealershipUserUpdated.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer.DealershipUserUpdated.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dealer.DealershipUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.dealer.DealershipUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dealer.DealershipUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealership: (f = msg.getDealership()) && proto.dealer.Dealership.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dealer.DealershipUpdated}
 */
proto.dealer.DealershipUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dealer.DealershipUpdated;
  return proto.dealer.DealershipUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dealer.DealershipUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dealer.DealershipUpdated}
 */
proto.dealer.DealershipUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dealer.Dealership;
      reader.readMessage(value,proto.dealer.Dealership.deserializeBinaryFromReader);
      msg.setDealership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dealer.DealershipUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dealer.DealershipUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dealer.DealershipUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dealer.DealershipUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealership();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dealer.Dealership.serializeBinaryToWriter
    );
  }
};


/**
 * optional Dealership dealership = 1;
 * @return {?proto.dealer.Dealership}
 */
proto.dealer.DealershipUpdated.prototype.getDealership = function() {
  return /** @type{?proto.dealer.Dealership} */ (
    jspb.Message.getWrapperField(this, proto.dealer.Dealership, 1));
};


/**
 * @param {?proto.dealer.Dealership|undefined} value
 * @return {!proto.dealer.DealershipUpdated} returns this
*/
proto.dealer.DealershipUpdated.prototype.setDealership = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dealer.DealershipUpdated} returns this
 */
proto.dealer.DealershipUpdated.prototype.clearDealership = function() {
  return this.setDealership(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dealer.DealershipUpdated.prototype.hasDealership = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.dealer);
