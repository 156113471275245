import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import {
  GetCustomerRequest,
  GetVehicleMapRequest,
} from '@nxcr-org/web-api-interface/lib/nexus_service_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import { isEmpty, isString, map } from 'lodash'
import {
  listPromosRequest,
  createPromoCode,
  listPromoPartners,
  createPromoPartner,
  getCustomerInvoices,
  getAccountBalance,
  getProgramofferings,
  listProgramPricing,
  getBillingStatus,
  getSubscriptionById,
  listRecurringBillableItems,
  updateRecurringBillableItems,
  listCustomerSubscriptions,
  removeMileageRecurringBillableItem,
  payInvoice,
  UpdateProgramReservation,
  getProgramReservation,
} from './nexus-service-payments'
import {
  getLatestCustody,
  LogMilesPurchased,
  deleteMilesPurchasedLog,
} from './nexus-service-fleet'
import {
  setPickupDate,
  setReservationProgram,
  listAppointments,
  cancelAppointment,
  createAppointment,
  updateAppointment,
  listAppointmentsByAccountNumber,
} from './nexus-service-scheduling'
import {
  listDealerships,
  listDealershipUsers,
} from './nexus-service-settings'
import {
  ListParams,
  PaginationParams,
  OrderParams,
  QueryParams,
} from '@nxcr-org/web-api-interface/lib/web_pb'
import {
  getCustomerById,
  UpdateCustomerWithFakePhone,
  ListCustomers,
} from './nexus-service-customer'

import {
  UpdateCustomerRequest,
  UpsertAddressesRequest,
} from '@nxcr-org/web-api-interface/lib/customer_service_pb'
import { CustomerServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { Address } from '@nxcr-org/web-api-interface/lib/domain_pb'
import toast from 'react-hot-toast'

//Service Exports
export const NexusService = {
  getCustomer,
  setPickupDate,
  setReservationProgram,
  listAppointments,
  cancelAppointment,
  createStatement: notImplementedFunction,
  createAppointment,
  updateAppointment,
  listPromosRequest,
  listAppointmentsByAccountNumber,
  createPromoCode,
  listPromoPartners,
  createPromoPartner,
  getCustomerInvoices,
  getVehicles: getMapVehicles,
  listDealerships,
  listDealershipUsers,
  listTransactions: notImplementedFunction,
  getAccountBalance,
  getLatestCustody,
  getProgramofferings,
  listProgramPricing,
  getBillingStatus,
  getSubscriptionById,
  listCustomerSubscriptions,
  listRecurringBillableItems,
  updateRecurringBillableItems,
  removeMileageRecurringBillableItem,
  payInvoice,
  LogMilesPurchased,
  deleteMilesPurchasedLog,
  UpdateProgramReservation,
  getProgramReservation,
  UpdateCustomerWithFakePhone,
  getCustomerById,
  ListCustomers,
  updateCustomer,
  editCustomerAddresses,
}

function getNexusService(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}
function getCustomerService(): CustomerServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new CustomerServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}
function getCustomer(params: GetCustomerRequest.AsObject) {
  const client = getNexusService()
  const request = buildGetCustomerRequest(params)
  return client.getCustomer(request).then((res) => {
    return res.toObject()
  })
}
function updateCustomer(params: UpdateCustomerRequest.AsObject) {
  const client = getCustomerService()
  const request = new UpdateCustomerRequest()
  request.setCustomerId(params.customerId)
  request.setFirstName(params.firstName)
  request.setLastName(params.lastName)
  request.setEmail(params.email)
  request.setPhone(params.phone)
  return client.updateCustomer(request).then((res) => {
    return res.toObject()
  })
}
export function buildGetCustomerRequest(params: GetCustomerRequest.AsObject) {
  const request = new GetCustomerRequest()
  if (!isString(params.customerId)) {
    throw new Error('Request requires customer ID')
  }
  request.setCustomerId(params.customerId)
  return request
}

function getMapVehicles(params: GetVehicleMapRequest.AsObject) {
  const client = getNexusService()
  const request = buildGetVehiclesRequest(params)
  return client.getMapVehicles(request).then((res) => res.toObject())
}

/**
 * Place in more central location.
 * @param params
 * @returns
 */
function buildListParams(params: GetVehicleMapRequest.AsObject) {
  const status = new ListParams()

  if (params?.status?.queryList && params?.status?.queryList.length > 0) {
    const queries = mapQueryParams(params?.status?.queryList)
    status.setQueryList(queries)
  }

  if (params?.status?.orderList && params?.status?.orderList.length > 0) {
    const order = mapOrderList(params?.status?.orderList)
    status.setOrderList(order)
  }

  if (params?.status?.pagination && !isEmpty(params?.status?.pagination)) {
    const pagination = buildPagination(params?.status?.pagination)
    status.setPagination(pagination)
  }

  return status
}

function buildPagination(params: ListParams.AsObject['pagination']) {
  const pagination = new PaginationParams()

  pagination.setLimit(params.limit)
  pagination.setOffset(params.offset)

  return pagination
}

function mapQueryParams(params: ListParams.AsObject['queryList'] = []) {
  return map(params, (query) => {
    const queryParams = new QueryParams()

    queryParams.setProperty(query.property)
    queryParams.setValue(query.value)
    queryParams.setModifier(query.modifier)

    return queryParams
  })
}

function mapOrderList(params: ListParams.AsObject['orderList'] = []) {
  return map(params, (order) => {
    const orderParam = new OrderParams()
    orderParam.setDirection(order.direction)
    orderParam.setProperty(order.property)
    return orderParam
  })
}

export function buildGetVehiclesRequest(params: GetVehicleMapRequest.AsObject) {
  const request = new GetVehicleMapRequest()
  const listParams = buildListParams(params)

  request.setStatus(listParams)
  request.setCustomerName(params.customerName)
  request.setVehicleMake(params.vehicleMake)
  request.setVehicleModel(params.vehicleModel)
  request.setVehicleVin(params.vehicleVin)

  return request
}

// create not implemented function that returns a promise
function notImplementedFunction(params: any) {
  console.debug('called notImplementedFunction', params)
  return new Promise((resolve, reject) => {
    reject('Not Implemented')
  })
}
async function editCustomerAddresses(customer_id: string, addressObj) {
  try {
    const client = getCustomerService()
    const request = new UpsertAddressesRequest()
    const addresses = [addressObj].map((addr) => {
      const address = new Address()
      address.setStreet(addr.street)
      address.setStreet2(addr.street2)
      address.setState(addr.state)
      address.setCity(addr.city)
      address.setPostal(addr.postal)
      address.setAddressType(addr.addressType)
      address.setDeleted(false)
      address.setId(addr.id)
      return address
    })
    request.setCustomerId(customer_id)
    request.setAddressesList(addresses)
    const result = await client.upsertAddresses(request)
    return result
  } catch (error) {
    toast.error(error.message)
  }
}
