import React, { FC } from 'react'
import {
  Box,
  Card,
  Typography,
  Divider,
  TextField,
  Tooltip,
  IconButton,
  Paper,
} from '@mui/material'
import { autonomyColor } from '../../../global-styles/autonomyColor'
import { DataGrid, GridRowParams, GridColumns } from '@mui/x-data-grid'
import { useCustomerContext } from '../CustomerContext'
import { DrawerComponent } from 'shared/DrawerComponent'
import { InvoiceDetails } from './InvoiceDetails'
import { useCustomerInvoices } from '../useListCustomerInvoices'
import { useInvoiceDetails } from '../useInvoiceDetails'
import { StatusPill } from 'shared/StatusPill'
import { getGridRowClass } from '../../../utils/getGridRowClass'
import { formatMoney } from '../../../utils/currency-formatter'
import { Delete, Edit, CheckBox } from '@mui/icons-material'
import { useCustomerBilling } from '../useCustomerBilling'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export const CustomerInvoices = () => {
  const { customerId, subscriptionId } = useCustomerContext()
  return (
    <Payments2Invoices
      subscriptionId={subscriptionId}
      customerId={customerId}
    />
  )
}

const Payments2Invoices: FC<{
  customerId: string
  subscriptionId: string
}> = ({ customerId, subscriptionId }) => {
  const { formattedInvoices, refetchInvoices } = useCustomerInvoices(customerId)
  const { setInvoiceId, isInvoiceDetailsDrawerOpen } = useInvoiceDetails()
  const {
    useRecurringBillableItems,
    updateSubscriptionAmount,
    isEditingSubscription,
    setIsEditingSubscription,
    setSubscriptionAmount,
    cancelInsurance,
    deleteMileagePackage,
  } = useCustomerBilling(customerId, subscriptionId)
  const { recurringBillableItems, refetchBillableItems } =
    useRecurringBillableItems()
  const { doUpdateSubscriptionAmount } = updateSubscriptionAmount(
    recurringBillableItems
  )
  const { doCancelInsurance } = cancelInsurance()
  const { doDeleteMileagePackage } = deleteMileagePackage(
    recurringBillableItems
  )
  const style = { display: 'flex', alignItems: 'center', padding: '1rem' }
  const [open, setOpen] = React.useState(false)
  const [hideCancelInsuranceButton, setHideCancelInsuranceButton] =
    React.useState(false)
  const [hideDeleteMileageButton, setHideDeleteMileageButton] =
    React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  function onClickSaveAmount(recurringBillableItems) {
    doUpdateSubscriptionAmount(recurringBillableItems)
      .then(() => refetchBillableItems())
      .then(() => setIsEditingSubscription(false))
  }
  function onClickCancelInsurance() {
    doCancelInsurance()
      .then(() => setHideCancelInsuranceButton(true))
      .then(() => refetchBillableItems())
      .then(() => handleClose())
  }
  function onClickDeleteMileage(recurringBillableItems) {
    doDeleteMileagePackage(recurringBillableItems)
      .then(() => setHideDeleteMileageButton(true))
      .then(() => refetchBillableItems())
  }

  return (
    <Card>
      <Box sx={{ ml: 1, margin: '20px' }}>
        <Typography color="inherit" variant="subtitle2">
          Subscription ID:{' '}
        </Typography>
        <Typography
          sx={{ fontWeight: 'bold' }}
          color={autonomyColor.white}
          variant="subtitle2"
        >
          {subscriptionId}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ ml: 1, margin: '20px' }}>
        <Typography color="inherit" variant="h5">
          Recurring Costs
        </Typography>
        {!recurringBillableItems && (
          <Typography style={style} color="inherit" variant="subtitle2">
            Loading...
          </Typography>
        )}
        {recurringBillableItems &&
          recurringBillableItems.find((rbi) => rbi.productType === 1) && (
            <Paper
              component="form"
              sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}
            >
              <TextField
                id={'subscriptionAmount'}
                label={'Subscription:'}
                color={isEditingSubscription ? 'error' : 'primary'}
                autoFocus={isEditingSubscription}
                variant={isEditingSubscription ? 'outlined' : 'standard'}
                defaultValue={
                  isEditingSubscription
                    ? recurringBillableItems.find(
                        (rbi) => rbi.productType === 1
                      ).amount
                    : formatMoney(
                        recurringBillableItems.find(
                          (rbi) => rbi.productType === 1
                        ).amount
                      )
                }
                onChange={(e) =>
                  setSubscriptionAmount(parseInt(e.target.value))
                }
                disabled={!isEditingSubscription}
              />
              {!isEditingSubscription && (
                <Tooltip title={'Edit Subscription'}>
                  <IconButton
                    color="primary"
                    sx={{ p: '10px' }}
                    aria-label="edit subscription"
                    onClick={() => setIsEditingSubscription(true)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
              {isEditingSubscription && (
                <Tooltip title={'Save Subscription Changes'}>
                  <IconButton
                    color="primary"
                    sx={{ p: '10px' }}
                    aria-label="save subscription changes"
                    onClick={() => onClickSaveAmount(recurringBillableItems)}
                  >
                    <CheckBox />
                  </IconButton>
                </Tooltip>
              )}
            </Paper>
          )}
        {recurringBillableItems &&
          recurringBillableItems.find((rbi) => rbi.productType === 4) && (
            <Paper
              component="form"
              sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}
            >
              <TextField
                label={'Insurance:'}
                variant={'standard'}
                defaultValue={formatMoney(
                  recurringBillableItems.find((rbi) => rbi.productType === 4)
                    .amount
                )}
                disabled={true}
              />
              {!hideCancelInsuranceButton && (
                <Tooltip title={'Cancel Insurance'}>
                  <IconButton
                    color="primary"
                    sx={{ p: '10px' }}
                    aria-label="cancel insurance"
                    onClick={handleClickOpen}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Paper>
          )}
        {recurringBillableItems &&
          recurringBillableItems.find((rbi) => rbi.productType === 23) && (
            <Paper
              component="form"
              sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}
            >
              <TextField
                label={'Mileage Package:'}
                variant={'standard'}
                defaultValue={formatMoney(
                  recurringBillableItems.find((rbi) => rbi.productType === 23)
                    .amount
                )}
                disabled={true}
              />
              {!hideDeleteMileageButton && (
                <Tooltip title={'Delete Mileage Package'}>
                  <IconButton
                    color="primary"
                    sx={{ p: '10px' }}
                    aria-label="delete mileage package"
                    onClick={() => onClickDeleteMileage(recurringBillableItems)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Paper>
          )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Cancel Insurance'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will cancel this customers insurance, are you sure you want
              to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Stop</Button>
            <Button onClick={onClickCancelInsurance} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Divider />
      <DataGrid
        autoHeight
        rowHeight={35}
        isRowSelectable={() => true}
        getRowClassName={(params) =>
          getGridRowClass(params.indexRelativeToCurrentPage)
        }
        columns={invoiceColumns}
        rows={formattedInvoices}
        onRowClick={(params: GridRowParams) => {
          setInvoiceId(params.row.id)
        }}
        getRowId={(row) => row.documentNumber}
        pageSize={20}
        pagination={true}
        rowsPerPageOptions={[10]}
      />
      {isInvoiceDetailsDrawerOpen && (
        <DrawerComponent
          isDrawerOpen={true}
          setIsDrawerOpen={() => {
            setInvoiceId()
          }}
          header={'Invoice Details'}
          childComponent={() => {
            return (
              <InvoiceDetails
                invoices={formattedInvoices}
                refetchInvoices={refetchInvoices}
                subscriptionId={subscriptionId}
              />
            )
          }}
        />
      )}
    </Card>
  )
}

const invoiceColumns: GridColumns = [
  // {
  //   field: 'type',
  //   headerName: 'Invoice Type',
  //   width: 150,
  // },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (props) => (
      <StatusPill color={'issued'}>{props.row.status}</StatusPill>
    ),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 100,
    cellClassName: 'rtl',
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 100,
    cellClassName: 'rtl',
  },
  {
    field: 'documentNumber',
    headerName: 'Document Number',
    width: 150,
  },
  {
    field: 'id',
    headerName: 'Invoice ID',
    width: 350,
  },
]
