// source: gateway_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var appointment_service_pb = require('./appointment_service_pb.js');
goog.object.extend(proto, appointment_service_pb);
var billing_service_pb = require('./billing_service_pb.js');
goog.object.extend(proto, billing_service_pb);
var contract_service_pb = require('./contract_service_pb.js');
goog.object.extend(proto, contract_service_pb);
var customer_service_pb = require('./customer_service_pb.js');
goog.object.extend(proto, customer_service_pb);
var customer_support_service_pb = require('./customer_support_service_pb.js');
goog.object.extend(proto, customer_support_service_pb);
var dealership_service_pb = require('./dealership_service_pb.js');
goog.object.extend(proto, dealership_service_pb);
var document_service_pb = require('./document_service_pb.js');
goog.object.extend(proto, document_service_pb);
var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var fleet_service_pb = require('./fleet_service_pb.js');
goog.object.extend(proto, fleet_service_pb);
var inspection_pb = require('./inspection_pb.js');
goog.object.extend(proto, inspection_pb);
var insurance_service_pb = require('./insurance_service_pb.js');
goog.object.extend(proto, insurance_service_pb);
var nexus_service_pb = require('./nexus_service_pb.js');
goog.object.extend(proto, nexus_service_pb);
var notification_service_pb = require('./notification_service_pb.js');
goog.object.extend(proto, notification_service_pb);
var payment_service_pb = require('./payment_service_pb.js');
goog.object.extend(proto, payment_service_pb);
var spre_pb = require('./spre_pb.js');
goog.object.extend(proto, spre_pb);
var subscription_service_pb = require('./subscription_service_pb.js');
goog.object.extend(proto, subscription_service_pb);
var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
var vehicle_listing_service_pb = require('./vehicle_listing_service_pb.js');
goog.object.extend(proto, vehicle_listing_service_pb);
var web_pb = require('./web_pb.js');
goog.object.extend(proto, web_pb);
var telematics_service_pb = require('./telematics_service_pb.js');
goog.object.extend(proto, telematics_service_pb);
