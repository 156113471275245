import {useEffect, useState} from 'react'
import {useIsFetching, useIsMutating, useMutation, useQuery, useQueryClient} from 'react-query'
import {NexusService} from 'global-apis/nexus-service'
import {
  getFormattedDealershipList,
  getFormattedDealershipUsers,
  IDayOfWeek,
  IDate,
  IHoliday,
} from '../../utils'
import {getAvailabilityResponse, mockClosedDatesResponse, mockGetHolidaysResponse} from 'scheduling/settings/mockData'
import {formattedDaysOfWeek} from 'scheduling/settings/blocks/TeamSettings/BusinessDaysBlock/useBusinessDaysBlock'
import {ClosedDateWithReason, Calendar, OpenDayOfWeek} from '@nxcr-org/web-api-interface/lib/dealer_pb'
import {parse} from 'date-fns'

export function useTeamSettings(
  dealership,
  setDealership
){
  const queryClient = useQueryClient()
  const [isEditing, setIsEditing] = useState( false )

  const {data: listDealerships} = useQuery(
    ['/listDealerships'],
    () => {
      return NexusService.listDealerships().then((response) => {
        return getFormattedDealershipList(response.dealersList)
      })
    }, {
      onSuccess: (dealerships) => {
        if ( dealerships && dealerships.length ) {
          setDealership(dealerships[0])
        }
      },
    }
  )

  const {data: listDealershipUsers} = useQuery(
    ['/listDealershipUsers'],
    () => {
      return NexusService.listDealershipUsers(dealership.id).then((response) => {
        return getFormattedDealershipUsers(response.dealershipUsersList)
      })
    },{
      onSuccess: (data) => {
        console.log('dealershipUserList success', data)
      },
      enabled: !!dealership
    }
  )

  useEffect(() => {
    if ( listDealerships && listDealerships.length ) {
      setDealership( listDealerships.find(dealer => dealership.id === dealer.id) )
    }
  }, [dealership])

  function handleCancellation() {
    queryClient.invalidateQueries(['/dealership', dealership.id])
    queryClient.invalidateQueries(['/dealership', 'businessDays'])
    queryClient.invalidateQueries(['/dealership', 'closedDays'])
    queryClient.invalidateQueries(['/dealership', 'holidays'])
    // ...invalidate all queries here
    setIsEditing(false)
  }

  function saveChanges(): Promise<any> {
    const mutations = [
      mutateBusinessDays(),
      mutateClosedDates(),
    ]

    // perform all mutations asynchronously
    return Promise.all( mutations ).then(() => {
      setIsEditing( !isEditing )
    })
  }

  function filterFutureHolidays( holidays: IDate[] ): IDate[] {
    const currentDate = Date.now()

    return holidays.filter( holiday => {
      return holiday.date.getTime() > currentDate
    })
  }

  ////////////////////////////////////////////////
  ///////////////////// MOCK /////////////////////
  ////////////////////////////////////////////////

  // NOTE: For everything below here: endpoints not available yet in NexusService,
  // so are currently mocked with mockData.ts

  // @START MOCK BUSINESS DAYS BLOCK
  const {
    data: businessDays
  } = useQuery(
    ['/dealership', 'businessDays'],
    () => {
      return Promise
        .resolve( getAvailabilityResponse )
        .then( response => {
          return handleBusinessDaysResponse( response.openDaysOfWeekList )
        })
    }, {
      enabled: !!dealership,
      initialData: []
    }
  )

  const {
    mutateAsync: mutateBusinessDays
  } = useMutation( function() {
    // simulates an API PUT request that takes 1 second to complete
    return new Promise<void>(function( resolve ) {
      setTimeout(function() {
        resolve()
      }, 1000)
    })
  })

  function setBusinessDays( updatedBusinessDays: IDayOfWeek[] ): void {
    queryClient.setQueryData(['/dealership', 'businessDays'], updatedBusinessDays )
  }

  function handleBusinessDaysResponse( businessDays: OpenDayOfWeek.AsObject[] ): IDayOfWeek[] {
    return businessDays.map( day => ({
      ...day,
      dayOfWeek: formattedDaysOfWeek.find( formattedDay => formattedDay.index === day.dayOfWeek ).dayOfWeek
    }))
  }
  // @END MOCK BUSINESS DAYS BLOCK


  // @START MOCK HOLIDAYS DAYS BLOCK
  const {
    data: closedDates
  } = useQuery(
    ['/dealership', 'closedDates'],
    () => {
      return Promise
        .resolve( mockClosedDatesResponse )
        .then( response => handleClosedDatesResponse( response.closedDatesWithReasonsList ) )
    }, {
      enabled: !!dealership,
      initialData: []
    }
  )

  const {
    data: holidays
  } = useQuery(
    ['/dealership', 'holidays'],
    () => {
      return Promise
        .resolve( mockGetHolidaysResponse )
        .then( response => handleHolidaysResponse( response.calendar ) )
    }, {
      enabled: !!dealership,
      initialData: []
    }
  )

  const {
    mutateAsync: mutateClosedDates
  } = useMutation( function() {
    // simulates an API PUT request that takes 1 second to complete
    return new Promise<void>(function( resolve ) {
      setTimeout(function() {
        resolve()
      }, 1000)
    })
  })

  function setClosedDates( updatedClosedDates: IDate[] ): void {
    queryClient.setQueryData(['/dealership', 'closedDates'], updatedClosedDates )
  }

  function setHolidays( holidays: IHoliday[] ){
    queryClient.setQueryData(['/dealership', 'holidays'], {...dealership, holidays} )
  }

  function handleClosedDatesResponse( closedDates: ClosedDateWithReason.AsObject[] ): IDate[] {
    return closedDates.map( ({ reason, date }) => ({
      id: date,
      reason,
      date: parse( date, 'yyyy-MM-dd', new Date() )
    }))
  }

  function handleHolidaysResponse( calendar: Calendar.AsObject ): IDate[] {
    return filterFutureHolidays(
      calendar.datesList.map( ( date: AtyDate.AsObject ) => ({
        id: date.datestring,
        date: parse( date.datestring, calendar.dateformat, new Date() ),
        reason: date.description
      }))
    )
  }
  // @END MOCK HOLIDAYS DAYS BLOCK

  ////////////////////////////////////////////////
  ///////////////// END MOCK /////////////////////
  ////////////////////////////////////////////////

  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  return {
    dealership,
    setDealership,
    listDealerships,
    listDealershipUsers,
    isEditing,
    isLoading: isFetching > 0 || isMutating > 0,
    businessDays,
    closedDates,
    holidays,
    setClosedDates,
    setHolidays,
    setBusinessDays,
    saveChanges,
    setIsEditing,
    handleCancellation,
  }
}

