import {
  CreatePromotionRequest,
  PromotionConstraint,
  CreatePromotionPartnerRequest,
  GetAccountBalanceRequest,
  GetBillingStatusRequest,
  GetSubscriptionByIdRequest,
  ListRecurringBillableItemsRequest,
  UpdateRecurringBillableItemsRequest,
  RecurringBillableItem,
  RemoveMileageRecurringBillableItemRequest,
  PayInvoiceRequest,
  Subscription,
  UpdateReservationRequest,
  Reservation,
  GetReservationRequest,
  ListProgramPricingRequest,
} from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import {
  BillingServicePromiseClient,
  SubscriptionServicePromiseClient,
} from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'
import { Product } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { NexusServicePromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_service_grpc_web_pb'
import {
  ListParams,
  PaginationParams,
  SearchParams,
  QueryParams,
  OrderParams,
  Modifier,
} from '@nxcr-org/web-api-interface/lib/web_pb'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import { spyOnError } from '../../utils/spyOnResponse'

export const BillingService = {
  // addToOrder,
  listProducts,
}

function getNexusService(): NexusServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new NexusServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

function getSubscriptionService(): SubscriptionServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new SubscriptionServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

function getBillingService(): BillingServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new BillingServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

export function buildLineItemProduct(
  lineItemProduct: Product.AsObject
): Product {
  const product = new Product()
  const lineItemproductAmount = lineItemProduct.amount * 100
  product.setId(lineItemProduct.id)
  product.setName(lineItemProduct.name)
  product.setDescription(lineItemProduct.description)
  product.setAmount(lineItemproductAmount)
  product.setCurrency(lineItemProduct.currency)
  product.setProductType(lineItemProduct.productType)
  product.setRecurring(lineItemProduct.recurring)
  product.setAddOn(lineItemProduct.addOn)

  return product
}

export async function listPromosRequest() {
  const client = getNexusService()

  const request = new SearchParams()

  const pagination = new PaginationParams()
  pagination.setLimit(1000)
  pagination.setOffset(0)

  request.setQueryList([])
  request.setPagination(pagination)
  request.setOrderList([])

  return client.listPromotions(request).then((res) => {
    return res.toObject()
  })
}

export async function createPromoCode(
  params: CreatePromotionRequest.AsObject = {
    promoCode: '',
    rebateValue: 0,
    rebatePercent: 0,
    target: 0,
    source: 0,
    sourceId: '',
    validFrom: 0,
    validUntil: 0,
    status: true,
    constraintsList: [],
  }
) {
  const client = getNexusService()
  const request = buildNewPromoRequest(params)

  return client.createPromotion(request).then((res) => {
    return res.toObject()
  })
}

export function buildNewPromoRequest(
  promoRequest: CreatePromotionRequest.AsObject
): CreatePromotionRequest {
  const request = new CreatePromotionRequest()
  const promoConstraint = [] as PromotionConstraint[]

  request.setPromoCode(promoRequest.promoCode)
  request.setRebateValue(promoRequest.rebateValue)
  request.setRebatePercent(promoRequest.rebatePercent)
  request.setTarget(promoRequest.target)
  request.setSource(promoRequest.source)
  request.setSourceId(promoRequest.sourceId)
  request.setValidFrom(promoRequest.validFrom)
  request.setValidUntil(promoRequest.validUntil)
  request.setStatus(promoRequest.status)
  request.setConstraintsList(promoConstraint)

  return request
}

export async function listPromoPartners() {
  const client = getNexusService()

  const request = new ListParams()

  const pagination = new PaginationParams()
  pagination.setLimit(50)
  pagination.setOffset(0)

  request.setQueryList([])
  request.setPagination(pagination)
  request.setOrderList([])

  return client.listPromotionPartners(request).then((res) => {
    return res.toObject()
  })
}

export async function createPromoPartner(
  params: CreatePromotionPartnerRequest.AsObject = {
    name: '',
  }
) {
  const client = getNexusService()
  const request = new CreatePromotionPartnerRequest()
  request.setName(params.name)

  return client.createPromotionPartner(request).then((res) => {
    return res.toObject()
  })
}

// *** Payments 2 *** This section is probably gonna move to separate file*********

export async function getCustomerInvoices(customerId: string) {
  const client = getBillingService()
  const request = new SearchParams()
  const params: SearchParams.AsObject = {
    queryList: [
      {
        property: 'customer_id',
        value: customerId,
        modifier: Modifier.EQUAL_TO,
      },
    ],
    orderList: [{ property: 'due_date', direction: 1 }],
    pagination: { limit: 100, offset: 0 },
  }

  request.setQueryList(buildQueryListObj(params.queryList))
  request.setOrderList(buildOrderListObj(params.orderList))
  request.setPagination(buildPaginationObj(params.pagination))

  return client.listInvoices(request).then((res) => {
    return res.toObject()
  })
}

export async function getAccountBalance(
  params: GetAccountBalanceRequest.AsObject = { customerId: '' }
) {
  const client = getBillingService()
  const request = new GetAccountBalanceRequest()

  request.setCustomerId(params.customerId)

  return client.getAccountBalance(request).then((res) => {
    return res.toObject()
  })
}

// function buildAddToOrderRequest(params: OrderFE.AsObject) {
//   const request = new AddToOrderRequest()

//   request.setCustomerId(params.customerId)
//   request.setDollarAmount(params.dollarAmount * 100)
//   request.setProductId(params.productId)

//   return request
// }

// async function addToOrder(params: AddToOrderRequest.AsObject) {
//   const client = getBillingService()
//   const request = buildAddToOrderRequest(params)

//   return client
//     .addToOrder(request)
//     .then((res) => res.toObject())
//     .catch(spyOnError('addToOrder'))
// }

async function listProducts() {
  const client = getBillingService()
  const request = new SearchParams()
  return client
    .listProductOfferings(request)
    .then((res) => res.toObject())
    .catch(spyOnError('listProducts'))
}

export function buildQueryListObj(
  params: QueryParams.AsObject[]
): QueryParams[] {
  const queryListRequestArray = params.map((queryParam) => {
    const request = new QueryParams()
    request.setProperty(queryParam.property)
    request.setValue(queryParam.value)
    request.setModifier(queryParam.modifier)
    return request
  })

  return queryListRequestArray
}

export function buildOrderListObj(
  params: OrderParams.AsObject[]
): OrderParams[] {
  const requestArray = params.map((queryParam) => {
    const request = new OrderParams()
    request.setProperty(queryParam.property)
    request.setDirection(queryParam.direction)
    return request
  })

  return requestArray
}

export function buildPaginationObj(
  params: PaginationParams.AsObject
): PaginationParams {
  const request = new PaginationParams()

  request.setLimit(params.limit)
  request.setOffset(params.offset)

  return request
}

export async function listProgramPricing() {
  const client = getNexusService()
  const request = new ListProgramPricingRequest()

  return client.listProgramPricing(request).then((res) => {
    return res.toObject()
  })
}

export async function getProgramofferings() {
  const client = getSubscriptionService()
  const request = new ListProgramPricingRequest()

  return client.listPrograms(request).then((res) => {
    return res.toObject()
  })
}

export async function getBillingStatus(subscriptionId: string) {
  const client = getBillingService()
  const request = new GetBillingStatusRequest()
  request.setSubscriptionId(subscriptionId)

  return client.getBillingStatus(request).then((res) => {
    return res.toObject()
  })
}

export async function getSubscriptionById(subscriptionId: string) {
  const client = getBillingService()
  const request = new GetSubscriptionByIdRequest()
  request.setSubscriptionId(subscriptionId)

  return client.getSubscriptionById(request).then((res: Subscription) => {
    return res.toObject()
  })
}

export async function listCustomerSubscriptions(customerId: string) {
  const client = getBillingService()
  const request = new SearchParams()
  const params: SearchParams.AsObject = {
    queryList: [
      {
        property: 'customer_id',
        value: customerId,
        modifier: Modifier.EQUAL_TO,
      },
    ],
    orderList: [{ property: 'created_at', direction: 1 }],
    pagination: { limit: 100, offset: 0 },
  }

  request.setQueryList(buildQueryListObj(params.queryList))
  request.setOrderList(buildOrderListObj(params.orderList))
  request.setPagination(buildPaginationObj(params.pagination))

  return client.listSubscriptions(request).then((res) => {
    return res.toObject()
  })
}

export async function listRecurringBillableItems(
  customerId: string,
  subscriptionId: string
) {
  const client = getBillingService()
  const request = new ListRecurringBillableItemsRequest()
  request.setSubscriptionId(subscriptionId)
  request.setCustomerId(customerId)

  return client.listRecurringBillableItems(request).then((res) => {
    return res.toObject()
  })
}

export async function updateRecurringBillableItems(
  customerId: string,
  subscriptionId: string,
  recurringBillableItems: RecurringBillableItem[]
) {
  const client = getBillingService()
  const request = new UpdateRecurringBillableItemsRequest()
  request.setSubscriptionId(subscriptionId)
  request.setCustomerId(customerId)
  request.setRecurringBillableItemsList(recurringBillableItems)

  return client.updateRecurringBillableItems(request).then((res) => {
    return res.toObject()
  })
}

export async function removeMileageRecurringBillableItem(
  customerId: string,
  subscriptionId: string,
  recurringBillableItemId: number
) {
  const client = getBillingService()
  const request = new RemoveMileageRecurringBillableItemRequest()
  request.setSubscriptionId(subscriptionId)
  request.setCustomerId(customerId)
  request.setMileageRecurringBillableItemId(recurringBillableItemId)

  return client.removeMileageRecurringBillableItem(request).then((res) => {
    return res.toObject()
  })
}

export async function payInvoice(
  documentNumber: string,
  subscriptionId: string,
  paymentAmount: number
) {
  const client = getBillingService()
  const request = new PayInvoiceRequest()
  request.setSubscriptionId(subscriptionId)
  request.setInvoiceNumber(documentNumber)
  request.setAmount(paymentAmount)

  return client.payInvoice(request).then((res) => {
    return res.toObject()
  })
}

export function buildSetReservationProgramRequest(
  params: UpdateReservationRequest.AsObject
) {
  const request = new UpdateReservationRequest()

  if (!params.programId || params.programId.length === 0) {
    throw new Error('Program ID must be provided.')
  }

  if (!params.reservationId || params.reservationId.length === 0) {
    throw new Error('Reservation ID must be provided.')
  }

  if (!params.monthlyRent) {
    throw new Error('Monthly amount must be provided.')
  }
  request.setProgramId(params.programId)
  request.setReservationId(params.reservationId)
  request.setDeposit(params.deposit)
  request.setStartFee(params.startFee)

  request.setMonthlyRent(params.monthlyRent)
  request.setColor(params.color)
  request.setSecondaryColor(params.secondaryColor)

  request.setMake(params.make)
  request.setModel(params.model)
  request.setPostal(params.postal)
  request.setTrackingId(params.trackingId)

  return request
}
export async function UpdateProgramReservation(
  params: UpdateReservationRequest.AsObject
) {
  const client = getBillingService()
  const request = buildSetReservationProgramRequest(params)

  return client
    .updateProgramReservation(request)
    .catch(spyOnError('setReservationProgramError'))
}

export async function getProgramReservation(
  reservationId: string
): Promise<Reservation.AsObject> {
  const client = getBillingService()

  const request = new GetReservationRequest()

  request.setReservationId(reservationId)

  return client.getProgramReservation(request).then((res: Reservation) => {
    return res.toObject()
  })
}
