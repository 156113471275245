// source: billing_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var vendor_invoicing_pb = require('./vendor_invoicing_pb.js');
goog.object.extend(proto, vendor_invoicing_pb);
var web_pb = require('./web_pb.js');
goog.object.extend(proto, web_pb);
goog.exportSymbol('proto.billing_service.ActivityType', null, global);
goog.exportSymbol('proto.billing_service.AddMileageRecurringBillableItemRequest', null, global);
goog.exportSymbol('proto.billing_service.AddRecurringBillableItemsRequest', null, global);
goog.exportSymbol('proto.billing_service.AddToOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.AddToOwnOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.BatchDateRequest', null, global);
goog.exportSymbol('proto.billing_service.BillPunitiveMileageRequest', null, global);
goog.exportSymbol('proto.billing_service.ConfirmFinalBillRequest', null, global);
goog.exportSymbol('proto.billing_service.ConvertOrderToInvoiceRequest', null, global);
goog.exportSymbol('proto.billing_service.ConvertOrderToInvoiceResponse', null, global);
goog.exportSymbol('proto.billing_service.ConvertOwnOrderToInvoiceRequest', null, global);
goog.exportSymbol('proto.billing_service.ConvertSubscriptionOrdersByDateResponse', null, global);
goog.exportSymbol('proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest', null, global);
goog.exportSymbol('proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse', null, global);
goog.exportSymbol('proto.billing_service.CreateFeeInvoiceRequest', null, global);
goog.exportSymbol('proto.billing_service.CreateFeeInvoiceResponse', null, global);
goog.exportSymbol('proto.billing_service.CreateFinalBillRequest', null, global);
goog.exportSymbol('proto.billing_service.CreateOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.CreateOwnOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.CreatePromotionPartnerRequest', null, global);
goog.exportSymbol('proto.billing_service.CreatePromotionPartnerResponse', null, global);
goog.exportSymbol('proto.billing_service.CreatePromotionRequest', null, global);
goog.exportSymbol('proto.billing_service.CreatePromotionResponse', null, global);
goog.exportSymbol('proto.billing_service.CreateReservationRequest', null, global);
goog.exportSymbol('proto.billing_service.CustomerAccountRow', null, global);
goog.exportSymbol('proto.billing_service.DatedProgramPricing', null, global);
goog.exportSymbol('proto.billing_service.DeleteOldDraftOrdersFromBillingRequest', null, global);
goog.exportSymbol('proto.billing_service.DeleteOldDraftOrdersFromBillingResponse', null, global);
goog.exportSymbol('proto.billing_service.DeleteOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.DeleteOrderResponse', null, global);
goog.exportSymbol('proto.billing_service.DeleteOwnOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.DetailsRequest', null, global);
goog.exportSymbol('proto.billing_service.DraftOrderUpdated', null, global);
goog.exportSymbol('proto.billing_service.ExportCustomerPromotionsRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportCustomerPromotionsResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportPartnerPromotionsRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportPartnerPromotionsResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportPromotionActivitiesRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportPromotionActivitiesResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportReferralCustomersRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportReferralCustomersResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportReferralPromotionsRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportReferralPromotionsResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportToWestlakeRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportToWestlakeResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportWestlakePaymentDetailsRequest', null, global);
goog.exportSymbol('proto.billing_service.ExportWestlakePaymentDetailsResponse', null, global);
goog.exportSymbol('proto.billing_service.ExportedReferralPromotion', null, global);
goog.exportSymbol('proto.billing_service.FeeItem', null, global);
goog.exportSymbol('proto.billing_service.GetAccountBalanceRequest', null, global);
goog.exportSymbol('proto.billing_service.GetAccountBalanceResponse', null, global);
goog.exportSymbol('proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse', null, global);
goog.exportSymbol('proto.billing_service.GetBillingStatusRequest', null, global);
goog.exportSymbol('proto.billing_service.GetBillingStatusResponse', null, global);
goog.exportSymbol('proto.billing_service.GetDriveOffInvoiceRequest', null, global);
goog.exportSymbol('proto.billing_service.GetLatestDraftOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.GetMileageRecurringBillableItemRequest', null, global);
goog.exportSymbol('proto.billing_service.GetMileageRecurringBillableItemResponse', null, global);
goog.exportSymbol('proto.billing_service.GetOwnAccountBalanceRequest', null, global);
goog.exportSymbol('proto.billing_service.GetOwnBillingStatusRequest', null, global);
goog.exportSymbol('proto.billing_service.GetOwnDriveOffInvoiceRequest', null, global);
goog.exportSymbol('proto.billing_service.GetOwnLatestDraftOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.GetOwnMileageRecurringBillableItemRequest', null, global);
goog.exportSymbol('proto.billing_service.GetReservationRequest', null, global);
goog.exportSymbol('proto.billing_service.GetSubscriptionByIdRequest', null, global);
goog.exportSymbol('proto.billing_service.GetSubscriptionResponse', null, global);
goog.exportSymbol('proto.billing_service.ImportRecurringMileagePackagesRequest', null, global);
goog.exportSymbol('proto.billing_service.ImportRecurringMileagePackagesResponse', null, global);
goog.exportSymbol('proto.billing_service.Invoice', null, global);
goog.exportSymbol('proto.billing_service.InvoiceFE', null, global);
goog.exportSymbol('proto.billing_service.InvoiceReference', null, global);
goog.exportSymbol('proto.billing_service.InvoiceStatus', null, global);
goog.exportSymbol('proto.billing_service.InvoiceType', null, global);
goog.exportSymbol('proto.billing_service.LineItem', null, global);
goog.exportSymbol('proto.billing_service.LineItemFE', null, global);
goog.exportSymbol('proto.billing_service.LineItemType', null, global);
goog.exportSymbol('proto.billing_service.ListInvoicesResponse', null, global);
goog.exportSymbol('proto.billing_service.ListOwnInvoicesRequest', null, global);
goog.exportSymbol('proto.billing_service.ListOwnProgramReservationsRequest', null, global);
goog.exportSymbol('proto.billing_service.ListOwnSubscriptionsRequest', null, global);
goog.exportSymbol('proto.billing_service.ListProductsResponse', null, global);
goog.exportSymbol('proto.billing_service.ListProgramPricingRequest', null, global);
goog.exportSymbol('proto.billing_service.ListProgramPricingResponse', null, global);
goog.exportSymbol('proto.billing_service.ListProgramsRequest', null, global);
goog.exportSymbol('proto.billing_service.ListProgramsResponse', null, global);
goog.exportSymbol('proto.billing_service.ListPromotionPartnersResponse', null, global);
goog.exportSymbol('proto.billing_service.ListPromotionsResponse', null, global);
goog.exportSymbol('proto.billing_service.ListRecurringBillableItemsRequest', null, global);
goog.exportSymbol('proto.billing_service.ListRecurringBillableItemsResponse', null, global);
goog.exportSymbol('proto.billing_service.ListReservationsResponse', null, global);
goog.exportSymbol('proto.billing_service.ListSubscriptionsResponse', null, global);
goog.exportSymbol('proto.billing_service.MileageRecurringBillableItem', null, global);
goog.exportSymbol('proto.billing_service.OrderFE', null, global);
goog.exportSymbol('proto.billing_service.PayInvoiceRequest', null, global);
goog.exportSymbol('proto.billing_service.PayInvoiceResponse', null, global);
goog.exportSymbol('proto.billing_service.PaySubscriptionInvoiceByDateResponse', null, global);
goog.exportSymbol('proto.billing_service.PricingOption', null, global);
goog.exportSymbol('proto.billing_service.ProductFE', null, global);
goog.exportSymbol('proto.billing_service.Program', null, global);
goog.exportSymbol('proto.billing_service.ProgramColorOption', null, global);
goog.exportSymbol('proto.billing_service.Promotion', null, global);
goog.exportSymbol('proto.billing_service.PromotionActivity', null, global);
goog.exportSymbol('proto.billing_service.PromotionConstraint', null, global);
goog.exportSymbol('proto.billing_service.PromotionConstraint.ConstraintDimension', null, global);
goog.exportSymbol('proto.billing_service.PromotionDetails', null, global);
goog.exportSymbol('proto.billing_service.PromotionPartner', null, global);
goog.exportSymbol('proto.billing_service.PromotionSource', null, global);
goog.exportSymbol('proto.billing_service.PromotionTarget', null, global);
goog.exportSymbol('proto.billing_service.PurchaseOneTimeMileageRequest', null, global);
goog.exportSymbol('proto.billing_service.PurchaseRecurringMileageRequest', null, global);
goog.exportSymbol('proto.billing_service.RecurringBillableItem', null, global);
goog.exportSymbol('proto.billing_service.RedemptionRequest', null, global);
goog.exportSymbol('proto.billing_service.RedemptionResponse', null, global);
goog.exportSymbol('proto.billing_service.ReferralCodeRequest', null, global);
goog.exportSymbol('proto.billing_service.ReferralCodeResponse', null, global);
goog.exportSymbol('proto.billing_service.RemoveFromOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.RemoveFromOwnOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.RemoveMileageRecurringBillableItemRequest', null, global);
goog.exportSymbol('proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest', null, global);
goog.exportSymbol('proto.billing_service.RemoveRecurringBillableItemsRequest', null, global);
goog.exportSymbol('proto.billing_service.Reservation', null, global);
goog.exportSymbol('proto.billing_service.SeedReferralPromotionsRequest', null, global);
goog.exportSymbol('proto.billing_service.SeedReferralPromotionsResponse', null, global);
goog.exportSymbol('proto.billing_service.StageAllReservationsRequest', null, global);
goog.exportSymbol('proto.billing_service.StageAllReservationsResponse', null, global);
goog.exportSymbol('proto.billing_service.StageCustomerRequest', null, global);
goog.exportSymbol('proto.billing_service.StageCustomerResponse', null, global);
goog.exportSymbol('proto.billing_service.StageDataRequest', null, global);
goog.exportSymbol('proto.billing_service.StageDataResponse', null, global);
goog.exportSymbol('proto.billing_service.StageReservationRequest', null, global);
goog.exportSymbol('proto.billing_service.StageReservationResponse', null, global);
goog.exportSymbol('proto.billing_service.StageWestlakeDataRequest', null, global);
goog.exportSymbol('proto.billing_service.StageWestlakeDataResponse', null, global);
goog.exportSymbol('proto.billing_service.Subscription', null, global);
goog.exportSymbol('proto.billing_service.SyncReservationRequest', null, global);
goog.exportSymbol('proto.billing_service.SyncReservationResponse', null, global);
goog.exportSymbol('proto.billing_service.SyncTaxedProducts', null, global);
goog.exportSymbol('proto.billing_service.UpdateMileageRecurringBillableItemRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdateOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdateOwnOrderRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdatePromotionPartnerRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdatePromotionPartnerResponse', null, global);
goog.exportSymbol('proto.billing_service.UpdatePromotionRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdatePromotionResponse', null, global);
goog.exportSymbol('proto.billing_service.UpdateRecurringBillableItemsRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdateRecurringBillingDayRequest', null, global);
goog.exportSymbol('proto.billing_service.UpdateRecurringBillingDayResponse', null, global);
goog.exportSymbol('proto.billing_service.UpdateReservationRequest', null, global);
goog.exportSymbol('proto.billing_service.WestlakeStagingStatusRequest', null, global);
goog.exportSymbol('proto.billing_service.WestlakeStagingStatusResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.SyncReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.SyncReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.SyncReservationRequest.displayName = 'proto.billing_service.SyncReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.SyncReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.SyncReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.SyncReservationResponse.displayName = 'proto.billing_service.SyncReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.AddToOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.AddToOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.AddToOrderRequest.displayName = 'proto.billing_service.AddToOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.AddToOwnOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.AddToOwnOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.AddToOwnOrderRequest.displayName = 'proto.billing_service.AddToOwnOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.CreateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreateOrderRequest.displayName = 'proto.billing_service.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreateOwnOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.CreateOwnOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.CreateOwnOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreateOwnOrderRequest.displayName = 'proto.billing_service.CreateOwnOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.UpdateOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.UpdateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateOrderRequest.displayName = 'proto.billing_service.UpdateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateOwnOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.UpdateOwnOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.UpdateOwnOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateOwnOrderRequest.displayName = 'proto.billing_service.UpdateOwnOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConvertOrderToInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ConvertOrderToInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConvertOrderToInvoiceRequest.displayName = 'proto.billing_service.ConvertOrderToInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ConvertOwnOrderToInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConvertOwnOrderToInvoiceRequest.displayName = 'proto.billing_service.ConvertOwnOrderToInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PayInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PayInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PayInvoiceRequest.displayName = 'proto.billing_service.PayInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PayInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PayInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PayInvoiceResponse.displayName = 'proto.billing_service.PayInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetBillingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetBillingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetBillingStatusRequest.displayName = 'proto.billing_service.GetBillingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetOwnBillingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetOwnBillingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetOwnBillingStatusRequest.displayName = 'proto.billing_service.GetOwnBillingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetBillingStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetBillingStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetBillingStatusResponse.displayName = 'proto.billing_service.GetBillingStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetDriveOffInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetDriveOffInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetDriveOffInvoiceRequest.displayName = 'proto.billing_service.GetDriveOffInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetOwnDriveOffInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetOwnDriveOffInvoiceRequest.displayName = 'proto.billing_service.GetOwnDriveOffInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RemoveFromOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.RemoveFromOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.RemoveFromOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RemoveFromOrderRequest.displayName = 'proto.billing_service.RemoveFromOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RemoveFromOwnOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.RemoveFromOwnOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.RemoveFromOwnOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RemoveFromOwnOrderRequest.displayName = 'proto.billing_service.RemoveFromOwnOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetOwnLatestDraftOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetOwnLatestDraftOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetOwnLatestDraftOrderRequest.displayName = 'proto.billing_service.GetOwnLatestDraftOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetLatestDraftOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetLatestDraftOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetLatestDraftOrderRequest.displayName = 'proto.billing_service.GetLatestDraftOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DeleteOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DeleteOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DeleteOrderRequest.displayName = 'proto.billing_service.DeleteOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DeleteOwnOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DeleteOwnOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DeleteOwnOrderRequest.displayName = 'proto.billing_service.DeleteOwnOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DeleteOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DeleteOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DeleteOrderResponse.displayName = 'proto.billing_service.DeleteOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConvertOrderToInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ConvertOrderToInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConvertOrderToInvoiceResponse.displayName = 'proto.billing_service.ConvertOrderToInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreateFeeInvoiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.CreateFeeInvoiceRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.CreateFeeInvoiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreateFeeInvoiceRequest.displayName = 'proto.billing_service.CreateFeeInvoiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.FeeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.FeeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.FeeItem.displayName = 'proto.billing_service.FeeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreateFeeInvoiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.CreateFeeInvoiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreateFeeInvoiceResponse.displayName = 'proto.billing_service.CreateFeeInvoiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreateFinalBillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.CreateFinalBillRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.CreateFinalBillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreateFinalBillRequest.displayName = 'proto.billing_service.CreateFinalBillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConfirmFinalBillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ConfirmFinalBillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConfirmFinalBillRequest.displayName = 'proto.billing_service.ConfirmFinalBillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.OrderFE = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.OrderFE.repeatedFields_, null);
};
goog.inherits(proto.billing_service.OrderFE, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.OrderFE.displayName = 'proto.billing_service.OrderFE';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetOwnAccountBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetOwnAccountBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetOwnAccountBalanceRequest.displayName = 'proto.billing_service.GetOwnAccountBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetAccountBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetAccountBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetAccountBalanceRequest.displayName = 'proto.billing_service.GetAccountBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetAccountBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetAccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetAccountBalanceResponse.displayName = 'proto.billing_service.GetAccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListOwnInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ListOwnInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListOwnInvoicesRequest.displayName = 'proto.billing_service.ListOwnInvoicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListInvoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListInvoicesResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListInvoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListInvoicesResponse.displayName = 'proto.billing_service.ListInvoicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdateReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateReservationRequest.displayName = 'proto.billing_service.UpdateReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetReservationRequest.displayName = 'proto.billing_service.GetReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListOwnProgramReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ListOwnProgramReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListOwnProgramReservationsRequest.displayName = 'proto.billing_service.ListOwnProgramReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListReservationsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListReservationsResponse.displayName = 'proto.billing_service.ListReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreateReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.CreateReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreateReservationRequest.displayName = 'proto.billing_service.CreateReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.InvoiceReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.InvoiceReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.InvoiceReference.displayName = 'proto.billing_service.InvoiceReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListProductsResponse.displayName = 'proto.billing_service.ListProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ProductFE = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ProductFE, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ProductFE.displayName = 'proto.billing_service.ProductFE';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ProgramColorOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ProgramColorOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ProgramColorOption.displayName = 'proto.billing_service.ProgramColorOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DatedProgramPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.DatedProgramPricing.repeatedFields_, null);
};
goog.inherits(proto.billing_service.DatedProgramPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DatedProgramPricing.displayName = 'proto.billing_service.DatedProgramPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PricingOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PricingOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PricingOption.displayName = 'proto.billing_service.PricingOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.Program = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.Program.repeatedFields_, null);
};
goog.inherits(proto.billing_service.Program, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.Program.displayName = 'proto.billing_service.Program';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListProgramsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ListProgramsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListProgramsRequest.displayName = 'proto.billing_service.ListProgramsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListProgramsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListProgramsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListProgramsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListProgramsResponse.displayName = 'proto.billing_service.ListProgramsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DetailsRequest.displayName = 'proto.billing_service.DetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PromotionConstraint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PromotionConstraint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PromotionConstraint.displayName = 'proto.billing_service.PromotionConstraint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PromotionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PromotionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PromotionDetails.displayName = 'proto.billing_service.PromotionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RedemptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.RedemptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RedemptionRequest.displayName = 'proto.billing_service.RedemptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RedemptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.RedemptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RedemptionResponse.displayName = 'proto.billing_service.RedemptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ReferralCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ReferralCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ReferralCodeRequest.displayName = 'proto.billing_service.ReferralCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetSubscriptionByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetSubscriptionByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetSubscriptionByIdRequest.displayName = 'proto.billing_service.GetSubscriptionByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CustomerAccountRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.CustomerAccountRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CustomerAccountRow.displayName = 'proto.billing_service.CustomerAccountRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetSubscriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetSubscriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetSubscriptionResponse.displayName = 'proto.billing_service.GetSubscriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ReferralCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ReferralCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ReferralCodeResponse.displayName = 'proto.billing_service.ReferralCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.SeedReferralPromotionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.SeedReferralPromotionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.SeedReferralPromotionsRequest.displayName = 'proto.billing_service.SeedReferralPromotionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.SeedReferralPromotionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.SeedReferralPromotionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.SeedReferralPromotionsResponse.displayName = 'proto.billing_service.SeedReferralPromotionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportReferralPromotionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportReferralPromotionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportReferralPromotionsRequest.displayName = 'proto.billing_service.ExportReferralPromotionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportedReferralPromotion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportedReferralPromotion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportedReferralPromotion.displayName = 'proto.billing_service.ExportedReferralPromotion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportReferralPromotionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ExportReferralPromotionsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ExportReferralPromotionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportReferralPromotionsResponse.displayName = 'proto.billing_service.ExportReferralPromotionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreatePromotionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.CreatePromotionRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.CreatePromotionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreatePromotionRequest.displayName = 'proto.billing_service.CreatePromotionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreatePromotionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.CreatePromotionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreatePromotionResponse.displayName = 'proto.billing_service.CreatePromotionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdatePromotionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.UpdatePromotionRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.UpdatePromotionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdatePromotionRequest.displayName = 'proto.billing_service.UpdatePromotionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdatePromotionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdatePromotionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdatePromotionResponse.displayName = 'proto.billing_service.UpdatePromotionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListPromotionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListPromotionsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListPromotionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListPromotionsResponse.displayName = 'proto.billing_service.ListPromotionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreatePromotionPartnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.CreatePromotionPartnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreatePromotionPartnerRequest.displayName = 'proto.billing_service.CreatePromotionPartnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.CreatePromotionPartnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.CreatePromotionPartnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.CreatePromotionPartnerResponse.displayName = 'proto.billing_service.CreatePromotionPartnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdatePromotionPartnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdatePromotionPartnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdatePromotionPartnerRequest.displayName = 'proto.billing_service.UpdatePromotionPartnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdatePromotionPartnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdatePromotionPartnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdatePromotionPartnerResponse.displayName = 'proto.billing_service.UpdatePromotionPartnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListPromotionPartnersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListPromotionPartnersResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListPromotionPartnersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListPromotionPartnersResponse.displayName = 'proto.billing_service.ListPromotionPartnersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageWestlakeDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageWestlakeDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageWestlakeDataRequest.displayName = 'proto.billing_service.StageWestlakeDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageWestlakeDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageWestlakeDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageWestlakeDataResponse.displayName = 'proto.billing_service.StageWestlakeDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageCustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageCustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageCustomerRequest.displayName = 'proto.billing_service.StageCustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageCustomerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageCustomerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageCustomerResponse.displayName = 'proto.billing_service.StageCustomerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageReservationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageReservationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageReservationRequest.displayName = 'proto.billing_service.StageReservationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageReservationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageReservationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageReservationResponse.displayName = 'proto.billing_service.StageReservationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageAllReservationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageAllReservationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageAllReservationsRequest.displayName = 'proto.billing_service.StageAllReservationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageAllReservationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageAllReservationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageAllReservationsResponse.displayName = 'proto.billing_service.StageAllReservationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageDataRequest.displayName = 'proto.billing_service.StageDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.StageDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.StageDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.StageDataResponse.displayName = 'proto.billing_service.StageDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportWestlakePaymentDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportWestlakePaymentDetailsRequest.displayName = 'proto.billing_service.ExportWestlakePaymentDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportWestlakePaymentDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportWestlakePaymentDetailsResponse.displayName = 'proto.billing_service.ExportWestlakePaymentDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportCustomerPromotionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportCustomerPromotionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportCustomerPromotionsRequest.displayName = 'proto.billing_service.ExportCustomerPromotionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportCustomerPromotionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportCustomerPromotionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportCustomerPromotionsResponse.displayName = 'proto.billing_service.ExportCustomerPromotionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportPartnerPromotionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportPartnerPromotionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportPartnerPromotionsRequest.displayName = 'proto.billing_service.ExportPartnerPromotionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportPartnerPromotionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportPartnerPromotionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportPartnerPromotionsResponse.displayName = 'proto.billing_service.ExportPartnerPromotionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportPromotionActivitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportPromotionActivitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportPromotionActivitiesRequest.displayName = 'proto.billing_service.ExportPromotionActivitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportPromotionActivitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportPromotionActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportPromotionActivitiesResponse.displayName = 'proto.billing_service.ExportPromotionActivitiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportReferralCustomersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportReferralCustomersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportReferralCustomersRequest.displayName = 'proto.billing_service.ExportReferralCustomersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportReferralCustomersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportReferralCustomersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportReferralCustomersResponse.displayName = 'proto.billing_service.ExportReferralCustomersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.displayName = 'proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.displayName = 'proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ImportRecurringMileagePackagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ImportRecurringMileagePackagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ImportRecurringMileagePackagesRequest.displayName = 'proto.billing_service.ImportRecurringMileagePackagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ImportRecurringMileagePackagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ImportRecurringMileagePackagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ImportRecurringMileagePackagesResponse.displayName = 'proto.billing_service.ImportRecurringMileagePackagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DeleteOldDraftOrdersFromBillingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.displayName = 'proto.billing_service.DeleteOldDraftOrdersFromBillingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DeleteOldDraftOrdersFromBillingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.displayName = 'proto.billing_service.DeleteOldDraftOrdersFromBillingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportToWestlakeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportToWestlakeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportToWestlakeRequest.displayName = 'proto.billing_service.ExportToWestlakeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ExportToWestlakeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ExportToWestlakeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ExportToWestlakeResponse.displayName = 'proto.billing_service.ExportToWestlakeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.WestlakeStagingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.WestlakeStagingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.WestlakeStagingStatusRequest.displayName = 'proto.billing_service.WestlakeStagingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.WestlakeStagingStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.WestlakeStagingStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.WestlakeStagingStatusResponse.displayName = 'proto.billing_service.WestlakeStagingStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.DraftOrderUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.DraftOrderUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.DraftOrderUpdated.displayName = 'proto.billing_service.DraftOrderUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.Promotion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.Promotion.repeatedFields_, null);
};
goog.inherits(proto.billing_service.Promotion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.Promotion.displayName = 'proto.billing_service.Promotion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PromotionPartner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PromotionPartner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PromotionPartner.displayName = 'proto.billing_service.PromotionPartner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PromotionActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.PromotionActivity.repeatedFields_, null);
};
goog.inherits(proto.billing_service.PromotionActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PromotionActivity.displayName = 'proto.billing_service.PromotionActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.Reservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.Reservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.Reservation.displayName = 'proto.billing_service.Reservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.LineItemFE = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.LineItemFE, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.LineItemFE.displayName = 'proto.billing_service.LineItemFE';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.InvoiceFE = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.InvoiceFE.repeatedFields_, null);
};
goog.inherits(proto.billing_service.InvoiceFE, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.InvoiceFE.displayName = 'proto.billing_service.InvoiceFE';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.LineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.LineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.LineItem.displayName = 'proto.billing_service.LineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.Invoice.repeatedFields_, null);
};
goog.inherits(proto.billing_service.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.Invoice.displayName = 'proto.billing_service.Invoice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.MileageRecurringBillableItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.MileageRecurringBillableItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.MileageRecurringBillableItem.displayName = 'proto.billing_service.MileageRecurringBillableItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetMileageRecurringBillableItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetMileageRecurringBillableItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetMileageRecurringBillableItemRequest.displayName = 'proto.billing_service.GetMileageRecurringBillableItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetOwnMileageRecurringBillableItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetOwnMileageRecurringBillableItemRequest.displayName = 'proto.billing_service.GetOwnMileageRecurringBillableItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetMileageRecurringBillableItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.GetMileageRecurringBillableItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetMileageRecurringBillableItemResponse.displayName = 'proto.billing_service.GetMileageRecurringBillableItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdateMileageRecurringBillableItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateMileageRecurringBillableItemRequest.displayName = 'proto.billing_service.UpdateMileageRecurringBillableItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.RemoveMileageRecurringBillableItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RemoveMileageRecurringBillableItemRequest.displayName = 'proto.billing_service.RemoveMileageRecurringBillableItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.displayName = 'proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.AddMileageRecurringBillableItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.AddMileageRecurringBillableItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.AddMileageRecurringBillableItemRequest.displayName = 'proto.billing_service.AddMileageRecurringBillableItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateRecurringBillingDayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdateRecurringBillingDayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateRecurringBillingDayRequest.displayName = 'proto.billing_service.UpdateRecurringBillingDayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateRecurringBillingDayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.UpdateRecurringBillingDayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateRecurringBillingDayResponse.displayName = 'proto.billing_service.UpdateRecurringBillingDayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RecurringBillableItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.RecurringBillableItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RecurringBillableItem.displayName = 'proto.billing_service.RecurringBillableItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListRecurringBillableItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ListRecurringBillableItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListRecurringBillableItemsRequest.displayName = 'proto.billing_service.ListRecurringBillableItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListRecurringBillableItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListRecurringBillableItemsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListRecurringBillableItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListRecurringBillableItemsResponse.displayName = 'proto.billing_service.ListRecurringBillableItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.AddRecurringBillableItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.AddRecurringBillableItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.AddRecurringBillableItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.AddRecurringBillableItemsRequest.displayName = 'proto.billing_service.AddRecurringBillableItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.UpdateRecurringBillableItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.UpdateRecurringBillableItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.UpdateRecurringBillableItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.UpdateRecurringBillableItemsRequest.displayName = 'proto.billing_service.UpdateRecurringBillableItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.RemoveRecurringBillableItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.RemoveRecurringBillableItemsRequest.repeatedFields_, null);
};
goog.inherits(proto.billing_service.RemoveRecurringBillableItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.RemoveRecurringBillableItemsRequest.displayName = 'proto.billing_service.RemoveRecurringBillableItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListOwnSubscriptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ListOwnSubscriptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListOwnSubscriptionsRequest.displayName = 'proto.billing_service.ListOwnSubscriptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListSubscriptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListSubscriptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListSubscriptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListSubscriptionsResponse.displayName = 'proto.billing_service.ListSubscriptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.Subscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.Subscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.Subscription.displayName = 'proto.billing_service.Subscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.SyncTaxedProducts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.SyncTaxedProducts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.SyncTaxedProducts.displayName = 'proto.billing_service.SyncTaxedProducts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PurchaseOneTimeMileageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PurchaseOneTimeMileageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PurchaseOneTimeMileageRequest.displayName = 'proto.billing_service.PurchaseOneTimeMileageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PurchaseRecurringMileageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.PurchaseRecurringMileageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PurchaseRecurringMileageRequest.displayName = 'proto.billing_service.PurchaseRecurringMileageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.BillPunitiveMileageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.BillPunitiveMileageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.BillPunitiveMileageRequest.displayName = 'proto.billing_service.BillPunitiveMileageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.BatchDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.BatchDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.BatchDateRequest.displayName = 'proto.billing_service.BatchDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.displayName = 'proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ConvertSubscriptionOrdersByDateResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ConvertSubscriptionOrdersByDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ConvertSubscriptionOrdersByDateResponse.displayName = 'proto.billing_service.ConvertSubscriptionOrdersByDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.PaySubscriptionInvoiceByDateResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.PaySubscriptionInvoiceByDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.PaySubscriptionInvoiceByDateResponse.displayName = 'proto.billing_service.PaySubscriptionInvoiceByDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListProgramPricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.billing_service.ListProgramPricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListProgramPricingRequest.displayName = 'proto.billing_service.ListProgramPricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.billing_service.ListProgramPricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.billing_service.ListProgramPricingResponse.repeatedFields_, null);
};
goog.inherits(proto.billing_service.ListProgramPricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.billing_service.ListProgramPricingResponse.displayName = 'proto.billing_service.ListProgramPricingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.SyncReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.SyncReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.SyncReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SyncReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.SyncReservationRequest}
 */
proto.billing_service.SyncReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.SyncReservationRequest;
  return proto.billing_service.SyncReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.SyncReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.SyncReservationRequest}
 */
proto.billing_service.SyncReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.SyncReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.SyncReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.SyncReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SyncReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.SyncReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.SyncReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.SyncReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SyncReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.SyncReservationResponse}
 */
proto.billing_service.SyncReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.SyncReservationResponse;
  return proto.billing_service.SyncReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.SyncReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.SyncReservationResponse}
 */
proto.billing_service.SyncReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.SyncReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.SyncReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.SyncReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SyncReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.AddToOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.AddToOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.AddToOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddToOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.AddToOrderRequest}
 */
proto.billing_service.AddToOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.AddToOrderRequest;
  return proto.billing_service.AddToOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.AddToOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.AddToOrderRequest}
 */
proto.billing_service.AddToOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 6:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.AddToOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.AddToOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.AddToOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddToOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.billing_service.AddToOrderRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.billing_service.AddToOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string order_number = 5;
 * @return {string}
 */
proto.billing_service.AddToOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional domain.Product.ProductType product_type = 6;
 * @return {!proto.domain.Product.ProductType}
 */
proto.billing_service.AddToOrderRequest.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.billing_service.AddToOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 amount = 8;
 * @return {number}
 */
proto.billing_service.AddToOrderRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 quantity = 9;
 * @return {number}
 */
proto.billing_service.AddToOrderRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.AddToOrderRequest} returns this
 */
proto.billing_service.AddToOrderRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.AddToOwnOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.AddToOwnOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddToOwnOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.AddToOwnOrderRequest}
 */
proto.billing_service.AddToOwnOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.AddToOwnOrderRequest;
  return proto.billing_service.AddToOwnOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.AddToOwnOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.AddToOwnOrderRequest}
 */
proto.billing_service.AddToOwnOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.AddToOwnOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.AddToOwnOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddToOwnOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOwnOrderRequest} returns this
 */
proto.billing_service.AddToOwnOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_number = 2;
 * @return {string}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOwnOrderRequest} returns this
 */
proto.billing_service.AddToOwnOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional domain.Product.ProductType product_type = 3;
 * @return {!proto.domain.Product.ProductType}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.billing_service.AddToOwnOrderRequest} returns this
 */
proto.billing_service.AddToOwnOrderRequest.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddToOwnOrderRequest} returns this
 */
proto.billing_service.AddToOwnOrderRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.AddToOwnOrderRequest} returns this
 */
proto.billing_service.AddToOwnOrderRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 quantity = 6;
 * @return {number}
 */
proto.billing_service.AddToOwnOrderRequest.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.AddToOwnOrderRequest} returns this
 */
proto.billing_service.AddToOwnOrderRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.CreateOrderRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    vendor_invoicing_pb.OrderItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreateOrderRequest}
 */
proto.billing_service.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreateOrderRequest;
  return proto.billing_service.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreateOrderRequest}
 */
proto.billing_service.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new vendor_invoicing_pb.OrderItem;
      reader.readMessage(value,vendor_invoicing_pb.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vendor_invoicing_pb.OrderItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.CreateOrderRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateOrderRequest} returns this
 */
proto.billing_service.CreateOrderRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.CreateOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateOrderRequest} returns this
 */
proto.billing_service.CreateOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated vendor_invoicing.OrderItem items = 3;
 * @return {!Array<!proto.vendor_invoicing.OrderItem>}
 */
proto.billing_service.CreateOrderRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.vendor_invoicing.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, vendor_invoicing_pb.OrderItem, 3));
};


/**
 * @param {!Array<!proto.vendor_invoicing.OrderItem>} value
 * @return {!proto.billing_service.CreateOrderRequest} returns this
*/
proto.billing_service.CreateOrderRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vendor_invoicing.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendor_invoicing.OrderItem}
 */
proto.billing_service.CreateOrderRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vendor_invoicing.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.CreateOrderRequest} returns this
 */
proto.billing_service.CreateOrderRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.CreateOwnOrderRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreateOwnOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreateOwnOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreateOwnOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateOwnOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    vendor_invoicing_pb.OrderItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreateOwnOrderRequest}
 */
proto.billing_service.CreateOwnOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreateOwnOrderRequest;
  return proto.billing_service.CreateOwnOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreateOwnOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreateOwnOrderRequest}
 */
proto.billing_service.CreateOwnOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new vendor_invoicing_pb.OrderItem;
      reader.readMessage(value,vendor_invoicing_pb.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreateOwnOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreateOwnOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreateOwnOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateOwnOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vendor_invoicing_pb.OrderItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.CreateOwnOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateOwnOrderRequest} returns this
 */
proto.billing_service.CreateOwnOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated vendor_invoicing.OrderItem items = 3;
 * @return {!Array<!proto.vendor_invoicing.OrderItem>}
 */
proto.billing_service.CreateOwnOrderRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.vendor_invoicing.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, vendor_invoicing_pb.OrderItem, 3));
};


/**
 * @param {!Array<!proto.vendor_invoicing.OrderItem>} value
 * @return {!proto.billing_service.CreateOwnOrderRequest} returns this
*/
proto.billing_service.CreateOwnOrderRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vendor_invoicing.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vendor_invoicing.OrderItem}
 */
proto.billing_service.CreateOwnOrderRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vendor_invoicing.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.CreateOwnOrderRequest} returns this
 */
proto.billing_service.CreateOwnOrderRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.UpdateOrderRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.billing_service.LineItemFE.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateOrderRequest}
 */
proto.billing_service.UpdateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateOrderRequest;
  return proto.billing_service.UpdateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateOrderRequest}
 */
proto.billing_service.UpdateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 4:
      var value = new proto.billing_service.LineItemFE;
      reader.readMessage(value,proto.billing_service.LineItemFE.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.billing_service.LineItemFE.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.UpdateOrderRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateOrderRequest} returns this
 */
proto.billing_service.UpdateOrderRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.UpdateOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateOrderRequest} returns this
 */
proto.billing_service.UpdateOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_number = 3;
 * @return {string}
 */
proto.billing_service.UpdateOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateOrderRequest} returns this
 */
proto.billing_service.UpdateOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated LineItemFE line_items = 4;
 * @return {!Array<!proto.billing_service.LineItemFE>}
 */
proto.billing_service.UpdateOrderRequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.billing_service.LineItemFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.LineItemFE, 4));
};


/**
 * @param {!Array<!proto.billing_service.LineItemFE>} value
 * @return {!proto.billing_service.UpdateOrderRequest} returns this
*/
proto.billing_service.UpdateOrderRequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.billing_service.LineItemFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.LineItemFE}
 */
proto.billing_service.UpdateOrderRequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.billing_service.LineItemFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.UpdateOrderRequest} returns this
 */
proto.billing_service.UpdateOrderRequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.UpdateOwnOrderRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateOwnOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateOwnOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateOwnOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.billing_service.LineItemFE.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateOwnOrderRequest}
 */
proto.billing_service.UpdateOwnOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateOwnOrderRequest;
  return proto.billing_service.UpdateOwnOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateOwnOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateOwnOrderRequest}
 */
proto.billing_service.UpdateOwnOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 3:
      var value = new proto.billing_service.LineItemFE;
      reader.readMessage(value,proto.billing_service.LineItemFE.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateOwnOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateOwnOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateOwnOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing_service.LineItemFE.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateOwnOrderRequest} returns this
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_number = 2;
 * @return {string}
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateOwnOrderRequest} returns this
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LineItemFE line_items = 3;
 * @return {!Array<!proto.billing_service.LineItemFE>}
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.billing_service.LineItemFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.LineItemFE, 3));
};


/**
 * @param {!Array<!proto.billing_service.LineItemFE>} value
 * @return {!proto.billing_service.UpdateOwnOrderRequest} returns this
*/
proto.billing_service.UpdateOwnOrderRequest.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing_service.LineItemFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.LineItemFE}
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing_service.LineItemFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.UpdateOwnOrderRequest} returns this
 */
proto.billing_service.UpdateOwnOrderRequest.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConvertOrderToInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertOrderToInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConvertOrderToInvoiceRequest}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConvertOrderToInvoiceRequest;
  return proto.billing_service.ConvertOrderToInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConvertOrderToInvoiceRequest}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConvertOrderToInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertOrderToInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConvertOrderToInvoiceRequest} returns this
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConvertOrderToInvoiceRequest} returns this
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_number = 3;
 * @return {string}
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConvertOrderToInvoiceRequest} returns this
 */
proto.billing_service.ConvertOrderToInvoiceRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConvertOwnOrderToInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConvertOwnOrderToInvoiceRequest}
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConvertOwnOrderToInvoiceRequest;
  return proto.billing_service.ConvertOwnOrderToInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConvertOwnOrderToInvoiceRequest}
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConvertOwnOrderToInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} returns this
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_number = 2;
 * @return {string}
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} returns this
 */
proto.billing_service.ConvertOwnOrderToInvoiceRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PayInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PayInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PayInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PayInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PayInvoiceRequest}
 */
proto.billing_service.PayInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PayInvoiceRequest;
  return proto.billing_service.PayInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PayInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PayInvoiceRequest}
 */
proto.billing_service.PayInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PayInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PayInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PayInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PayInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.PayInvoiceRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PayInvoiceRequest} returns this
 */
proto.billing_service.PayInvoiceRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_method_id = 2;
 * @return {string}
 */
proto.billing_service.PayInvoiceRequest.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PayInvoiceRequest} returns this
 */
proto.billing_service.PayInvoiceRequest.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.billing_service.PayInvoiceRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PayInvoiceRequest} returns this
 */
proto.billing_service.PayInvoiceRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string invoice_number = 4;
 * @return {string}
 */
proto.billing_service.PayInvoiceRequest.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PayInvoiceRequest} returns this
 */
proto.billing_service.PayInvoiceRequest.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PayInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PayInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PayInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PayInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PayInvoiceResponse}
 */
proto.billing_service.PayInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PayInvoiceResponse;
  return proto.billing_service.PayInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PayInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PayInvoiceResponse}
 */
proto.billing_service.PayInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PayInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PayInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PayInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PayInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.billing_service.PayInvoiceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.PayInvoiceResponse} returns this
 */
proto.billing_service.PayInvoiceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.billing_service.PayInvoiceResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PayInvoiceResponse} returns this
 */
proto.billing_service.PayInvoiceResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetBillingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetBillingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetBillingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetBillingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetBillingStatusRequest}
 */
proto.billing_service.GetBillingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetBillingStatusRequest;
  return proto.billing_service.GetBillingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetBillingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetBillingStatusRequest}
 */
proto.billing_service.GetBillingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetBillingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetBillingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetBillingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetBillingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.GetBillingStatusRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetBillingStatusRequest} returns this
 */
proto.billing_service.GetBillingStatusRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetOwnBillingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetOwnBillingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetOwnBillingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnBillingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetOwnBillingStatusRequest}
 */
proto.billing_service.GetOwnBillingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetOwnBillingStatusRequest;
  return proto.billing_service.GetOwnBillingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetOwnBillingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetOwnBillingStatusRequest}
 */
proto.billing_service.GetOwnBillingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetOwnBillingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetOwnBillingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetOwnBillingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnBillingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.GetOwnBillingStatusRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetOwnBillingStatusRequest} returns this
 */
proto.billing_service.GetOwnBillingStatusRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetBillingStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetBillingStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetBillingStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetBillingStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationFeePaid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    signedContract: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    driveOffPaid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetBillingStatusResponse}
 */
proto.billing_service.GetBillingStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetBillingStatusResponse;
  return proto.billing_service.GetBillingStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetBillingStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetBillingStatusResponse}
 */
proto.billing_service.GetBillingStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReservationFeePaid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSignedContract(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDriveOffPaid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetBillingStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetBillingStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetBillingStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetBillingStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationFeePaid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSignedContract();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDriveOffPaid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool reservation_fee_paid = 1;
 * @return {boolean}
 */
proto.billing_service.GetBillingStatusResponse.prototype.getReservationFeePaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.GetBillingStatusResponse} returns this
 */
proto.billing_service.GetBillingStatusResponse.prototype.setReservationFeePaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool signed_contract = 2;
 * @return {boolean}
 */
proto.billing_service.GetBillingStatusResponse.prototype.getSignedContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.GetBillingStatusResponse} returns this
 */
proto.billing_service.GetBillingStatusResponse.prototype.setSignedContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool drive_off_paid = 3;
 * @return {boolean}
 */
proto.billing_service.GetBillingStatusResponse.prototype.getDriveOffPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.GetBillingStatusResponse} returns this
 */
proto.billing_service.GetBillingStatusResponse.prototype.setDriveOffPaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetDriveOffInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetDriveOffInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetDriveOffInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetDriveOffInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetDriveOffInvoiceRequest}
 */
proto.billing_service.GetDriveOffInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetDriveOffInvoiceRequest;
  return proto.billing_service.GetDriveOffInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetDriveOffInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetDriveOffInvoiceRequest}
 */
proto.billing_service.GetDriveOffInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetDriveOffInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetDriveOffInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetDriveOffInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetDriveOffInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.GetDriveOffInvoiceRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetDriveOffInvoiceRequest} returns this
 */
proto.billing_service.GetDriveOffInvoiceRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetOwnDriveOffInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetOwnDriveOffInvoiceRequest}
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetOwnDriveOffInvoiceRequest;
  return proto.billing_service.GetOwnDriveOffInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetOwnDriveOffInvoiceRequest}
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetOwnDriveOffInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetOwnDriveOffInvoiceRequest} returns this
 */
proto.billing_service.GetOwnDriveOffInvoiceRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.RemoveFromOrderRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RemoveFromOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RemoveFromOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RemoveFromOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveFromOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lineItemIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RemoveFromOrderRequest}
 */
proto.billing_service.RemoveFromOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RemoveFromOrderRequest;
  return proto.billing_service.RemoveFromOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RemoveFromOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RemoveFromOrderRequest}
 */
proto.billing_service.RemoveFromOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLineItemIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RemoveFromOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RemoveFromOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RemoveFromOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveFromOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLineItemIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.RemoveFromOrderRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveFromOrderRequest} returns this
 */
proto.billing_service.RemoveFromOrderRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.RemoveFromOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveFromOrderRequest} returns this
 */
proto.billing_service.RemoveFromOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_number = 3;
 * @return {string}
 */
proto.billing_service.RemoveFromOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveFromOrderRequest} returns this
 */
proto.billing_service.RemoveFromOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string line_item_ids = 4;
 * @return {!Array<string>}
 */
proto.billing_service.RemoveFromOrderRequest.prototype.getLineItemIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.RemoveFromOrderRequest} returns this
 */
proto.billing_service.RemoveFromOrderRequest.prototype.setLineItemIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.RemoveFromOrderRequest} returns this
 */
proto.billing_service.RemoveFromOrderRequest.prototype.addLineItemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.RemoveFromOrderRequest} returns this
 */
proto.billing_service.RemoveFromOrderRequest.prototype.clearLineItemIdsList = function() {
  return this.setLineItemIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.RemoveFromOwnOrderRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RemoveFromOwnOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RemoveFromOwnOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveFromOwnOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lineItemIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest}
 */
proto.billing_service.RemoveFromOwnOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RemoveFromOwnOrderRequest;
  return proto.billing_service.RemoveFromOwnOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RemoveFromOwnOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest}
 */
proto.billing_service.RemoveFromOwnOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addLineItemIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RemoveFromOwnOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RemoveFromOwnOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveFromOwnOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLineItemIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest} returns this
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_number = 2;
 * @return {string}
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest} returns this
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string line_item_ids = 3;
 * @return {!Array<string>}
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.getLineItemIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest} returns this
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.setLineItemIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest} returns this
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.addLineItemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.RemoveFromOwnOrderRequest} returns this
 */
proto.billing_service.RemoveFromOwnOrderRequest.prototype.clearLineItemIdsList = function() {
  return this.setLineItemIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetOwnLatestDraftOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetOwnLatestDraftOrderRequest}
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetOwnLatestDraftOrderRequest;
  return proto.billing_service.GetOwnLatestDraftOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetOwnLatestDraftOrderRequest}
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetOwnLatestDraftOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetOwnLatestDraftOrderRequest} returns this
 */
proto.billing_service.GetOwnLatestDraftOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetLatestDraftOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetLatestDraftOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetLatestDraftOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetLatestDraftOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetLatestDraftOrderRequest}
 */
proto.billing_service.GetLatestDraftOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetLatestDraftOrderRequest;
  return proto.billing_service.GetLatestDraftOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetLatestDraftOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetLatestDraftOrderRequest}
 */
proto.billing_service.GetLatestDraftOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetLatestDraftOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetLatestDraftOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetLatestDraftOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetLatestDraftOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.GetLatestDraftOrderRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetLatestDraftOrderRequest} returns this
 */
proto.billing_service.GetLatestDraftOrderRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.GetLatestDraftOrderRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetLatestDraftOrderRequest} returns this
 */
proto.billing_service.GetLatestDraftOrderRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DeleteOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DeleteOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DeleteOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DeleteOrderRequest}
 */
proto.billing_service.DeleteOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DeleteOrderRequest;
  return proto.billing_service.DeleteOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DeleteOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DeleteOrderRequest}
 */
proto.billing_service.DeleteOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DeleteOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DeleteOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DeleteOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_number = 1;
 * @return {string}
 */
proto.billing_service.DeleteOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DeleteOrderRequest} returns this
 */
proto.billing_service.DeleteOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DeleteOwnOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DeleteOwnOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DeleteOwnOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOwnOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DeleteOwnOrderRequest}
 */
proto.billing_service.DeleteOwnOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DeleteOwnOrderRequest;
  return proto.billing_service.DeleteOwnOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DeleteOwnOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DeleteOwnOrderRequest}
 */
proto.billing_service.DeleteOwnOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DeleteOwnOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DeleteOwnOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DeleteOwnOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOwnOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_number = 1;
 * @return {string}
 */
proto.billing_service.DeleteOwnOrderRequest.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DeleteOwnOrderRequest} returns this
 */
proto.billing_service.DeleteOwnOrderRequest.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DeleteOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DeleteOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DeleteOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DeleteOrderResponse}
 */
proto.billing_service.DeleteOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DeleteOrderResponse;
  return proto.billing_service.DeleteOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DeleteOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DeleteOrderResponse}
 */
proto.billing_service.DeleteOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DeleteOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DeleteOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DeleteOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConvertOrderToInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConvertOrderToInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConvertOrderToInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertOrderToInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConvertOrderToInvoiceResponse}
 */
proto.billing_service.ConvertOrderToInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConvertOrderToInvoiceResponse;
  return proto.billing_service.ConvertOrderToInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConvertOrderToInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConvertOrderToInvoiceResponse}
 */
proto.billing_service.ConvertOrderToInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConvertOrderToInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConvertOrderToInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConvertOrderToInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertOrderToInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.CreateFeeInvoiceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreateFeeInvoiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreateFeeInvoiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateFeeInvoiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.billing_service.FeeItem.toObject, includeInstance),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    autoPay: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreateFeeInvoiceRequest}
 */
proto.billing_service.CreateFeeInvoiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreateFeeInvoiceRequest;
  return proto.billing_service.CreateFeeInvoiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreateFeeInvoiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreateFeeInvoiceRequest}
 */
proto.billing_service.CreateFeeInvoiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new proto.billing_service.FeeItem;
      reader.readMessage(value,proto.billing_service.FeeItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoPay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreateFeeInvoiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreateFeeInvoiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateFeeInvoiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.billing_service.FeeItem.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAutoPay();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateFeeInvoiceRequest} returns this
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated FeeItem items = 2;
 * @return {!Array<!proto.billing_service.FeeItem>}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.billing_service.FeeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.FeeItem, 2));
};


/**
 * @param {!Array<!proto.billing_service.FeeItem>} value
 * @return {!proto.billing_service.CreateFeeInvoiceRequest} returns this
*/
proto.billing_service.CreateFeeInvoiceRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.billing_service.FeeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.FeeItem}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.billing_service.FeeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.CreateFeeInvoiceRequest} returns this
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateFeeInvoiceRequest} returns this
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool auto_pay = 4;
 * @return {boolean}
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.getAutoPay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.CreateFeeInvoiceRequest} returns this
 */
proto.billing_service.CreateFeeInvoiceRequest.prototype.setAutoPay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.FeeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.FeeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.FeeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.FeeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.FeeItem}
 */
proto.billing_service.FeeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.FeeItem;
  return proto.billing_service.FeeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.FeeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.FeeItem}
 */
proto.billing_service.FeeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.FeeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.FeeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.FeeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.FeeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 amount = 1;
 * @return {number}
 */
proto.billing_service.FeeItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.FeeItem} returns this
 */
proto.billing_service.FeeItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.billing_service.FeeItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.FeeItem} returns this
 */
proto.billing_service.FeeItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional domain.Product.ProductType product_type = 3;
 * @return {!proto.domain.Product.ProductType}
 */
proto.billing_service.FeeItem.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.billing_service.FeeItem} returns this
 */
proto.billing_service.FeeItem.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreateFeeInvoiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreateFeeInvoiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateFeeInvoiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    paymentApplied: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreateFeeInvoiceResponse}
 */
proto.billing_service.CreateFeeInvoiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreateFeeInvoiceResponse;
  return proto.billing_service.CreateFeeInvoiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreateFeeInvoiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreateFeeInvoiceResponse}
 */
proto.billing_service.CreateFeeInvoiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentSuccess(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentApplied(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreateFeeInvoiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreateFeeInvoiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateFeeInvoiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPaymentApplied();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string invoice_number = 1;
 * @return {string}
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateFeeInvoiceResponse} returns this
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool payment_success = 2;
 * @return {boolean}
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.getPaymentSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.CreateFeeInvoiceResponse} returns this
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.setPaymentSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool payment_applied = 3;
 * @return {boolean}
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.getPaymentApplied = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.CreateFeeInvoiceResponse} returns this
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.setPaymentApplied = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateFeeInvoiceResponse} returns this
 */
proto.billing_service.CreateFeeInvoiceResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.CreateFinalBillRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreateFinalBillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreateFinalBillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreateFinalBillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateFinalBillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.billing_service.FeeItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreateFinalBillRequest}
 */
proto.billing_service.CreateFinalBillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreateFinalBillRequest;
  return proto.billing_service.CreateFinalBillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreateFinalBillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreateFinalBillRequest}
 */
proto.billing_service.CreateFinalBillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = new proto.billing_service.FeeItem;
      reader.readMessage(value,proto.billing_service.FeeItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreateFinalBillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreateFinalBillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreateFinalBillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateFinalBillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.billing_service.FeeItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.CreateFinalBillRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateFinalBillRequest} returns this
 */
proto.billing_service.CreateFinalBillRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.CreateFinalBillRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateFinalBillRequest} returns this
 */
proto.billing_service.CreateFinalBillRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FeeItem items = 4;
 * @return {!Array<!proto.billing_service.FeeItem>}
 */
proto.billing_service.CreateFinalBillRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.billing_service.FeeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.FeeItem, 4));
};


/**
 * @param {!Array<!proto.billing_service.FeeItem>} value
 * @return {!proto.billing_service.CreateFinalBillRequest} returns this
*/
proto.billing_service.CreateFinalBillRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.billing_service.FeeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.FeeItem}
 */
proto.billing_service.CreateFinalBillRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.billing_service.FeeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.CreateFinalBillRequest} returns this
 */
proto.billing_service.CreateFinalBillRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConfirmFinalBillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConfirmFinalBillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConfirmFinalBillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConfirmFinalBillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConfirmFinalBillRequest}
 */
proto.billing_service.ConfirmFinalBillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConfirmFinalBillRequest;
  return proto.billing_service.ConfirmFinalBillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConfirmFinalBillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConfirmFinalBillRequest}
 */
proto.billing_service.ConfirmFinalBillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConfirmFinalBillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConfirmFinalBillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConfirmFinalBillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConfirmFinalBillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.ConfirmFinalBillRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConfirmFinalBillRequest} returns this
 */
proto.billing_service.ConfirmFinalBillRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.ConfirmFinalBillRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConfirmFinalBillRequest} returns this
 */
proto.billing_service.ConfirmFinalBillRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.OrderFE.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.OrderFE.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.OrderFE.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.OrderFE} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.OrderFE.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updated: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.billing_service.LineItemFE.toObject, includeInstance),
    orderNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.OrderFE}
 */
proto.billing_service.OrderFE.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.OrderFE;
  return proto.billing_service.OrderFE.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.OrderFE} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.OrderFE}
 */
proto.billing_service.OrderFE.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdated(value);
      break;
    case 7:
      var value = new proto.billing_service.LineItemFE;
      reader.readMessage(value,proto.billing_service.LineItemFE.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.OrderFE.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.OrderFE.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.OrderFE} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.OrderFE.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.billing_service.LineItemFE.serializeBinaryToWriter
    );
  }
  f = message.getOrderNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.OrderFE.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.billing_service.OrderFE.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.billing_service.OrderFE.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created = 4;
 * @return {number}
 */
proto.billing_service.OrderFE.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated = 5;
 * @return {number}
 */
proto.billing_service.OrderFE.prototype.getUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated LineItemFE line_items = 7;
 * @return {!Array<!proto.billing_service.LineItemFE>}
 */
proto.billing_service.OrderFE.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.billing_service.LineItemFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.LineItemFE, 7));
};


/**
 * @param {!Array<!proto.billing_service.LineItemFE>} value
 * @return {!proto.billing_service.OrderFE} returns this
*/
proto.billing_service.OrderFE.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.billing_service.LineItemFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.LineItemFE}
 */
proto.billing_service.OrderFE.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.billing_service.LineItemFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional string order_number = 8;
 * @return {string}
 */
proto.billing_service.OrderFE.prototype.getOrderNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setOrderNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string invoice_number = 9;
 * @return {string}
 */
proto.billing_service.OrderFE.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.OrderFE} returns this
 */
proto.billing_service.OrderFE.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetOwnAccountBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetOwnAccountBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetOwnAccountBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnAccountBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetOwnAccountBalanceRequest}
 */
proto.billing_service.GetOwnAccountBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetOwnAccountBalanceRequest;
  return proto.billing_service.GetOwnAccountBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetOwnAccountBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetOwnAccountBalanceRequest}
 */
proto.billing_service.GetOwnAccountBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetOwnAccountBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetOwnAccountBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetOwnAccountBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnAccountBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetAccountBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetAccountBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetAccountBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetAccountBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetAccountBalanceRequest}
 */
proto.billing_service.GetAccountBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetAccountBalanceRequest;
  return proto.billing_service.GetAccountBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetAccountBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetAccountBalanceRequest}
 */
proto.billing_service.GetAccountBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetAccountBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetAccountBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetAccountBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetAccountBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.GetAccountBalanceRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetAccountBalanceRequest} returns this
 */
proto.billing_service.GetAccountBalanceRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetAccountBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetAccountBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetAccountBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetAccountBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openBalance: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pastDueBalance: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetAccountBalanceResponse}
 */
proto.billing_service.GetAccountBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetAccountBalanceResponse;
  return proto.billing_service.GetAccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetAccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetAccountBalanceResponse}
 */
proto.billing_service.GetAccountBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenBalance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPastDueBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetAccountBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetAccountBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetAccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetAccountBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpenBalance();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPastDueBalance();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.GetAccountBalanceResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetAccountBalanceResponse} returns this
 */
proto.billing_service.GetAccountBalanceResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 open_balance = 2;
 * @return {number}
 */
proto.billing_service.GetAccountBalanceResponse.prototype.getOpenBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.GetAccountBalanceResponse} returns this
 */
proto.billing_service.GetAccountBalanceResponse.prototype.setOpenBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 past_due_balance = 3;
 * @return {number}
 */
proto.billing_service.GetAccountBalanceResponse.prototype.getPastDueBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.GetAccountBalanceResponse} returns this
 */
proto.billing_service.GetAccountBalanceResponse.prototype.setPastDueBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListOwnInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListOwnInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListOwnInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListOwnInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListOwnInvoicesRequest}
 */
proto.billing_service.ListOwnInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListOwnInvoicesRequest;
  return proto.billing_service.ListOwnInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListOwnInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListOwnInvoicesRequest}
 */
proto.billing_service.ListOwnInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListOwnInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListOwnInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListOwnInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListOwnInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_Id = 1;
 * @return {string}
 */
proto.billing_service.ListOwnInvoicesRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ListOwnInvoicesRequest} returns this
 */
proto.billing_service.ListOwnInvoicesRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListInvoicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListInvoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListInvoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListInvoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListInvoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.billing_service.InvoiceFE.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListInvoicesResponse}
 */
proto.billing_service.ListInvoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListInvoicesResponse;
  return proto.billing_service.ListInvoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListInvoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListInvoicesResponse}
 */
proto.billing_service.ListInvoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.InvoiceFE;
      reader.readMessage(value,proto.billing_service.InvoiceFE.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListInvoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListInvoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListInvoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListInvoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.InvoiceFE.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated InvoiceFE invoices = 1;
 * @return {!Array<!proto.billing_service.InvoiceFE>}
 */
proto.billing_service.ListInvoicesResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.billing_service.InvoiceFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.InvoiceFE, 1));
};


/**
 * @param {!Array<!proto.billing_service.InvoiceFE>} value
 * @return {!proto.billing_service.ListInvoicesResponse} returns this
*/
proto.billing_service.ListInvoicesResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.InvoiceFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.InvoiceFE}
 */
proto.billing_service.ListInvoicesResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.InvoiceFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListInvoicesResponse} returns this
 */
proto.billing_service.ListInvoicesResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.billing_service.ListInvoicesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ListInvoicesResponse} returns this
 */
proto.billing_service.ListInvoicesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deposit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 3, 0),
    monthlyRent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    color: jspb.Message.getFieldWithDefault(msg, 5, ""),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    make: jspb.Message.getFieldWithDefault(msg, 7, ""),
    model: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 9, ""),
    trackingId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateReservationRequest}
 */
proto.billing_service.UpdateReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateReservationRequest;
  return proto.billing_service.UpdateReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateReservationRequest}
 */
proto.billing_service.UpdateReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryColor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMonthlyRent();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string reservation_id = 1;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 deposit = 2;
 * @return {number}
 */
proto.billing_service.UpdateReservationRequest.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 start_fee = 3;
 * @return {number}
 */
proto.billing_service.UpdateReservationRequest.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 monthly_rent = 4;
 * @return {number}
 */
proto.billing_service.UpdateReservationRequest.prototype.getMonthlyRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setMonthlyRent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string color = 5;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string secondary_color = 6;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getSecondaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string make = 7;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string model = 8;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postal = 9;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tracking_id = 10;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string program_id = 11;
 * @return {string}
 */
proto.billing_service.UpdateReservationRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateReservationRequest} returns this
 */
proto.billing_service.UpdateReservationRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetReservationRequest}
 */
proto.billing_service.GetReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetReservationRequest;
  return proto.billing_service.GetReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetReservationRequest}
 */
proto.billing_service.GetReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reservation_id = 1;
 * @return {string}
 */
proto.billing_service.GetReservationRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetReservationRequest} returns this
 */
proto.billing_service.GetReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListOwnProgramReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListOwnProgramReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListOwnProgramReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListOwnProgramReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListOwnProgramReservationsRequest}
 */
proto.billing_service.ListOwnProgramReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListOwnProgramReservationsRequest;
  return proto.billing_service.ListOwnProgramReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListOwnProgramReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListOwnProgramReservationsRequest}
 */
proto.billing_service.ListOwnProgramReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListOwnProgramReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListOwnProgramReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListOwnProgramReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListOwnProgramReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListReservationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationsList: jspb.Message.toObjectList(msg.getReservationsList(),
    proto.billing_service.Reservation.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListReservationsResponse}
 */
proto.billing_service.ListReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListReservationsResponse;
  return proto.billing_service.ListReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListReservationsResponse}
 */
proto.billing_service.ListReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.Reservation;
      reader.readMessage(value,proto.billing_service.Reservation.deserializeBinaryFromReader);
      msg.addReservations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.Reservation.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Reservation reservations = 1;
 * @return {!Array<!proto.billing_service.Reservation>}
 */
proto.billing_service.ListReservationsResponse.prototype.getReservationsList = function() {
  return /** @type{!Array<!proto.billing_service.Reservation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.Reservation, 1));
};


/**
 * @param {!Array<!proto.billing_service.Reservation>} value
 * @return {!proto.billing_service.ListReservationsResponse} returns this
*/
proto.billing_service.ListReservationsResponse.prototype.setReservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.Reservation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.Reservation}
 */
proto.billing_service.ListReservationsResponse.prototype.addReservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.Reservation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListReservationsResponse} returns this
 */
proto.billing_service.ListReservationsResponse.prototype.clearReservationsList = function() {
  return this.setReservationsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.billing_service.ListReservationsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ListReservationsResponse} returns this
 */
proto.billing_service.ListReservationsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreateReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreateReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreateReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 6, 0),
    monthlyRent: jspb.Message.getFieldWithDefault(msg, 7, 0),
    color: jspb.Message.getFieldWithDefault(msg, 8, ""),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    make: jspb.Message.getFieldWithDefault(msg, 10, ""),
    model: jspb.Message.getFieldWithDefault(msg, 11, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 12, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    email: jspb.Message.getFieldWithDefault(msg, 15, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 16, ""),
    trackingId: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreateReservationRequest}
 */
proto.billing_service.CreateReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreateReservationRequest;
  return proto.billing_service.CreateReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreateReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreateReservationRequest}
 */
proto.billing_service.CreateReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreateReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreateReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreateReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreateReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationFee();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMonthlyRent();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTrackingId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 reservation_fee = 4;
 * @return {number}
 */
proto.billing_service.CreateReservationRequest.prototype.getReservationFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 deposit = 5;
 * @return {number}
 */
proto.billing_service.CreateReservationRequest.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 start_fee = 6;
 * @return {number}
 */
proto.billing_service.CreateReservationRequest.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 monthly_rent = 7;
 * @return {number}
 */
proto.billing_service.CreateReservationRequest.prototype.getMonthlyRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setMonthlyRent = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string color = 8;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string secondary_color = 9;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getSecondaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string make = 10;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string model = 11;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string postal = 12;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string first_name = 13;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string last_name = 14;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string email = 15;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string phone = 16;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string tracking_id = 17;
 * @return {string}
 */
proto.billing_service.CreateReservationRequest.prototype.getTrackingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreateReservationRequest} returns this
 */
proto.billing_service.CreateReservationRequest.prototype.setTrackingId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.InvoiceReference.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.InvoiceReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.InvoiceReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.InvoiceReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invoiceType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.InvoiceReference}
 */
proto.billing_service.InvoiceReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.InvoiceReference;
  return proto.billing_service.InvoiceReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.InvoiceReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.InvoiceReference}
 */
proto.billing_service.InvoiceReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceId(value);
      break;
    case 3:
      var value = /** @type {!proto.billing_service.InvoiceType} */ (reader.readEnum());
      msg.setInvoiceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.InvoiceReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.InvoiceReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.InvoiceReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.InvoiceReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvoiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.InvoiceReference.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.InvoiceReference} returns this
 */
proto.billing_service.InvoiceReference.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string invoice_id = 2;
 * @return {string}
 */
proto.billing_service.InvoiceReference.prototype.getInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.InvoiceReference} returns this
 */
proto.billing_service.InvoiceReference.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional InvoiceType invoice_type = 3;
 * @return {!proto.billing_service.InvoiceType}
 */
proto.billing_service.InvoiceReference.prototype.getInvoiceType = function() {
  return /** @type {!proto.billing_service.InvoiceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing_service.InvoiceType} value
 * @return {!proto.billing_service.InvoiceReference} returns this
 */
proto.billing_service.InvoiceReference.prototype.setInvoiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListProductsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.billing_service.ProductFE.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListProductsResponse}
 */
proto.billing_service.ListProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListProductsResponse;
  return proto.billing_service.ListProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListProductsResponse}
 */
proto.billing_service.ListProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.ProductFE;
      reader.readMessage(value,proto.billing_service.ProductFE.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.ProductFE.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ProductFE products = 1;
 * @return {!Array<!proto.billing_service.ProductFE>}
 */
proto.billing_service.ListProductsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.billing_service.ProductFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.ProductFE, 1));
};


/**
 * @param {!Array<!proto.billing_service.ProductFE>} value
 * @return {!proto.billing_service.ListProductsResponse} returns this
*/
proto.billing_service.ListProductsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.ProductFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.ProductFE}
 */
proto.billing_service.ListProductsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.ProductFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListProductsResponse} returns this
 */
proto.billing_service.ListProductsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.billing_service.ListProductsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ListProductsResponse} returns this
 */
proto.billing_service.ListProductsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ProductFE.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ProductFE.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ProductFE} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ProductFE.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isRecurring: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ProductFE}
 */
proto.billing_service.ProductFE.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ProductFE;
  return proto.billing_service.ProductFE.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ProductFE} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ProductFE}
 */
proto.billing_service.ProductFE.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecurring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ProductFE.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ProductFE.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ProductFE} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ProductFE.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsRecurring();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.ProductFE.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ProductFE} returns this
 */
proto.billing_service.ProductFE.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing_service.ProductFE.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ProductFE} returns this
 */
proto.billing_service.ProductFE.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.billing_service.ProductFE.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ProductFE} returns this
 */
proto.billing_service.ProductFE.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_recurring = 4;
 * @return {boolean}
 */
proto.billing_service.ProductFE.prototype.getIsRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ProductFE} returns this
 */
proto.billing_service.ProductFE.prototype.setIsRecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ProgramColorOption.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ProgramColorOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ProgramColorOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ProgramColorOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ProgramColorOption}
 */
proto.billing_service.ProgramColorOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ProgramColorOption;
  return proto.billing_service.ProgramColorOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ProgramColorOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ProgramColorOption}
 */
proto.billing_service.ProgramColorOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ProgramColorOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ProgramColorOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ProgramColorOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ProgramColorOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.ProgramColorOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ProgramColorOption} returns this
 */
proto.billing_service.ProgramColorOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing_service.ProgramColorOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ProgramColorOption} returns this
 */
proto.billing_service.ProgramColorOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.DatedProgramPricing.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DatedProgramPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DatedProgramPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DatedProgramPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DatedProgramPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pricingOptionsList: jspb.Message.toObjectList(msg.getPricingOptionsList(),
    proto.billing_service.PricingOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DatedProgramPricing}
 */
proto.billing_service.DatedProgramPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DatedProgramPricing;
  return proto.billing_service.DatedProgramPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DatedProgramPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DatedProgramPricing}
 */
proto.billing_service.DatedProgramPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 5:
      var value = new proto.billing_service.PricingOption;
      reader.readMessage(value,proto.billing_service.PricingOption.deserializeBinaryFromReader);
      msg.addPricingOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DatedProgramPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DatedProgramPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DatedProgramPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DatedProgramPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getToDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPricingOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.billing_service.PricingOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.DatedProgramPricing.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DatedProgramPricing} returns this
 */
proto.billing_service.DatedProgramPricing.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string program_id = 2;
 * @return {string}
 */
proto.billing_service.DatedProgramPricing.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DatedProgramPricing} returns this
 */
proto.billing_service.DatedProgramPricing.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 from_date = 3;
 * @return {number}
 */
proto.billing_service.DatedProgramPricing.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.DatedProgramPricing} returns this
 */
proto.billing_service.DatedProgramPricing.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 to_date = 4;
 * @return {number}
 */
proto.billing_service.DatedProgramPricing.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.DatedProgramPricing} returns this
 */
proto.billing_service.DatedProgramPricing.prototype.setToDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated PricingOption pricing_options = 5;
 * @return {!Array<!proto.billing_service.PricingOption>}
 */
proto.billing_service.DatedProgramPricing.prototype.getPricingOptionsList = function() {
  return /** @type{!Array<!proto.billing_service.PricingOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.PricingOption, 5));
};


/**
 * @param {!Array<!proto.billing_service.PricingOption>} value
 * @return {!proto.billing_service.DatedProgramPricing} returns this
*/
proto.billing_service.DatedProgramPricing.prototype.setPricingOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.billing_service.PricingOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PricingOption}
 */
proto.billing_service.DatedProgramPricing.prototype.addPricingOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.billing_service.PricingOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.DatedProgramPricing} returns this
 */
proto.billing_service.DatedProgramPricing.prototype.clearPricingOptionsList = function() {
  return this.setPricingOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PricingOption.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PricingOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PricingOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PricingOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 4, 0),
    monthlyRent: jspb.Message.getFieldWithDefault(msg, 5, 0),
    priceTermMonths: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PricingOption}
 */
proto.billing_service.PricingOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PricingOption;
  return proto.billing_service.PricingOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PricingOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PricingOption}
 */
proto.billing_service.PricingOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PricingOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PricingOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PricingOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PricingOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationFee();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMonthlyRent();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPriceTermMonths();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.PricingOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PricingOption} returns this
 */
proto.billing_service.PricingOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 reservation_fee = 2;
 * @return {number}
 */
proto.billing_service.PricingOption.prototype.getReservationFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PricingOption} returns this
 */
proto.billing_service.PricingOption.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 deposit = 3;
 * @return {number}
 */
proto.billing_service.PricingOption.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PricingOption} returns this
 */
proto.billing_service.PricingOption.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 start_fee = 4;
 * @return {number}
 */
proto.billing_service.PricingOption.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PricingOption} returns this
 */
proto.billing_service.PricingOption.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 monthly_rent = 5;
 * @return {number}
 */
proto.billing_service.PricingOption.prototype.getMonthlyRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PricingOption} returns this
 */
proto.billing_service.PricingOption.prototype.setMonthlyRent = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 price_term_months = 6;
 * @return {number}
 */
proto.billing_service.PricingOption.prototype.getPriceTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PricingOption} returns this
 */
proto.billing_service.PricingOption.prototype.setPriceTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.Program.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.Program.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.Program.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.Program} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Program.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trimOptions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    colorOptionsList: jspb.Message.toObjectList(msg.getColorOptionsList(),
    proto.billing_service.ProgramColorOption.toObject, includeInstance),
    pricingOptionsList: jspb.Message.toObjectList(msg.getPricingOptionsList(),
    proto.billing_service.DatedProgramPricing.toObject, includeInstance),
    make: jspb.Message.getFieldWithDefault(msg, 8, ""),
    model: jspb.Message.getFieldWithDefault(msg, 9, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.Program}
 */
proto.billing_service.Program.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.Program;
  return proto.billing_service.Program.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.Program} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.Program}
 */
proto.billing_service.Program.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrimOptions(value);
      break;
    case 6:
      var value = new proto.billing_service.ProgramColorOption;
      reader.readMessage(value,proto.billing_service.ProgramColorOption.deserializeBinaryFromReader);
      msg.addColorOptions(value);
      break;
    case 7:
      var value = new proto.billing_service.DatedProgramPricing;
      reader.readMessage(value,proto.billing_service.DatedProgramPricing.deserializeBinaryFromReader);
      msg.addPricingOptions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.Program.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.Program.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.Program} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Program.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTrimOptions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getColorOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.billing_service.ProgramColorOption.serializeBinaryToWriter
    );
  }
  f = message.getPricingOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.billing_service.DatedProgramPricing.serializeBinaryToWriter
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.Program.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing_service.Program.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.billing_service.Program.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created = 4;
 * @return {number}
 */
proto.billing_service.Program.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string trim_options = 5;
 * @return {string}
 */
proto.billing_service.Program.prototype.getTrimOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setTrimOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ProgramColorOption color_options = 6;
 * @return {!Array<!proto.billing_service.ProgramColorOption>}
 */
proto.billing_service.Program.prototype.getColorOptionsList = function() {
  return /** @type{!Array<!proto.billing_service.ProgramColorOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.ProgramColorOption, 6));
};


/**
 * @param {!Array<!proto.billing_service.ProgramColorOption>} value
 * @return {!proto.billing_service.Program} returns this
*/
proto.billing_service.Program.prototype.setColorOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.billing_service.ProgramColorOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.ProgramColorOption}
 */
proto.billing_service.Program.prototype.addColorOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.billing_service.ProgramColorOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.clearColorOptionsList = function() {
  return this.setColorOptionsList([]);
};


/**
 * repeated DatedProgramPricing pricing_options = 7;
 * @return {!Array<!proto.billing_service.DatedProgramPricing>}
 */
proto.billing_service.Program.prototype.getPricingOptionsList = function() {
  return /** @type{!Array<!proto.billing_service.DatedProgramPricing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.DatedProgramPricing, 7));
};


/**
 * @param {!Array<!proto.billing_service.DatedProgramPricing>} value
 * @return {!proto.billing_service.Program} returns this
*/
proto.billing_service.Program.prototype.setPricingOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.billing_service.DatedProgramPricing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.DatedProgramPricing}
 */
proto.billing_service.Program.prototype.addPricingOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.billing_service.DatedProgramPricing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.clearPricingOptionsList = function() {
  return this.setPricingOptionsList([]);
};


/**
 * optional string make = 8;
 * @return {string}
 */
proto.billing_service.Program.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string model = 9;
 * @return {string}
 */
proto.billing_service.Program.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool active = 10;
 * @return {boolean}
 */
proto.billing_service.Program.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool hidden = 11;
 * @return {boolean}
 */
proto.billing_service.Program.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.Program} returns this
 */
proto.billing_service.Program.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListProgramsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListProgramsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListProgramsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListProgramsRequest}
 */
proto.billing_service.ListProgramsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListProgramsRequest;
  return proto.billing_service.ListProgramsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListProgramsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListProgramsRequest}
 */
proto.billing_service.ListProgramsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListProgramsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListProgramsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListProgramsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListProgramsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListProgramsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListProgramsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListProgramsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    programsList: jspb.Message.toObjectList(msg.getProgramsList(),
    proto.billing_service.Program.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListProgramsResponse}
 */
proto.billing_service.ListProgramsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListProgramsResponse;
  return proto.billing_service.ListProgramsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListProgramsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListProgramsResponse}
 */
proto.billing_service.ListProgramsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.Program;
      reader.readMessage(value,proto.billing_service.Program.deserializeBinaryFromReader);
      msg.addPrograms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListProgramsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListProgramsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListProgramsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.Program.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Program programs = 1;
 * @return {!Array<!proto.billing_service.Program>}
 */
proto.billing_service.ListProgramsResponse.prototype.getProgramsList = function() {
  return /** @type{!Array<!proto.billing_service.Program>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.Program, 1));
};


/**
 * @param {!Array<!proto.billing_service.Program>} value
 * @return {!proto.billing_service.ListProgramsResponse} returns this
*/
proto.billing_service.ListProgramsResponse.prototype.setProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.Program=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.Program}
 */
proto.billing_service.ListProgramsResponse.prototype.addPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.Program, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListProgramsResponse} returns this
 */
proto.billing_service.ListProgramsResponse.prototype.clearProgramsList = function() {
  return this.setProgramsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DetailsRequest}
 */
proto.billing_service.DetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DetailsRequest;
  return proto.billing_service.DetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DetailsRequest}
 */
proto.billing_service.DetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string promo_code = 1;
 * @return {string}
 */
proto.billing_service.DetailsRequest.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DetailsRequest} returns this
 */
proto.billing_service.DetailsRequest.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PromotionConstraint.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PromotionConstraint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PromotionConstraint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionConstraint.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    promotionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    constraintDimension: jspb.Message.getFieldWithDefault(msg, 3, 0),
    constraintProperty: jspb.Message.getFieldWithDefault(msg, 4, ""),
    constraintValue: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PromotionConstraint}
 */
proto.billing_service.PromotionConstraint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PromotionConstraint;
  return proto.billing_service.PromotionConstraint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PromotionConstraint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PromotionConstraint}
 */
proto.billing_service.PromotionConstraint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    case 3:
      var value = /** @type {!proto.billing_service.PromotionConstraint.ConstraintDimension} */ (reader.readEnum());
      msg.setConstraintDimension(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstraintProperty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConstraintValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PromotionConstraint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PromotionConstraint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PromotionConstraint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionConstraint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConstraintDimension();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getConstraintProperty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConstraintValue();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.billing_service.PromotionConstraint.ConstraintDimension = {
  APPLICATION_COUNT: 0
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.PromotionConstraint.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionConstraint} returns this
 */
proto.billing_service.PromotionConstraint.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string promotion_id = 2;
 * @return {string}
 */
proto.billing_service.PromotionConstraint.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionConstraint} returns this
 */
proto.billing_service.PromotionConstraint.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ConstraintDimension constraint_dimension = 3;
 * @return {!proto.billing_service.PromotionConstraint.ConstraintDimension}
 */
proto.billing_service.PromotionConstraint.prototype.getConstraintDimension = function() {
  return /** @type {!proto.billing_service.PromotionConstraint.ConstraintDimension} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.billing_service.PromotionConstraint.ConstraintDimension} value
 * @return {!proto.billing_service.PromotionConstraint} returns this
 */
proto.billing_service.PromotionConstraint.prototype.setConstraintDimension = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string constraint_property = 4;
 * @return {string}
 */
proto.billing_service.PromotionConstraint.prototype.getConstraintProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionConstraint} returns this
 */
proto.billing_service.PromotionConstraint.prototype.setConstraintProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 constraint_value = 5;
 * @return {number}
 */
proto.billing_service.PromotionConstraint.prototype.getConstraintValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PromotionConstraint} returns this
 */
proto.billing_service.PromotionConstraint.prototype.setConstraintValue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PromotionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PromotionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PromotionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    promotionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rebateValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rebatePercent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    target: jspb.Message.getFieldWithDefault(msg, 5, 0),
    source: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sourceName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sourceId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PromotionDetails}
 */
proto.billing_service.PromotionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PromotionDetails;
  return proto.billing_service.PromotionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PromotionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PromotionDetails}
 */
proto.billing_service.PromotionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRebateValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebatePercent(value);
      break;
    case 5:
      var value = /** @type {!proto.billing_service.PromotionTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 6:
      var value = /** @type {!proto.billing_service.PromotionSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PromotionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PromotionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PromotionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRebateValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRebatePercent();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSourceName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string promotion_id = 1;
 * @return {string}
 */
proto.billing_service.PromotionDetails.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string promo_code = 2;
 * @return {string}
 */
proto.billing_service.PromotionDetails.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 rebate_value = 3;
 * @return {number}
 */
proto.billing_service.PromotionDetails.prototype.getRebateValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setRebateValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 rebate_percent = 4;
 * @return {number}
 */
proto.billing_service.PromotionDetails.prototype.getRebatePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setRebatePercent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PromotionTarget target = 5;
 * @return {!proto.billing_service.PromotionTarget}
 */
proto.billing_service.PromotionDetails.prototype.getTarget = function() {
  return /** @type {!proto.billing_service.PromotionTarget} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing_service.PromotionTarget} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PromotionSource source = 6;
 * @return {!proto.billing_service.PromotionSource}
 */
proto.billing_service.PromotionDetails.prototype.getSource = function() {
  return /** @type {!proto.billing_service.PromotionSource} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing_service.PromotionSource} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string source_name = 7;
 * @return {string}
 */
proto.billing_service.PromotionDetails.prototype.getSourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setSourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string source_id = 8;
 * @return {string}
 */
proto.billing_service.PromotionDetails.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionDetails} returns this
 */
proto.billing_service.PromotionDetails.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RedemptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RedemptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RedemptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RedemptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RedemptionRequest}
 */
proto.billing_service.RedemptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RedemptionRequest;
  return proto.billing_service.RedemptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RedemptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RedemptionRequest}
 */
proto.billing_service.RedemptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RedemptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RedemptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RedemptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RedemptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string promo_code = 1;
 * @return {string}
 */
proto.billing_service.RedemptionRequest.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RedemptionRequest} returns this
 */
proto.billing_service.RedemptionRequest.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.RedemptionRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RedemptionRequest} returns this
 */
proto.billing_service.RedemptionRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RedemptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RedemptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RedemptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RedemptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RedemptionResponse}
 */
proto.billing_service.RedemptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RedemptionResponse;
  return proto.billing_service.RedemptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RedemptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RedemptionResponse}
 */
proto.billing_service.RedemptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RedemptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RedemptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RedemptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RedemptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ReferralCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ReferralCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ReferralCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ReferralCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ReferralCodeRequest}
 */
proto.billing_service.ReferralCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ReferralCodeRequest;
  return proto.billing_service.ReferralCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ReferralCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ReferralCodeRequest}
 */
proto.billing_service.ReferralCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ReferralCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ReferralCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ReferralCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ReferralCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetSubscriptionByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetSubscriptionByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetSubscriptionByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetSubscriptionByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetSubscriptionByIdRequest}
 */
proto.billing_service.GetSubscriptionByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetSubscriptionByIdRequest;
  return proto.billing_service.GetSubscriptionByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetSubscriptionByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetSubscriptionByIdRequest}
 */
proto.billing_service.GetSubscriptionByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetSubscriptionByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetSubscriptionByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetSubscriptionByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetSubscriptionByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.GetSubscriptionByIdRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetSubscriptionByIdRequest} returns this
 */
proto.billing_service.GetSubscriptionByIdRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CustomerAccountRow.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CustomerAccountRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CustomerAccountRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CustomerAccountRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subscriptionActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    openBalance: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pastDueBalance: jspb.Message.getFieldWithDefault(msg, 7, 0),
    daysPastDue: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CustomerAccountRow}
 */
proto.billing_service.CustomerAccountRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CustomerAccountRow;
  return proto.billing_service.CustomerAccountRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CustomerAccountRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CustomerAccountRow}
 */
proto.billing_service.CustomerAccountRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriptionActive(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOpenBalance(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPastDueBalance(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDaysPastDue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CustomerAccountRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CustomerAccountRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CustomerAccountRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CustomerAccountRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubscriptionActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOpenBalance();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPastDueBalance();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDaysPastDue();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.CustomerAccountRow.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_number = 2;
 * @return {string}
 */
proto.billing_service.CustomerAccountRow.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.billing_service.CustomerAccountRow.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.billing_service.CustomerAccountRow.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool subscription_active = 5;
 * @return {boolean}
 */
proto.billing_service.CustomerAccountRow.prototype.getSubscriptionActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setSubscriptionActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 open_balance = 6;
 * @return {number}
 */
proto.billing_service.CustomerAccountRow.prototype.getOpenBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setOpenBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 past_due_balance = 7;
 * @return {number}
 */
proto.billing_service.CustomerAccountRow.prototype.getPastDueBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setPastDueBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 days_past_due = 8;
 * @return {number}
 */
proto.billing_service.CustomerAccountRow.prototype.getDaysPastDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CustomerAccountRow} returns this
 */
proto.billing_service.CustomerAccountRow.prototype.setDaysPastDue = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetSubscriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetSubscriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetSubscriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetSubscriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscription: (f = msg.getSubscription()) && proto.billing_service.Subscription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetSubscriptionResponse}
 */
proto.billing_service.GetSubscriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetSubscriptionResponse;
  return proto.billing_service.GetSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetSubscriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetSubscriptionResponse}
 */
proto.billing_service.GetSubscriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.Subscription;
      reader.readMessage(value,proto.billing_service.Subscription.deserializeBinaryFromReader);
      msg.setSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetSubscriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetSubscriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetSubscriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetSubscriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscription();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing_service.Subscription.serializeBinaryToWriter
    );
  }
};


/**
 * optional Subscription subscription = 1;
 * @return {?proto.billing_service.Subscription}
 */
proto.billing_service.GetSubscriptionResponse.prototype.getSubscription = function() {
  return /** @type{?proto.billing_service.Subscription} */ (
    jspb.Message.getWrapperField(this, proto.billing_service.Subscription, 1));
};


/**
 * @param {?proto.billing_service.Subscription|undefined} value
 * @return {!proto.billing_service.GetSubscriptionResponse} returns this
*/
proto.billing_service.GetSubscriptionResponse.prototype.setSubscription = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing_service.GetSubscriptionResponse} returns this
 */
proto.billing_service.GetSubscriptionResponse.prototype.clearSubscription = function() {
  return this.setSubscription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing_service.GetSubscriptionResponse.prototype.hasSubscription = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ReferralCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ReferralCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ReferralCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ReferralCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    referralCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ReferralCodeResponse}
 */
proto.billing_service.ReferralCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ReferralCodeResponse;
  return proto.billing_service.ReferralCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ReferralCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ReferralCodeResponse}
 */
proto.billing_service.ReferralCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ReferralCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ReferralCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ReferralCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ReferralCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferralCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string referral_code = 1;
 * @return {string}
 */
proto.billing_service.ReferralCodeResponse.prototype.getReferralCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ReferralCodeResponse} returns this
 */
proto.billing_service.ReferralCodeResponse.prototype.setReferralCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.SeedReferralPromotionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.SeedReferralPromotionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.SeedReferralPromotionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SeedReferralPromotionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.SeedReferralPromotionsRequest}
 */
proto.billing_service.SeedReferralPromotionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.SeedReferralPromotionsRequest;
  return proto.billing_service.SeedReferralPromotionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.SeedReferralPromotionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.SeedReferralPromotionsRequest}
 */
proto.billing_service.SeedReferralPromotionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.SeedReferralPromotionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.SeedReferralPromotionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.SeedReferralPromotionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SeedReferralPromotionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.SeedReferralPromotionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.SeedReferralPromotionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.SeedReferralPromotionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SeedReferralPromotionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.SeedReferralPromotionsResponse}
 */
proto.billing_service.SeedReferralPromotionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.SeedReferralPromotionsResponse;
  return proto.billing_service.SeedReferralPromotionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.SeedReferralPromotionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.SeedReferralPromotionsResponse}
 */
proto.billing_service.SeedReferralPromotionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.SeedReferralPromotionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.SeedReferralPromotionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.SeedReferralPromotionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SeedReferralPromotionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportReferralPromotionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportReferralPromotionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportReferralPromotionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralPromotionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportReferralPromotionsRequest}
 */
proto.billing_service.ExportReferralPromotionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportReferralPromotionsRequest;
  return proto.billing_service.ExportReferralPromotionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportReferralPromotionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportReferralPromotionsRequest}
 */
proto.billing_service.ExportReferralPromotionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportReferralPromotionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportReferralPromotionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportReferralPromotionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralPromotionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportedReferralPromotion.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportedReferralPromotion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportedReferralPromotion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportedReferralPromotion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    validFrom: jspb.Message.getFieldWithDefault(msg, 8, 0),
    validUntil: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportedReferralPromotion}
 */
proto.billing_service.ExportedReferralPromotion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportedReferralPromotion;
  return proto.billing_service.ExportedReferralPromotion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportedReferralPromotion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportedReferralPromotion}
 */
proto.billing_service.ExportedReferralPromotion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidFrom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidUntil(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportedReferralPromotion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportedReferralPromotion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportedReferralPromotion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportedReferralPromotion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getValidFrom();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string promo_code = 7;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 valid_from = 8;
 * @return {number}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getValidFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setValidFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 valid_until = 9;
 * @return {number}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool status = 10;
 * @return {boolean}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string account_number = 11;
 * @return {string}
 */
proto.billing_service.ExportedReferralPromotion.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ExportedReferralPromotion} returns this
 */
proto.billing_service.ExportedReferralPromotion.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ExportReferralPromotionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportReferralPromotionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportReferralPromotionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportReferralPromotionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralPromotionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promosList: jspb.Message.toObjectList(msg.getPromosList(),
    proto.billing_service.ExportedReferralPromotion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportReferralPromotionsResponse}
 */
proto.billing_service.ExportReferralPromotionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportReferralPromotionsResponse;
  return proto.billing_service.ExportReferralPromotionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportReferralPromotionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportReferralPromotionsResponse}
 */
proto.billing_service.ExportReferralPromotionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.ExportedReferralPromotion;
      reader.readMessage(value,proto.billing_service.ExportedReferralPromotion.deserializeBinaryFromReader);
      msg.addPromos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportReferralPromotionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportReferralPromotionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportReferralPromotionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralPromotionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.ExportedReferralPromotion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExportedReferralPromotion promos = 1;
 * @return {!Array<!proto.billing_service.ExportedReferralPromotion>}
 */
proto.billing_service.ExportReferralPromotionsResponse.prototype.getPromosList = function() {
  return /** @type{!Array<!proto.billing_service.ExportedReferralPromotion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.ExportedReferralPromotion, 1));
};


/**
 * @param {!Array<!proto.billing_service.ExportedReferralPromotion>} value
 * @return {!proto.billing_service.ExportReferralPromotionsResponse} returns this
*/
proto.billing_service.ExportReferralPromotionsResponse.prototype.setPromosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.ExportedReferralPromotion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.ExportedReferralPromotion}
 */
proto.billing_service.ExportReferralPromotionsResponse.prototype.addPromos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.ExportedReferralPromotion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ExportReferralPromotionsResponse} returns this
 */
proto.billing_service.ExportReferralPromotionsResponse.prototype.clearPromosList = function() {
  return this.setPromosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.CreatePromotionRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreatePromotionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreatePromotionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreatePromotionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    promoCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rebateValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rebatePercent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    target: jspb.Message.getFieldWithDefault(msg, 4, 0),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sourceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    validFrom: jspb.Message.getFieldWithDefault(msg, 8, 0),
    validUntil: jspb.Message.getFieldWithDefault(msg, 9, 0),
    constraintsList: jspb.Message.toObjectList(msg.getConstraintsList(),
    proto.billing_service.PromotionConstraint.toObject, includeInstance),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreatePromotionRequest}
 */
proto.billing_service.CreatePromotionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreatePromotionRequest;
  return proto.billing_service.CreatePromotionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreatePromotionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreatePromotionRequest}
 */
proto.billing_service.CreatePromotionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRebateValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebatePercent(value);
      break;
    case 4:
      var value = /** @type {!proto.billing_service.PromotionTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 5:
      var value = /** @type {!proto.billing_service.PromotionSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidFrom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidUntil(value);
      break;
    case 10:
      var value = new proto.billing_service.PromotionConstraint;
      reader.readMessage(value,proto.billing_service.PromotionConstraint.deserializeBinaryFromReader);
      msg.addConstraints(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreatePromotionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreatePromotionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreatePromotionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRebateValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRebatePercent();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getValidFrom();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getConstraintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.billing_service.PromotionConstraint.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string promo_code = 1;
 * @return {string}
 */
proto.billing_service.CreatePromotionRequest.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 rebate_value = 2;
 * @return {number}
 */
proto.billing_service.CreatePromotionRequest.prototype.getRebateValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setRebateValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rebate_percent = 3;
 * @return {number}
 */
proto.billing_service.CreatePromotionRequest.prototype.getRebatePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setRebatePercent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional PromotionTarget target = 4;
 * @return {!proto.billing_service.PromotionTarget}
 */
proto.billing_service.CreatePromotionRequest.prototype.getTarget = function() {
  return /** @type {!proto.billing_service.PromotionTarget} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing_service.PromotionTarget} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PromotionSource source = 5;
 * @return {!proto.billing_service.PromotionSource}
 */
proto.billing_service.CreatePromotionRequest.prototype.getSource = function() {
  return /** @type {!proto.billing_service.PromotionSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing_service.PromotionSource} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string source_id = 6;
 * @return {string}
 */
proto.billing_service.CreatePromotionRequest.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 valid_from = 8;
 * @return {number}
 */
proto.billing_service.CreatePromotionRequest.prototype.getValidFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setValidFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 valid_until = 9;
 * @return {number}
 */
proto.billing_service.CreatePromotionRequest.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated PromotionConstraint constraints = 10;
 * @return {!Array<!proto.billing_service.PromotionConstraint>}
 */
proto.billing_service.CreatePromotionRequest.prototype.getConstraintsList = function() {
  return /** @type{!Array<!proto.billing_service.PromotionConstraint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.PromotionConstraint, 10));
};


/**
 * @param {!Array<!proto.billing_service.PromotionConstraint>} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
*/
proto.billing_service.CreatePromotionRequest.prototype.setConstraintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.billing_service.PromotionConstraint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PromotionConstraint}
 */
proto.billing_service.CreatePromotionRequest.prototype.addConstraints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.billing_service.PromotionConstraint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.clearConstraintsList = function() {
  return this.setConstraintsList([]);
};


/**
 * optional bool status = 11;
 * @return {boolean}
 */
proto.billing_service.CreatePromotionRequest.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.CreatePromotionRequest} returns this
 */
proto.billing_service.CreatePromotionRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreatePromotionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreatePromotionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreatePromotionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    promotionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreatePromotionResponse}
 */
proto.billing_service.CreatePromotionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreatePromotionResponse;
  return proto.billing_service.CreatePromotionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreatePromotionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreatePromotionResponse}
 */
proto.billing_service.CreatePromotionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreatePromotionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreatePromotionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreatePromotionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string promotion_id = 1;
 * @return {string}
 */
proto.billing_service.CreatePromotionResponse.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreatePromotionResponse} returns this
 */
proto.billing_service.CreatePromotionResponse.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.UpdatePromotionRequest.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdatePromotionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdatePromotionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdatePromotionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    promotionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rebateValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rebatePercent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    target: jspb.Message.getFieldWithDefault(msg, 5, 0),
    source: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sourceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    validFrom: jspb.Message.getFieldWithDefault(msg, 8, 0),
    validUntil: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    constraintsList: jspb.Message.toObjectList(msg.getConstraintsList(),
    proto.billing_service.PromotionConstraint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdatePromotionRequest}
 */
proto.billing_service.UpdatePromotionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdatePromotionRequest;
  return proto.billing_service.UpdatePromotionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdatePromotionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdatePromotionRequest}
 */
proto.billing_service.UpdatePromotionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRebateValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebatePercent(value);
      break;
    case 5:
      var value = /** @type {!proto.billing_service.PromotionTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 6:
      var value = /** @type {!proto.billing_service.PromotionSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidFrom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidUntil(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.billing_service.PromotionConstraint;
      reader.readMessage(value,proto.billing_service.PromotionConstraint.deserializeBinaryFromReader);
      msg.addConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdatePromotionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdatePromotionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdatePromotionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRebateValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRebatePercent();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getValidFrom();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getConstraintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.billing_service.PromotionConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string promotion_id = 1;
 * @return {string}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string promo_code = 2;
 * @return {string}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 rebate_value = 3;
 * @return {number}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getRebateValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setRebateValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 rebate_percent = 4;
 * @return {number}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getRebatePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setRebatePercent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PromotionTarget target = 5;
 * @return {!proto.billing_service.PromotionTarget}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getTarget = function() {
  return /** @type {!proto.billing_service.PromotionTarget} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing_service.PromotionTarget} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PromotionSource source = 6;
 * @return {!proto.billing_service.PromotionSource}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getSource = function() {
  return /** @type {!proto.billing_service.PromotionSource} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing_service.PromotionSource} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string source_id = 7;
 * @return {string}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 valid_from = 8;
 * @return {number}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getValidFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setValidFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 valid_until = 9;
 * @return {number}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool status = 10;
 * @return {boolean}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated PromotionConstraint constraints = 11;
 * @return {!Array<!proto.billing_service.PromotionConstraint>}
 */
proto.billing_service.UpdatePromotionRequest.prototype.getConstraintsList = function() {
  return /** @type{!Array<!proto.billing_service.PromotionConstraint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.PromotionConstraint, 11));
};


/**
 * @param {!Array<!proto.billing_service.PromotionConstraint>} value
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
*/
proto.billing_service.UpdatePromotionRequest.prototype.setConstraintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.billing_service.PromotionConstraint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PromotionConstraint}
 */
proto.billing_service.UpdatePromotionRequest.prototype.addConstraints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.billing_service.PromotionConstraint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.UpdatePromotionRequest} returns this
 */
proto.billing_service.UpdatePromotionRequest.prototype.clearConstraintsList = function() {
  return this.setConstraintsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdatePromotionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdatePromotionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdatePromotionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdatePromotionResponse}
 */
proto.billing_service.UpdatePromotionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdatePromotionResponse;
  return proto.billing_service.UpdatePromotionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdatePromotionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdatePromotionResponse}
 */
proto.billing_service.UpdatePromotionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdatePromotionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdatePromotionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdatePromotionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListPromotionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListPromotionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListPromotionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListPromotionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListPromotionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing_service.Promotion.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListPromotionsResponse}
 */
proto.billing_service.ListPromotionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListPromotionsResponse;
  return proto.billing_service.ListPromotionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListPromotionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListPromotionsResponse}
 */
proto.billing_service.ListPromotionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.Promotion;
      reader.readMessage(value,proto.billing_service.Promotion.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListPromotionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListPromotionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListPromotionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListPromotionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.Promotion.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Promotion results = 1;
 * @return {!Array<!proto.billing_service.Promotion>}
 */
proto.billing_service.ListPromotionsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing_service.Promotion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.Promotion, 1));
};


/**
 * @param {!Array<!proto.billing_service.Promotion>} value
 * @return {!proto.billing_service.ListPromotionsResponse} returns this
*/
proto.billing_service.ListPromotionsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.Promotion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.Promotion}
 */
proto.billing_service.ListPromotionsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.Promotion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListPromotionsResponse} returns this
 */
proto.billing_service.ListPromotionsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.billing_service.ListPromotionsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ListPromotionsResponse} returns this
 */
proto.billing_service.ListPromotionsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreatePromotionPartnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreatePromotionPartnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionPartnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreatePromotionPartnerRequest}
 */
proto.billing_service.CreatePromotionPartnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreatePromotionPartnerRequest;
  return proto.billing_service.CreatePromotionPartnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreatePromotionPartnerRequest}
 */
proto.billing_service.CreatePromotionPartnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreatePromotionPartnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreatePromotionPartnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionPartnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.billing_service.CreatePromotionPartnerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreatePromotionPartnerRequest} returns this
 */
proto.billing_service.CreatePromotionPartnerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.CreatePromotionPartnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.CreatePromotionPartnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.CreatePromotionPartnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionPartnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.CreatePromotionPartnerResponse}
 */
proto.billing_service.CreatePromotionPartnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.CreatePromotionPartnerResponse;
  return proto.billing_service.CreatePromotionPartnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.CreatePromotionPartnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.CreatePromotionPartnerResponse}
 */
proto.billing_service.CreatePromotionPartnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.CreatePromotionPartnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.CreatePromotionPartnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.CreatePromotionPartnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.CreatePromotionPartnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.CreatePromotionPartnerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.CreatePromotionPartnerResponse} returns this
 */
proto.billing_service.CreatePromotionPartnerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdatePromotionPartnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdatePromotionPartnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdatePromotionPartnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionPartnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdatePromotionPartnerRequest}
 */
proto.billing_service.UpdatePromotionPartnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdatePromotionPartnerRequest;
  return proto.billing_service.UpdatePromotionPartnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdatePromotionPartnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdatePromotionPartnerRequest}
 */
proto.billing_service.UpdatePromotionPartnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdatePromotionPartnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdatePromotionPartnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdatePromotionPartnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionPartnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.UpdatePromotionPartnerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdatePromotionPartnerRequest} returns this
 */
proto.billing_service.UpdatePromotionPartnerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing_service.UpdatePromotionPartnerRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdatePromotionPartnerRequest} returns this
 */
proto.billing_service.UpdatePromotionPartnerRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdatePromotionPartnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdatePromotionPartnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdatePromotionPartnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionPartnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdatePromotionPartnerResponse}
 */
proto.billing_service.UpdatePromotionPartnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdatePromotionPartnerResponse;
  return proto.billing_service.UpdatePromotionPartnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdatePromotionPartnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdatePromotionPartnerResponse}
 */
proto.billing_service.UpdatePromotionPartnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdatePromotionPartnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdatePromotionPartnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdatePromotionPartnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdatePromotionPartnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListPromotionPartnersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListPromotionPartnersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListPromotionPartnersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListPromotionPartnersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.billing_service.PromotionPartner.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListPromotionPartnersResponse}
 */
proto.billing_service.ListPromotionPartnersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListPromotionPartnersResponse;
  return proto.billing_service.ListPromotionPartnersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListPromotionPartnersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListPromotionPartnersResponse}
 */
proto.billing_service.ListPromotionPartnersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.PromotionPartner;
      reader.readMessage(value,proto.billing_service.PromotionPartner.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListPromotionPartnersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListPromotionPartnersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListPromotionPartnersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.PromotionPartner.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated PromotionPartner results = 1;
 * @return {!Array<!proto.billing_service.PromotionPartner>}
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.billing_service.PromotionPartner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.PromotionPartner, 1));
};


/**
 * @param {!Array<!proto.billing_service.PromotionPartner>} value
 * @return {!proto.billing_service.ListPromotionPartnersResponse} returns this
*/
proto.billing_service.ListPromotionPartnersResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.PromotionPartner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PromotionPartner}
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.PromotionPartner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListPromotionPartnersResponse} returns this
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ListPromotionPartnersResponse} returns this
 */
proto.billing_service.ListPromotionPartnersResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageWestlakeDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageWestlakeDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageWestlakeDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageWestlakeDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageWestlakeExports: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageWestlakeDataRequest}
 */
proto.billing_service.StageWestlakeDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageWestlakeDataRequest;
  return proto.billing_service.StageWestlakeDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageWestlakeDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageWestlakeDataRequest}
 */
proto.billing_service.StageWestlakeDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStageWestlakeExports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageWestlakeDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageWestlakeDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageWestlakeDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageWestlakeDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageWestlakeExports();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool stage_westlake_exports = 1;
 * @return {boolean}
 */
proto.billing_service.StageWestlakeDataRequest.prototype.getStageWestlakeExports = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.StageWestlakeDataRequest} returns this
 */
proto.billing_service.StageWestlakeDataRequest.prototype.setStageWestlakeExports = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageWestlakeDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageWestlakeDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageWestlakeDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageWestlakeDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageWestlakeDataResponse}
 */
proto.billing_service.StageWestlakeDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageWestlakeDataResponse;
  return proto.billing_service.StageWestlakeDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageWestlakeDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageWestlakeDataResponse}
 */
proto.billing_service.StageWestlakeDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageWestlakeDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageWestlakeDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageWestlakeDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageWestlakeDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageCustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageCustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageCustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageCustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageCustomerRequest}
 */
proto.billing_service.StageCustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageCustomerRequest;
  return proto.billing_service.StageCustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageCustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageCustomerRequest}
 */
proto.billing_service.StageCustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageCustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageCustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageCustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageCustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.StageCustomerRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.StageCustomerRequest} returns this
 */
proto.billing_service.StageCustomerRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageCustomerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageCustomerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageCustomerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageCustomerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageCustomerResponse}
 */
proto.billing_service.StageCustomerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageCustomerResponse;
  return proto.billing_service.StageCustomerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageCustomerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageCustomerResponse}
 */
proto.billing_service.StageCustomerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageCustomerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageCustomerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageCustomerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageCustomerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageReservationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageReservationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageReservationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageReservationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reservationId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageReservationRequest}
 */
proto.billing_service.StageReservationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageReservationRequest;
  return proto.billing_service.StageReservationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageReservationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageReservationRequest}
 */
proto.billing_service.StageReservationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageReservationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageReservationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageReservationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageReservationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reservation_id = 1;
 * @return {string}
 */
proto.billing_service.StageReservationRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.StageReservationRequest} returns this
 */
proto.billing_service.StageReservationRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageReservationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageReservationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageReservationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageReservationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageReservationResponse}
 */
proto.billing_service.StageReservationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageReservationResponse;
  return proto.billing_service.StageReservationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageReservationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageReservationResponse}
 */
proto.billing_service.StageReservationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageReservationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageReservationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageReservationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageReservationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageAllReservationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageAllReservationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageAllReservationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageAllReservationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageAllReservationsRequest}
 */
proto.billing_service.StageAllReservationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageAllReservationsRequest;
  return proto.billing_service.StageAllReservationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageAllReservationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageAllReservationsRequest}
 */
proto.billing_service.StageAllReservationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageAllReservationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageAllReservationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageAllReservationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageAllReservationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageAllReservationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageAllReservationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageAllReservationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageAllReservationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageAllReservationsResponse}
 */
proto.billing_service.StageAllReservationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageAllReservationsResponse;
  return proto.billing_service.StageAllReservationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageAllReservationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageAllReservationsResponse}
 */
proto.billing_service.StageAllReservationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageAllReservationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageAllReservationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageAllReservationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageAllReservationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageDataRequest}
 */
proto.billing_service.StageDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageDataRequest;
  return proto.billing_service.StageDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageDataRequest}
 */
proto.billing_service.StageDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.StageDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.StageDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.StageDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.StageDataResponse}
 */
proto.billing_service.StageDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.StageDataResponse;
  return proto.billing_service.StageDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.StageDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.StageDataResponse}
 */
proto.billing_service.StageDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.StageDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.StageDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.StageDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.StageDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportWestlakePaymentDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportWestlakePaymentDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportWestlakePaymentDetailsRequest}
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportWestlakePaymentDetailsRequest;
  return proto.billing_service.ExportWestlakePaymentDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportWestlakePaymentDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportWestlakePaymentDetailsRequest}
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportWestlakePaymentDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportWestlakePaymentDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendEmail();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool send_email = 1;
 * @return {boolean}
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.prototype.getSendEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportWestlakePaymentDetailsRequest} returns this
 */
proto.billing_service.ExportWestlakePaymentDetailsRequest.prototype.setSendEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportWestlakePaymentDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportWestlakePaymentDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportWestlakePaymentDetailsResponse}
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportWestlakePaymentDetailsResponse;
  return proto.billing_service.ExportWestlakePaymentDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportWestlakePaymentDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportWestlakePaymentDetailsResponse}
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportWestlakePaymentDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportWestlakePaymentDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportWestlakePaymentDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportCustomerPromotionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportCustomerPromotionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportCustomerPromotionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportCustomerPromotionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportCustomerPromotionsRequest}
 */
proto.billing_service.ExportCustomerPromotionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportCustomerPromotionsRequest;
  return proto.billing_service.ExportCustomerPromotionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportCustomerPromotionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportCustomerPromotionsRequest}
 */
proto.billing_service.ExportCustomerPromotionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportCustomerPromotionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportCustomerPromotionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportCustomerPromotionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportCustomerPromotionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendEmail();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool send_email = 1;
 * @return {boolean}
 */
proto.billing_service.ExportCustomerPromotionsRequest.prototype.getSendEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportCustomerPromotionsRequest} returns this
 */
proto.billing_service.ExportCustomerPromotionsRequest.prototype.setSendEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportCustomerPromotionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportCustomerPromotionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportCustomerPromotionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportCustomerPromotionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportCustomerPromotionsResponse}
 */
proto.billing_service.ExportCustomerPromotionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportCustomerPromotionsResponse;
  return proto.billing_service.ExportCustomerPromotionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportCustomerPromotionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportCustomerPromotionsResponse}
 */
proto.billing_service.ExportCustomerPromotionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportCustomerPromotionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportCustomerPromotionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportCustomerPromotionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportCustomerPromotionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportPartnerPromotionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportPartnerPromotionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportPartnerPromotionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPartnerPromotionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportPartnerPromotionsRequest}
 */
proto.billing_service.ExportPartnerPromotionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportPartnerPromotionsRequest;
  return proto.billing_service.ExportPartnerPromotionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportPartnerPromotionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportPartnerPromotionsRequest}
 */
proto.billing_service.ExportPartnerPromotionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportPartnerPromotionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportPartnerPromotionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportPartnerPromotionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPartnerPromotionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendEmail();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool send_email = 1;
 * @return {boolean}
 */
proto.billing_service.ExportPartnerPromotionsRequest.prototype.getSendEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportPartnerPromotionsRequest} returns this
 */
proto.billing_service.ExportPartnerPromotionsRequest.prototype.setSendEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportPartnerPromotionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportPartnerPromotionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportPartnerPromotionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPartnerPromotionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportPartnerPromotionsResponse}
 */
proto.billing_service.ExportPartnerPromotionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportPartnerPromotionsResponse;
  return proto.billing_service.ExportPartnerPromotionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportPartnerPromotionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportPartnerPromotionsResponse}
 */
proto.billing_service.ExportPartnerPromotionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportPartnerPromotionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportPartnerPromotionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportPartnerPromotionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPartnerPromotionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportPromotionActivitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportPromotionActivitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportPromotionActivitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPromotionActivitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportPromotionActivitiesRequest}
 */
proto.billing_service.ExportPromotionActivitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportPromotionActivitiesRequest;
  return proto.billing_service.ExportPromotionActivitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportPromotionActivitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportPromotionActivitiesRequest}
 */
proto.billing_service.ExportPromotionActivitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportPromotionActivitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportPromotionActivitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportPromotionActivitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPromotionActivitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendEmail();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool send_email = 1;
 * @return {boolean}
 */
proto.billing_service.ExportPromotionActivitiesRequest.prototype.getSendEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportPromotionActivitiesRequest} returns this
 */
proto.billing_service.ExportPromotionActivitiesRequest.prototype.setSendEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportPromotionActivitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportPromotionActivitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportPromotionActivitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPromotionActivitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportPromotionActivitiesResponse}
 */
proto.billing_service.ExportPromotionActivitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportPromotionActivitiesResponse;
  return proto.billing_service.ExportPromotionActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportPromotionActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportPromotionActivitiesResponse}
 */
proto.billing_service.ExportPromotionActivitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportPromotionActivitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportPromotionActivitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportPromotionActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportPromotionActivitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportReferralCustomersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportReferralCustomersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportReferralCustomersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralCustomersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportReferralCustomersRequest}
 */
proto.billing_service.ExportReferralCustomersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportReferralCustomersRequest;
  return proto.billing_service.ExportReferralCustomersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportReferralCustomersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportReferralCustomersRequest}
 */
proto.billing_service.ExportReferralCustomersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportReferralCustomersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportReferralCustomersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportReferralCustomersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralCustomersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendEmail();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool send_email = 1;
 * @return {boolean}
 */
proto.billing_service.ExportReferralCustomersRequest.prototype.getSendEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportReferralCustomersRequest} returns this
 */
proto.billing_service.ExportReferralCustomersRequest.prototype.setSendEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportReferralCustomersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportReferralCustomersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportReferralCustomersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralCustomersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportReferralCustomersResponse}
 */
proto.billing_service.ExportReferralCustomersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportReferralCustomersResponse;
  return proto.billing_service.ExportReferralCustomersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportReferralCustomersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportReferralCustomersResponse}
 */
proto.billing_service.ExportReferralCustomersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportReferralCustomersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportReferralCustomersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportReferralCustomersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportReferralCustomersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest;
  return proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest} returns this
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse;
  return proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertSubscriptionToInternalBillingScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ImportRecurringMileagePackagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ImportRecurringMileagePackagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ImportRecurringMileagePackagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ImportRecurringMileagePackagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ImportRecurringMileagePackagesRequest}
 */
proto.billing_service.ImportRecurringMileagePackagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ImportRecurringMileagePackagesRequest;
  return proto.billing_service.ImportRecurringMileagePackagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ImportRecurringMileagePackagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ImportRecurringMileagePackagesRequest}
 */
proto.billing_service.ImportRecurringMileagePackagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ImportRecurringMileagePackagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ImportRecurringMileagePackagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ImportRecurringMileagePackagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ImportRecurringMileagePackagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ImportRecurringMileagePackagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ImportRecurringMileagePackagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ImportRecurringMileagePackagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ImportRecurringMileagePackagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ImportRecurringMileagePackagesResponse}
 */
proto.billing_service.ImportRecurringMileagePackagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ImportRecurringMileagePackagesResponse;
  return proto.billing_service.ImportRecurringMileagePackagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ImportRecurringMileagePackagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ImportRecurringMileagePackagesResponse}
 */
proto.billing_service.ImportRecurringMileagePackagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ImportRecurringMileagePackagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ImportRecurringMileagePackagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ImportRecurringMileagePackagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ImportRecurringMileagePackagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DeleteOldDraftOrdersFromBillingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DeleteOldDraftOrdersFromBillingRequest}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DeleteOldDraftOrdersFromBillingRequest;
  return proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DeleteOldDraftOrdersFromBillingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DeleteOldDraftOrdersFromBillingRequest}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DeleteOldDraftOrdersFromBillingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DeleteOldDraftOrdersFromBillingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DeleteOldDraftOrdersFromBillingResponse}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DeleteOldDraftOrdersFromBillingResponse;
  return proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DeleteOldDraftOrdersFromBillingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DeleteOldDraftOrdersFromBillingResponse}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DeleteOldDraftOrdersFromBillingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DeleteOldDraftOrdersFromBillingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportToWestlakeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportToWestlakeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportToWestlakeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportToWestlakeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sftpToWestlake: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    sendEmail: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportToWestlakeRequest}
 */
proto.billing_service.ExportToWestlakeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportToWestlakeRequest;
  return proto.billing_service.ExportToWestlakeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportToWestlakeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportToWestlakeRequest}
 */
proto.billing_service.ExportToWestlakeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSftpToWestlake(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportToWestlakeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportToWestlakeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportToWestlakeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportToWestlakeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSftpToWestlake();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSendEmail();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool sftp_to_westlake = 1;
 * @return {boolean}
 */
proto.billing_service.ExportToWestlakeRequest.prototype.getSftpToWestlake = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportToWestlakeRequest} returns this
 */
proto.billing_service.ExportToWestlakeRequest.prototype.setSftpToWestlake = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool send_email = 2;
 * @return {boolean}
 */
proto.billing_service.ExportToWestlakeRequest.prototype.getSendEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ExportToWestlakeRequest} returns this
 */
proto.billing_service.ExportToWestlakeRequest.prototype.setSendEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ExportToWestlakeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ExportToWestlakeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ExportToWestlakeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportToWestlakeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ExportToWestlakeResponse}
 */
proto.billing_service.ExportToWestlakeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ExportToWestlakeResponse;
  return proto.billing_service.ExportToWestlakeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ExportToWestlakeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ExportToWestlakeResponse}
 */
proto.billing_service.ExportToWestlakeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ExportToWestlakeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ExportToWestlakeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ExportToWestlakeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ExportToWestlakeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.WestlakeStagingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.WestlakeStagingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.WestlakeStagingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.WestlakeStagingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.WestlakeStagingStatusRequest}
 */
proto.billing_service.WestlakeStagingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.WestlakeStagingStatusRequest;
  return proto.billing_service.WestlakeStagingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.WestlakeStagingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.WestlakeStagingStatusRequest}
 */
proto.billing_service.WestlakeStagingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.WestlakeStagingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.WestlakeStagingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.WestlakeStagingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.WestlakeStagingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.WestlakeStagingStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.WestlakeStagingStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.WestlakeStagingStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.WestlakeStagingStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stagingWestlakeExports: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.WestlakeStagingStatusResponse}
 */
proto.billing_service.WestlakeStagingStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.WestlakeStagingStatusResponse;
  return proto.billing_service.WestlakeStagingStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.WestlakeStagingStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.WestlakeStagingStatusResponse}
 */
proto.billing_service.WestlakeStagingStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStagingWestlakeExports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.WestlakeStagingStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.WestlakeStagingStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.WestlakeStagingStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.WestlakeStagingStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStagingWestlakeExports();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool staging_westlake_exports = 1;
 * @return {boolean}
 */
proto.billing_service.WestlakeStagingStatusResponse.prototype.getStagingWestlakeExports = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.WestlakeStagingStatusResponse} returns this
 */
proto.billing_service.WestlakeStagingStatusResponse.prototype.setStagingWestlakeExports = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.DraftOrderUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.DraftOrderUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.DraftOrderUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DraftOrderUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderSum: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.DraftOrderUpdated}
 */
proto.billing_service.DraftOrderUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.DraftOrderUpdated;
  return proto.billing_service.DraftOrderUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.DraftOrderUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.DraftOrderUpdated}
 */
proto.billing_service.DraftOrderUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.DraftOrderUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.DraftOrderUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.DraftOrderUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.DraftOrderUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderSum();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.DraftOrderUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DraftOrderUpdated} returns this
 */
proto.billing_service.DraftOrderUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.DraftOrderUpdated.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DraftOrderUpdated} returns this
 */
proto.billing_service.DraftOrderUpdated.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string order_id = 3;
 * @return {string}
 */
proto.billing_service.DraftOrderUpdated.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.DraftOrderUpdated} returns this
 */
proto.billing_service.DraftOrderUpdated.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 order_sum = 4;
 * @return {number}
 */
proto.billing_service.DraftOrderUpdated.prototype.getOrderSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.DraftOrderUpdated} returns this
 */
proto.billing_service.DraftOrderUpdated.prototype.setOrderSum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.Promotion.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.Promotion.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.Promotion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.Promotion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Promotion.toObject = function(includeInstance, msg) {
  var f, obj = {
    promotionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 12, 0),
    promoCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rebateValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rebatePercent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    target: jspb.Message.getFieldWithDefault(msg, 5, 0),
    source: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sourceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sourceName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    validFrom: jspb.Message.getFieldWithDefault(msg, 8, 0),
    validUntil: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    constraintsList: jspb.Message.toObjectList(msg.getConstraintsList(),
    proto.billing_service.PromotionConstraint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.Promotion}
 */
proto.billing_service.Promotion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.Promotion;
  return proto.billing_service.Promotion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.Promotion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.Promotion}
 */
proto.billing_service.Promotion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRebateValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRebatePercent(value);
      break;
    case 5:
      var value = /** @type {!proto.billing_service.PromotionTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 6:
      var value = /** @type {!proto.billing_service.PromotionSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidFrom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValidUntil(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.billing_service.PromotionConstraint;
      reader.readMessage(value,proto.billing_service.PromotionConstraint.deserializeBinaryFromReader);
      msg.addConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.Promotion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.Promotion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.Promotion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Promotion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRebateValue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRebatePercent();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSourceName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getValidFrom();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getValidUntil();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getConstraintsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.billing_service.PromotionConstraint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string promotion_id = 1;
 * @return {string}
 */
proto.billing_service.Promotion.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 version = 12;
 * @return {number}
 */
proto.billing_service.Promotion.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string promo_code = 2;
 * @return {string}
 */
proto.billing_service.Promotion.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 rebate_value = 3;
 * @return {number}
 */
proto.billing_service.Promotion.prototype.getRebateValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setRebateValue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 rebate_percent = 4;
 * @return {number}
 */
proto.billing_service.Promotion.prototype.getRebatePercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setRebatePercent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PromotionTarget target = 5;
 * @return {!proto.billing_service.PromotionTarget}
 */
proto.billing_service.Promotion.prototype.getTarget = function() {
  return /** @type {!proto.billing_service.PromotionTarget} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.billing_service.PromotionTarget} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PromotionSource source = 6;
 * @return {!proto.billing_service.PromotionSource}
 */
proto.billing_service.Promotion.prototype.getSource = function() {
  return /** @type {!proto.billing_service.PromotionSource} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing_service.PromotionSource} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string source_id = 7;
 * @return {string}
 */
proto.billing_service.Promotion.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string source_name = 13;
 * @return {string}
 */
proto.billing_service.Promotion.prototype.getSourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setSourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 valid_from = 8;
 * @return {number}
 */
proto.billing_service.Promotion.prototype.getValidFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setValidFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 valid_until = 9;
 * @return {number}
 */
proto.billing_service.Promotion.prototype.getValidUntil = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setValidUntil = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool status = 10;
 * @return {boolean}
 */
proto.billing_service.Promotion.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated PromotionConstraint constraints = 11;
 * @return {!Array<!proto.billing_service.PromotionConstraint>}
 */
proto.billing_service.Promotion.prototype.getConstraintsList = function() {
  return /** @type{!Array<!proto.billing_service.PromotionConstraint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.PromotionConstraint, 11));
};


/**
 * @param {!Array<!proto.billing_service.PromotionConstraint>} value
 * @return {!proto.billing_service.Promotion} returns this
*/
proto.billing_service.Promotion.prototype.setConstraintsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.billing_service.PromotionConstraint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PromotionConstraint}
 */
proto.billing_service.Promotion.prototype.addConstraints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.billing_service.PromotionConstraint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.Promotion} returns this
 */
proto.billing_service.Promotion.prototype.clearConstraintsList = function() {
  return this.setConstraintsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PromotionPartner.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PromotionPartner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PromotionPartner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionPartner.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PromotionPartner}
 */
proto.billing_service.PromotionPartner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PromotionPartner;
  return proto.billing_service.PromotionPartner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PromotionPartner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PromotionPartner}
 */
proto.billing_service.PromotionPartner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PromotionPartner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PromotionPartner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PromotionPartner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionPartner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.PromotionPartner.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionPartner} returns this
 */
proto.billing_service.PromotionPartner.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 version = 3;
 * @return {number}
 */
proto.billing_service.PromotionPartner.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PromotionPartner} returns this
 */
proto.billing_service.PromotionPartner.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.billing_service.PromotionPartner.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionPartner} returns this
 */
proto.billing_service.PromotionPartner.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.PromotionActivity.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PromotionActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PromotionActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PromotionActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    promotionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    target: jspb.Message.getFieldWithDefault(msg, 6, 0),
    source: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sourceId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    statementId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lineItemIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    transactionIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PromotionActivity}
 */
proto.billing_service.PromotionActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PromotionActivity;
  return proto.billing_service.PromotionActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PromotionActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PromotionActivity}
 */
proto.billing_service.PromotionActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.billing_service.ActivityType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {!proto.billing_service.PromotionTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 7:
      var value = /** @type {!proto.billing_service.PromotionSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatementId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addLineItemIds(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addTransactionIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PromotionActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PromotionActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PromotionActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PromotionActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPromotionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatementId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLineItemIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getTransactionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActivityType type = 2;
 * @return {!proto.billing_service.ActivityType}
 */
proto.billing_service.PromotionActivity.prototype.getType = function() {
  return /** @type {!proto.billing_service.ActivityType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.billing_service.ActivityType} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string promotion_id = 3;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getPromotionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setPromotionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string promo_code = 4;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_id = 5;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PromotionTarget target = 6;
 * @return {!proto.billing_service.PromotionTarget}
 */
proto.billing_service.PromotionActivity.prototype.getTarget = function() {
  return /** @type {!proto.billing_service.PromotionTarget} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.billing_service.PromotionTarget} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional PromotionSource source = 7;
 * @return {!proto.billing_service.PromotionSource}
 */
proto.billing_service.PromotionActivity.prototype.getSource = function() {
  return /** @type {!proto.billing_service.PromotionSource} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.billing_service.PromotionSource} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string source_id = 8;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string subscription_id = 9;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string statement_id = 10;
 * @return {string}
 */
proto.billing_service.PromotionActivity.prototype.getStatementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setStatementId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string line_item_ids = 11;
 * @return {!Array<string>}
 */
proto.billing_service.PromotionActivity.prototype.getLineItemIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setLineItemIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.addLineItemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.clearLineItemIdsList = function() {
  return this.setLineItemIdsList([]);
};


/**
 * repeated string transaction_ids = 12;
 * @return {!Array<string>}
 */
proto.billing_service.PromotionActivity.prototype.getTransactionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.setTransactionIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.addTransactionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.PromotionActivity} returns this
 */
proto.billing_service.PromotionActivity.prototype.clearTransactionIdsList = function() {
  return this.setTransactionIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.Reservation.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.Reservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.Reservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Reservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    created: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updated: jspb.Message.getFieldWithDefault(msg, 9, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, ""),
    color: jspb.Message.getFieldWithDefault(msg, 12, ""),
    secondaryColor: jspb.Message.getFieldWithDefault(msg, 13, ""),
    make: jspb.Message.getFieldWithDefault(msg, 14, ""),
    model: jspb.Message.getFieldWithDefault(msg, 15, ""),
    reservationNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    postal: jspb.Message.getFieldWithDefault(msg, 17, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    email: jspb.Message.getFieldWithDefault(msg, 20, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 21, ""),
    reservationFee: jspb.Message.getFieldWithDefault(msg, 22, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 23, 0),
    startFee: jspb.Message.getFieldWithDefault(msg, 24, 0),
    monthlyRent: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.Reservation}
 */
proto.billing_service.Reservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.Reservation;
  return proto.billing_service.Reservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.Reservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.Reservation}
 */
proto.billing_service.Reservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdated(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryColor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMake(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostal(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReservationFee(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartFee(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyRent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.Reservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.Reservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.Reservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Reservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdated();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSecondaryColor();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMake();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReservationNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPostal();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getReservationFee();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getStartFee();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getMonthlyRent();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string program_id = 4;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 created = 8;
 * @return {number}
 */
proto.billing_service.Reservation.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated = 9;
 * @return {number}
 */
proto.billing_service.Reservation.prototype.getUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string vehicle_id = 10;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string status = 11;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string color = 12;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string secondary_color = 13;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getSecondaryColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setSecondaryColor = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string make = 14;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getMake = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setMake = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string model = 15;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string reservation_number = 16;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getReservationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setReservationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string postal = 17;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getPostal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setPostal = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string first_name = 18;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string last_name = 19;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string email = 20;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string phone = 21;
 * @return {string}
 */
proto.billing_service.Reservation.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 reservation_fee = 22;
 * @return {number}
 */
proto.billing_service.Reservation.prototype.getReservationFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setReservationFee = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 deposit = 23;
 * @return {number}
 */
proto.billing_service.Reservation.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 start_fee = 24;
 * @return {number}
 */
proto.billing_service.Reservation.prototype.getStartFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setStartFee = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 monthly_rent = 25;
 * @return {number}
 */
proto.billing_service.Reservation.prototype.getMonthlyRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Reservation} returns this
 */
proto.billing_service.Reservation.prototype.setMonthlyRent = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.LineItemFE.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.LineItemFE.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.LineItemFE} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.LineItemFE.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 8, 0),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 10, 0),
    title: jspb.Message.getFieldWithDefault(msg, 11, ""),
    baseAmount: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.LineItemFE}
 */
proto.billing_service.LineItemFE.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.LineItemFE;
  return proto.billing_service.LineItemFE.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.LineItemFE} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.LineItemFE}
 */
proto.billing_service.LineItemFE.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBaseAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.LineItemFE.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.LineItemFE.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.LineItemFE} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.LineItemFE.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBaseAmount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.LineItemFE.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 5;
 * @return {string}
 */
proto.billing_service.LineItemFE.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.billing_service.LineItemFE.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 amount = 7;
 * @return {number}
 */
proto.billing_service.LineItemFE.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 balance = 8;
 * @return {number}
 */
proto.billing_service.LineItemFE.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 tax_amount = 9;
 * @return {number}
 */
proto.billing_service.LineItemFE.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 quantity = 10;
 * @return {number}
 */
proto.billing_service.LineItemFE.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string title = 11;
 * @return {string}
 */
proto.billing_service.LineItemFE.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 base_amount = 12;
 * @return {number}
 */
proto.billing_service.LineItemFE.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItemFE} returns this
 */
proto.billing_service.LineItemFE.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.InvoiceFE.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.InvoiceFE.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.InvoiceFE.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.InvoiceFE} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.InvoiceFE.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    date: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dueDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.billing_service.LineItemFE.toObject, includeInstance),
    amount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 10, 0),
    status: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.InvoiceFE}
 */
proto.billing_service.InvoiceFE.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.InvoiceFE;
  return proto.billing_service.InvoiceFE.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.InvoiceFE} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.InvoiceFE}
 */
proto.billing_service.InvoiceFE.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDueDate(value);
      break;
    case 7:
      var value = new proto.billing_service.LineItemFE;
      reader.readMessage(value,proto.billing_service.LineItemFE.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 11:
      var value = /** @type {!proto.billing_service.InvoiceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.InvoiceFE.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.InvoiceFE.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.InvoiceFE} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.InvoiceFE.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDueDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.billing_service.LineItemFE.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.InvoiceFE.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.billing_service.InvoiceFE.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_number = 4;
 * @return {string}
 */
proto.billing_service.InvoiceFE.prototype.getDocumentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setDocumentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 date = 5;
 * @return {number}
 */
proto.billing_service.InvoiceFE.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 due_date = 6;
 * @return {number}
 */
proto.billing_service.InvoiceFE.prototype.getDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated LineItemFE line_items = 7;
 * @return {!Array<!proto.billing_service.LineItemFE>}
 */
proto.billing_service.InvoiceFE.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.billing_service.LineItemFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.LineItemFE, 7));
};


/**
 * @param {!Array<!proto.billing_service.LineItemFE>} value
 * @return {!proto.billing_service.InvoiceFE} returns this
*/
proto.billing_service.InvoiceFE.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.billing_service.LineItemFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.LineItemFE}
 */
proto.billing_service.InvoiceFE.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.billing_service.LineItemFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional int32 amount = 8;
 * @return {number}
 */
proto.billing_service.InvoiceFE.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 tax_amount = 9;
 * @return {number}
 */
proto.billing_service.InvoiceFE.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 balance = 10;
 * @return {number}
 */
proto.billing_service.InvoiceFE.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional InvoiceStatus status = 11;
 * @return {!proto.billing_service.InvoiceStatus}
 */
proto.billing_service.InvoiceFE.prototype.getStatus = function() {
  return /** @type {!proto.billing_service.InvoiceStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.billing_service.InvoiceStatus} value
 * @return {!proto.billing_service.InvoiceFE} returns this
 */
proto.billing_service.InvoiceFE.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.LineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.LineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.LineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.LineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invoiceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.LineItem}
 */
proto.billing_service.LineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.LineItem;
  return proto.billing_service.LineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.LineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.LineItem}
 */
proto.billing_service.LineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceId(value);
      break;
    case 4:
      var value = /** @type {!proto.billing_service.LineItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.LineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.LineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.LineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.LineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.LineItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.billing_service.LineItem.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string invoice_id = 3;
 * @return {string}
 */
proto.billing_service.LineItem.prototype.getInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LineItemType type = 4;
 * @return {!proto.billing_service.LineItemType}
 */
proto.billing_service.LineItem.prototype.getType = function() {
  return /** @type {!proto.billing_service.LineItemType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.billing_service.LineItemType} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string product_id = 5;
 * @return {string}
 */
proto.billing_service.LineItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.billing_service.LineItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 amount = 7;
 * @return {number}
 */
proto.billing_service.LineItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 balance = 8;
 * @return {number}
 */
proto.billing_service.LineItem.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.LineItem} returns this
 */
proto.billing_service.LineItem.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.Invoice.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeStamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    date: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dueDate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.billing_service.LineItem.toObject, includeInstance),
    amount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.Invoice}
 */
proto.billing_service.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.Invoice;
  return proto.billing_service.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.Invoice}
 */
proto.billing_service.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDueDate(value);
      break;
    case 8:
      var value = new proto.billing_service.LineItem;
      reader.readMessage(value,proto.billing_service.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeStamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDueDate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.billing_service.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.Invoice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.billing_service.Invoice.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.billing_service.Invoice.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_number = 4;
 * @return {string}
 */
proto.billing_service.Invoice.prototype.getDocumentNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setDocumentNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 time_stamp = 5;
 * @return {number}
 */
proto.billing_service.Invoice.prototype.getTimeStamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setTimeStamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 date = 6;
 * @return {number}
 */
proto.billing_service.Invoice.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 due_date = 7;
 * @return {number}
 */
proto.billing_service.Invoice.prototype.getDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated LineItem line_items = 8;
 * @return {!Array<!proto.billing_service.LineItem>}
 */
proto.billing_service.Invoice.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.billing_service.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.LineItem, 8));
};


/**
 * @param {!Array<!proto.billing_service.LineItem>} value
 * @return {!proto.billing_service.Invoice} returns this
*/
proto.billing_service.Invoice.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.billing_service.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.LineItem}
 */
proto.billing_service.Invoice.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.billing_service.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * optional int32 amount = 9;
 * @return {number}
 */
proto.billing_service.Invoice.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 balance = 10;
 * @return {number}
 */
proto.billing_service.Invoice.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Invoice} returns this
 */
proto.billing_service.Invoice.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.MileageRecurringBillableItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.MileageRecurringBillableItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.MileageRecurringBillableItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    miles: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.MileageRecurringBillableItem}
 */
proto.billing_service.MileageRecurringBillableItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.MileageRecurringBillableItem;
  return proto.billing_service.MileageRecurringBillableItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.MileageRecurringBillableItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.MileageRecurringBillableItem}
 */
proto.billing_service.MileageRecurringBillableItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMiles(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.MileageRecurringBillableItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.MileageRecurringBillableItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.MileageRecurringBillableItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMiles();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.MileageRecurringBillableItem} returns this
 */
proto.billing_service.MileageRecurringBillableItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional domain.Product.ProductType product_type = 2;
 * @return {!proto.domain.Product.ProductType}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.billing_service.MileageRecurringBillableItem} returns this
 */
proto.billing_service.MileageRecurringBillableItem.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.MileageRecurringBillableItem} returns this
 */
proto.billing_service.MileageRecurringBillableItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 miles = 4;
 * @return {number}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.getMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.MileageRecurringBillableItem} returns this
 */
proto.billing_service.MileageRecurringBillableItem.prototype.setMiles = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 rate = 5;
 * @return {number}
 */
proto.billing_service.MileageRecurringBillableItem.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.MileageRecurringBillableItem} returns this
 */
proto.billing_service.MileageRecurringBillableItem.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetMileageRecurringBillableItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetMileageRecurringBillableItemRequest}
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetMileageRecurringBillableItemRequest;
  return proto.billing_service.GetMileageRecurringBillableItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetMileageRecurringBillableItemRequest}
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetMileageRecurringBillableItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.GetMileageRecurringBillableItemRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetOwnMileageRecurringBillableItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest}
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetOwnMileageRecurringBillableItemRequest;
  return proto.billing_service.GetOwnMileageRecurringBillableItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest}
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetOwnMileageRecurringBillableItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.GetOwnMileageRecurringBillableItemRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetMileageRecurringBillableItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetMileageRecurringBillableItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mileageRecurringBillableItem: (f = msg.getMileageRecurringBillableItem()) && proto.billing_service.MileageRecurringBillableItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetMileageRecurringBillableItemResponse}
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetMileageRecurringBillableItemResponse;
  return proto.billing_service.GetMileageRecurringBillableItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetMileageRecurringBillableItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetMileageRecurringBillableItemResponse}
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.MileageRecurringBillableItem;
      reader.readMessage(value,proto.billing_service.MileageRecurringBillableItem.deserializeBinaryFromReader);
      msg.setMileageRecurringBillableItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetMileageRecurringBillableItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetMileageRecurringBillableItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMileageRecurringBillableItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.billing_service.MileageRecurringBillableItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional MileageRecurringBillableItem mileage_recurring_billable_item = 1;
 * @return {?proto.billing_service.MileageRecurringBillableItem}
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.prototype.getMileageRecurringBillableItem = function() {
  return /** @type{?proto.billing_service.MileageRecurringBillableItem} */ (
    jspb.Message.getWrapperField(this, proto.billing_service.MileageRecurringBillableItem, 1));
};


/**
 * @param {?proto.billing_service.MileageRecurringBillableItem|undefined} value
 * @return {!proto.billing_service.GetMileageRecurringBillableItemResponse} returns this
*/
proto.billing_service.GetMileageRecurringBillableItemResponse.prototype.setMileageRecurringBillableItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing_service.GetMileageRecurringBillableItemResponse} returns this
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.prototype.clearMileageRecurringBillableItem = function() {
  return this.setMileageRecurringBillableItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing_service.GetMileageRecurringBillableItemResponse.prototype.hasMileageRecurringBillableItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateMileageRecurringBillableItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mileageRecurringBillableItem: (f = msg.getMileageRecurringBillableItem()) && proto.billing_service.MileageRecurringBillableItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateMileageRecurringBillableItemRequest}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateMileageRecurringBillableItemRequest;
  return proto.billing_service.UpdateMileageRecurringBillableItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateMileageRecurringBillableItemRequest}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new proto.billing_service.MileageRecurringBillableItem;
      reader.readMessage(value,proto.billing_service.MileageRecurringBillableItem.deserializeBinaryFromReader);
      msg.setMileageRecurringBillableItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateMileageRecurringBillableItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMileageRecurringBillableItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing_service.MileageRecurringBillableItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MileageRecurringBillableItem mileage_recurring_billable_item = 3;
 * @return {?proto.billing_service.MileageRecurringBillableItem}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.getMileageRecurringBillableItem = function() {
  return /** @type{?proto.billing_service.MileageRecurringBillableItem} */ (
    jspb.Message.getWrapperField(this, proto.billing_service.MileageRecurringBillableItem, 3));
};


/**
 * @param {?proto.billing_service.MileageRecurringBillableItem|undefined} value
 * @return {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} returns this
*/
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.setMileageRecurringBillableItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.clearMileageRecurringBillableItem = function() {
  return this.setMileageRecurringBillableItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing_service.UpdateMileageRecurringBillableItemRequest.prototype.hasMileageRecurringBillableItem = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RemoveMileageRecurringBillableItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mileageRecurringBillableItemId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RemoveMileageRecurringBillableItemRequest}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RemoveMileageRecurringBillableItemRequest;
  return proto.billing_service.RemoveMileageRecurringBillableItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RemoveMileageRecurringBillableItemRequest}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRecurringBillableItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RemoveMileageRecurringBillableItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMileageRecurringBillableItemId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 mileage_recurring_billable_item_id = 3;
 * @return {number}
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.getMileageRecurringBillableItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.RemoveMileageRecurringBillableItemRequest.prototype.setMileageRecurringBillableItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mileageRecurringBillableItemId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest}
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest;
  return proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest}
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMileageRecurringBillableItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMileageRecurringBillableItemId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 mileage_recurring_billable_item_id = 2;
 * @return {number}
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.prototype.getMileageRecurringBillableItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest.prototype.setMileageRecurringBillableItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.AddMileageRecurringBillableItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mileageRecurringBillableItem: (f = msg.getMileageRecurringBillableItem()) && proto.billing_service.MileageRecurringBillableItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.AddMileageRecurringBillableItemRequest}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.AddMileageRecurringBillableItemRequest;
  return proto.billing_service.AddMileageRecurringBillableItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.AddMileageRecurringBillableItemRequest}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new proto.billing_service.MileageRecurringBillableItem;
      reader.readMessage(value,proto.billing_service.MileageRecurringBillableItem.deserializeBinaryFromReader);
      msg.setMileageRecurringBillableItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.AddMileageRecurringBillableItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMileageRecurringBillableItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.billing_service.MileageRecurringBillableItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MileageRecurringBillableItem mileage_recurring_billable_item = 3;
 * @return {?proto.billing_service.MileageRecurringBillableItem}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.getMileageRecurringBillableItem = function() {
  return /** @type{?proto.billing_service.MileageRecurringBillableItem} */ (
    jspb.Message.getWrapperField(this, proto.billing_service.MileageRecurringBillableItem, 3));
};


/**
 * @param {?proto.billing_service.MileageRecurringBillableItem|undefined} value
 * @return {!proto.billing_service.AddMileageRecurringBillableItemRequest} returns this
*/
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.setMileageRecurringBillableItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.billing_service.AddMileageRecurringBillableItemRequest} returns this
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.clearMileageRecurringBillableItem = function() {
  return this.setMileageRecurringBillableItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.billing_service.AddMileageRecurringBillableItemRequest.prototype.hasMileageRecurringBillableItem = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateRecurringBillingDayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateRecurringBillingDayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateRecurringBillingDayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recurringBillableDay: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateRecurringBillingDayRequest}
 */
proto.billing_service.UpdateRecurringBillingDayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateRecurringBillingDayRequest;
  return proto.billing_service.UpdateRecurringBillingDayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateRecurringBillingDayRequest}
 */
proto.billing_service.UpdateRecurringBillingDayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecurringBillableDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateRecurringBillingDayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateRecurringBillingDayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateRecurringBillingDayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecurringBillableDay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.UpdateRecurringBillingDayRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateRecurringBillingDayRequest} returns this
 */
proto.billing_service.UpdateRecurringBillingDayRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 recurring_billable_day = 2;
 * @return {number}
 */
proto.billing_service.UpdateRecurringBillingDayRequest.prototype.getRecurringBillableDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdateRecurringBillingDayRequest} returns this
 */
proto.billing_service.UpdateRecurringBillingDayRequest.prototype.setRecurringBillableDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateRecurringBillingDayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateRecurringBillingDayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateRecurringBillingDayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateRecurringBillingDayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    previousDueDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nextDueDate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateRecurringBillingDayResponse}
 */
proto.billing_service.UpdateRecurringBillingDayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateRecurringBillingDayResponse;
  return proto.billing_service.UpdateRecurringBillingDayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateRecurringBillingDayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateRecurringBillingDayResponse}
 */
proto.billing_service.UpdateRecurringBillingDayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPreviousDueDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextDueDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateRecurringBillingDayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateRecurringBillingDayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateRecurringBillingDayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateRecurringBillingDayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreviousDueDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNextDueDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 previous_due_date = 1;
 * @return {number}
 */
proto.billing_service.UpdateRecurringBillingDayResponse.prototype.getPreviousDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdateRecurringBillingDayResponse} returns this
 */
proto.billing_service.UpdateRecurringBillingDayResponse.prototype.setPreviousDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 next_due_date = 2;
 * @return {number}
 */
proto.billing_service.UpdateRecurringBillingDayResponse.prototype.getNextDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.UpdateRecurringBillingDayResponse} returns this
 */
proto.billing_service.UpdateRecurringBillingDayResponse.prototype.setNextDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RecurringBillableItem.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RecurringBillableItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RecurringBillableItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RecurringBillableItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RecurringBillableItem}
 */
proto.billing_service.RecurringBillableItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RecurringBillableItem;
  return proto.billing_service.RecurringBillableItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RecurringBillableItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RecurringBillableItem}
 */
proto.billing_service.RecurringBillableItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.Product.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RecurringBillableItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RecurringBillableItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RecurringBillableItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RecurringBillableItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.billing_service.RecurringBillableItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.RecurringBillableItem} returns this
 */
proto.billing_service.RecurringBillableItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional domain.Product.ProductType product_type = 2;
 * @return {!proto.domain.Product.ProductType}
 */
proto.billing_service.RecurringBillableItem.prototype.getProductType = function() {
  return /** @type {!proto.domain.Product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.Product.ProductType} value
 * @return {!proto.billing_service.RecurringBillableItem} returns this
 */
proto.billing_service.RecurringBillableItem.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.billing_service.RecurringBillableItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.RecurringBillableItem} returns this
 */
proto.billing_service.RecurringBillableItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListRecurringBillableItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListRecurringBillableItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListRecurringBillableItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListRecurringBillableItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListRecurringBillableItemsRequest}
 */
proto.billing_service.ListRecurringBillableItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListRecurringBillableItemsRequest;
  return proto.billing_service.ListRecurringBillableItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListRecurringBillableItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListRecurringBillableItemsRequest}
 */
proto.billing_service.ListRecurringBillableItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListRecurringBillableItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListRecurringBillableItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListRecurringBillableItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListRecurringBillableItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.ListRecurringBillableItemsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ListRecurringBillableItemsRequest} returns this
 */
proto.billing_service.ListRecurringBillableItemsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.ListRecurringBillableItemsRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.ListRecurringBillableItemsRequest} returns this
 */
proto.billing_service.ListRecurringBillableItemsRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListRecurringBillableItemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListRecurringBillableItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListRecurringBillableItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListRecurringBillableItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListRecurringBillableItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurringBillableItemsList: jspb.Message.toObjectList(msg.getRecurringBillableItemsList(),
    proto.billing_service.RecurringBillableItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListRecurringBillableItemsResponse}
 */
proto.billing_service.ListRecurringBillableItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListRecurringBillableItemsResponse;
  return proto.billing_service.ListRecurringBillableItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListRecurringBillableItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListRecurringBillableItemsResponse}
 */
proto.billing_service.ListRecurringBillableItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.RecurringBillableItem;
      reader.readMessage(value,proto.billing_service.RecurringBillableItem.deserializeBinaryFromReader);
      msg.addRecurringBillableItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListRecurringBillableItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListRecurringBillableItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListRecurringBillableItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListRecurringBillableItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurringBillableItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.RecurringBillableItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RecurringBillableItem recurring_billable_items = 1;
 * @return {!Array<!proto.billing_service.RecurringBillableItem>}
 */
proto.billing_service.ListRecurringBillableItemsResponse.prototype.getRecurringBillableItemsList = function() {
  return /** @type{!Array<!proto.billing_service.RecurringBillableItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.RecurringBillableItem, 1));
};


/**
 * @param {!Array<!proto.billing_service.RecurringBillableItem>} value
 * @return {!proto.billing_service.ListRecurringBillableItemsResponse} returns this
*/
proto.billing_service.ListRecurringBillableItemsResponse.prototype.setRecurringBillableItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.RecurringBillableItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.RecurringBillableItem}
 */
proto.billing_service.ListRecurringBillableItemsResponse.prototype.addRecurringBillableItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.RecurringBillableItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListRecurringBillableItemsResponse} returns this
 */
proto.billing_service.ListRecurringBillableItemsResponse.prototype.clearRecurringBillableItemsList = function() {
  return this.setRecurringBillableItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.AddRecurringBillableItemsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.AddRecurringBillableItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.AddRecurringBillableItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddRecurringBillableItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recurringBillableItemsList: jspb.Message.toObjectList(msg.getRecurringBillableItemsList(),
    proto.billing_service.RecurringBillableItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.AddRecurringBillableItemsRequest}
 */
proto.billing_service.AddRecurringBillableItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.AddRecurringBillableItemsRequest;
  return proto.billing_service.AddRecurringBillableItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.AddRecurringBillableItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.AddRecurringBillableItemsRequest}
 */
proto.billing_service.AddRecurringBillableItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new proto.billing_service.RecurringBillableItem;
      reader.readMessage(value,proto.billing_service.RecurringBillableItem.deserializeBinaryFromReader);
      msg.addRecurringBillableItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.AddRecurringBillableItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.AddRecurringBillableItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.AddRecurringBillableItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecurringBillableItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing_service.RecurringBillableItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddRecurringBillableItemsRequest} returns this
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.AddRecurringBillableItemsRequest} returns this
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RecurringBillableItem recurring_billable_items = 3;
 * @return {!Array<!proto.billing_service.RecurringBillableItem>}
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.getRecurringBillableItemsList = function() {
  return /** @type{!Array<!proto.billing_service.RecurringBillableItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.RecurringBillableItem, 3));
};


/**
 * @param {!Array<!proto.billing_service.RecurringBillableItem>} value
 * @return {!proto.billing_service.AddRecurringBillableItemsRequest} returns this
*/
proto.billing_service.AddRecurringBillableItemsRequest.prototype.setRecurringBillableItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing_service.RecurringBillableItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.RecurringBillableItem}
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.addRecurringBillableItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing_service.RecurringBillableItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.AddRecurringBillableItemsRequest} returns this
 */
proto.billing_service.AddRecurringBillableItemsRequest.prototype.clearRecurringBillableItemsList = function() {
  return this.setRecurringBillableItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.UpdateRecurringBillableItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recurringBillableItemsList: jspb.Message.toObjectList(msg.getRecurringBillableItemsList(),
    proto.billing_service.RecurringBillableItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.UpdateRecurringBillableItemsRequest}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.UpdateRecurringBillableItemsRequest;
  return proto.billing_service.UpdateRecurringBillableItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.UpdateRecurringBillableItemsRequest}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new proto.billing_service.RecurringBillableItem;
      reader.readMessage(value,proto.billing_service.RecurringBillableItem.deserializeBinaryFromReader);
      msg.addRecurringBillableItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.UpdateRecurringBillableItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecurringBillableItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.billing_service.RecurringBillableItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateRecurringBillableItemsRequest} returns this
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.UpdateRecurringBillableItemsRequest} returns this
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RecurringBillableItem recurring_billable_items = 3;
 * @return {!Array<!proto.billing_service.RecurringBillableItem>}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.getRecurringBillableItemsList = function() {
  return /** @type{!Array<!proto.billing_service.RecurringBillableItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.RecurringBillableItem, 3));
};


/**
 * @param {!Array<!proto.billing_service.RecurringBillableItem>} value
 * @return {!proto.billing_service.UpdateRecurringBillableItemsRequest} returns this
*/
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.setRecurringBillableItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.billing_service.RecurringBillableItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.RecurringBillableItem}
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.addRecurringBillableItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.billing_service.RecurringBillableItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.UpdateRecurringBillableItemsRequest} returns this
 */
proto.billing_service.UpdateRecurringBillableItemsRequest.prototype.clearRecurringBillableItemsList = function() {
  return this.setRecurringBillableItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.RemoveRecurringBillableItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recurringBillableItemIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.RemoveRecurringBillableItemsRequest;
  return proto.billing_service.RemoveRecurringBillableItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRecurringBillableItemIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.RemoveRecurringBillableItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecurringBillableItemIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest} returns this
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest} returns this
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 recurring_billable_item_ids = 3;
 * @return {!Array<number>}
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.getRecurringBillableItemIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest} returns this
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.setRecurringBillableItemIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest} returns this
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.addRecurringBillableItemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.RemoveRecurringBillableItemsRequest} returns this
 */
proto.billing_service.RemoveRecurringBillableItemsRequest.prototype.clearRecurringBillableItemIdsList = function() {
  return this.setRecurringBillableItemIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListOwnSubscriptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListOwnSubscriptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListOwnSubscriptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListOwnSubscriptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListOwnSubscriptionsRequest}
 */
proto.billing_service.ListOwnSubscriptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListOwnSubscriptionsRequest;
  return proto.billing_service.ListOwnSubscriptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListOwnSubscriptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListOwnSubscriptionsRequest}
 */
proto.billing_service.ListOwnSubscriptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListOwnSubscriptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListOwnSubscriptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListOwnSubscriptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListOwnSubscriptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListSubscriptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListSubscriptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListSubscriptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListSubscriptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListSubscriptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionsList: jspb.Message.toObjectList(msg.getSubscriptionsList(),
    proto.billing_service.Subscription.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListSubscriptionsResponse}
 */
proto.billing_service.ListSubscriptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListSubscriptionsResponse;
  return proto.billing_service.ListSubscriptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListSubscriptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListSubscriptionsResponse}
 */
proto.billing_service.ListSubscriptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.Subscription;
      reader.readMessage(value,proto.billing_service.Subscription.deserializeBinaryFromReader);
      msg.addSubscriptions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListSubscriptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListSubscriptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListSubscriptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListSubscriptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.Subscription.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Subscription subscriptions = 1;
 * @return {!Array<!proto.billing_service.Subscription>}
 */
proto.billing_service.ListSubscriptionsResponse.prototype.getSubscriptionsList = function() {
  return /** @type{!Array<!proto.billing_service.Subscription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.Subscription, 1));
};


/**
 * @param {!Array<!proto.billing_service.Subscription>} value
 * @return {!proto.billing_service.ListSubscriptionsResponse} returns this
*/
proto.billing_service.ListSubscriptionsResponse.prototype.setSubscriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.Subscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.Subscription}
 */
proto.billing_service.ListSubscriptionsResponse.prototype.addSubscriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.Subscription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListSubscriptionsResponse} returns this
 */
proto.billing_service.ListSubscriptionsResponse.prototype.clearSubscriptionsList = function() {
  return this.setSubscriptionsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.billing_service.ListSubscriptionsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.ListSubscriptionsResponse} returns this
 */
proto.billing_service.ListSubscriptionsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.Subscription.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.Subscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.Subscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Subscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pickupDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    activationDate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    vehicleAssignmentDate: jspb.Message.getFieldWithDefault(msg, 12, 0),
    startingOdometer: jspb.Message.getFieldWithDefault(msg, 13, 0),
    recurringBillingDay: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.Subscription}
 */
proto.billing_service.Subscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.Subscription;
  return proto.billing_service.Subscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.Subscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.Subscription}
 */
proto.billing_service.Subscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupDate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActivationDate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVehicleAssignmentDate(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartingOdometer(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecurringBillingDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.Subscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.Subscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.Subscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.Subscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPickupDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getActivationDate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getVehicleAssignmentDate();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStartingOdometer();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getRecurringBillingDay();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.billing_service.Subscription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.billing_service.Subscription.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 6;
 * @return {string}
 */
proto.billing_service.Subscription.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.billing_service.Subscription.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 pickup_date = 9;
 * @return {number}
 */
proto.billing_service.Subscription.prototype.getPickupDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setPickupDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.billing_service.Subscription.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 activation_date = 11;
 * @return {number}
 */
proto.billing_service.Subscription.prototype.getActivationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setActivationDate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 vehicle_assignment_date = 12;
 * @return {number}
 */
proto.billing_service.Subscription.prototype.getVehicleAssignmentDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setVehicleAssignmentDate = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 starting_odometer = 13;
 * @return {number}
 */
proto.billing_service.Subscription.prototype.getStartingOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setStartingOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 recurring_billing_day = 14;
 * @return {number}
 */
proto.billing_service.Subscription.prototype.getRecurringBillingDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.Subscription} returns this
 */
proto.billing_service.Subscription.prototype.setRecurringBillingDay = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.SyncTaxedProducts.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.SyncTaxedProducts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.SyncTaxedProducts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SyncTaxedProducts.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.SyncTaxedProducts}
 */
proto.billing_service.SyncTaxedProducts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.SyncTaxedProducts;
  return proto.billing_service.SyncTaxedProducts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.SyncTaxedProducts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.SyncTaxedProducts}
 */
proto.billing_service.SyncTaxedProducts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.SyncTaxedProducts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.SyncTaxedProducts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.SyncTaxedProducts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.SyncTaxedProducts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PurchaseOneTimeMileageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PurchaseOneTimeMileageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    miles: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PurchaseOneTimeMileageRequest}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PurchaseOneTimeMileageRequest;
  return proto.billing_service.PurchaseOneTimeMileageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PurchaseOneTimeMileageRequest}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PurchaseOneTimeMileageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PurchaseOneTimeMileageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMiles();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PurchaseOneTimeMileageRequest} returns this
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PurchaseOneTimeMileageRequest} returns this
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 miles = 3;
 * @return {number}
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.getMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PurchaseOneTimeMileageRequest} returns this
 */
proto.billing_service.PurchaseOneTimeMileageRequest.prototype.setMiles = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PurchaseRecurringMileageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PurchaseRecurringMileageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PurchaseRecurringMileageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    miles: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PurchaseRecurringMileageRequest}
 */
proto.billing_service.PurchaseRecurringMileageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PurchaseRecurringMileageRequest;
  return proto.billing_service.PurchaseRecurringMileageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PurchaseRecurringMileageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PurchaseRecurringMileageRequest}
 */
proto.billing_service.PurchaseRecurringMileageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMiles(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PurchaseRecurringMileageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PurchaseRecurringMileageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PurchaseRecurringMileageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMiles();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.PurchaseRecurringMileageRequest} returns this
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PurchaseRecurringMileageRequest} returns this
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 miles = 4;
 * @return {number}
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.getMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PurchaseRecurringMileageRequest} returns this
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.setMiles = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 rate = 5;
 * @return {number}
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.PurchaseRecurringMileageRequest} returns this
 */
proto.billing_service.PurchaseRecurringMileageRequest.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.BillPunitiveMileageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.BillPunitiveMileageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.BillPunitiveMileageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.BillPunitiveMileageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    miles: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.BillPunitiveMileageRequest}
 */
proto.billing_service.BillPunitiveMileageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.BillPunitiveMileageRequest;
  return proto.billing_service.BillPunitiveMileageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.BillPunitiveMileageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.BillPunitiveMileageRequest}
 */
proto.billing_service.BillPunitiveMileageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.BillPunitiveMileageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.BillPunitiveMileageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.BillPunitiveMileageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.BillPunitiveMileageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiles();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.billing_service.BillPunitiveMileageRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.billing_service.BillPunitiveMileageRequest} returns this
 */
proto.billing_service.BillPunitiveMileageRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 miles = 3;
 * @return {number}
 */
proto.billing_service.BillPunitiveMileageRequest.prototype.getMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.BillPunitiveMileageRequest} returns this
 */
proto.billing_service.BillPunitiveMileageRequest.prototype.setMiles = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.BatchDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.BatchDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.BatchDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.BatchDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.BatchDateRequest}
 */
proto.billing_service.BatchDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.BatchDateRequest;
  return proto.billing_service.BatchDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.BatchDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.BatchDateRequest}
 */
proto.billing_service.BatchDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.BatchDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.BatchDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.BatchDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.BatchDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 date = 1;
 * @return {number}
 */
proto.billing_service.BatchDateRequest.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.billing_service.BatchDateRequest} returns this
 */
proto.billing_service.BatchDateRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumbersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse}
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse;
  return proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse}
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccountNumbers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string account_numbers = 1;
 * @return {!Array<string>}
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.prototype.getAccountNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.prototype.setAccountNumbersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.prototype.addAccountNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.GetAccountsMissingSubscriptionInvoiceByDateResponse.prototype.clearAccountNumbersList = function() {
  return this.setAccountNumbersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ConvertSubscriptionOrdersByDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.billing_service.InvoiceFE.toObject, includeInstance),
    failedAccountNumbersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ConvertSubscriptionOrdersByDateResponse;
  return proto.billing_service.ConvertSubscriptionOrdersByDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.InvoiceFE;
      reader.readMessage(value,proto.billing_service.InvoiceFE.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedAccountNumbers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ConvertSubscriptionOrdersByDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.InvoiceFE.serializeBinaryToWriter
    );
  }
  f = message.getFailedAccountNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated InvoiceFE invoices = 1;
 * @return {!Array<!proto.billing_service.InvoiceFE>}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.billing_service.InvoiceFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.InvoiceFE, 1));
};


/**
 * @param {!Array<!proto.billing_service.InvoiceFE>} value
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} returns this
*/
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.InvoiceFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.InvoiceFE}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.InvoiceFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} returns this
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};


/**
 * repeated string failed_account_numbers = 2;
 * @return {!Array<string>}
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.getFailedAccountNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} returns this
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.setFailedAccountNumbersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} returns this
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.addFailedAccountNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ConvertSubscriptionOrdersByDateResponse} returns this
 */
proto.billing_service.ConvertSubscriptionOrdersByDateResponse.prototype.clearFailedAccountNumbersList = function() {
  return this.setFailedAccountNumbersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.PaySubscriptionInvoiceByDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.billing_service.InvoiceFE.toObject, includeInstance),
    failedAccountNumbersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.PaySubscriptionInvoiceByDateResponse;
  return proto.billing_service.PaySubscriptionInvoiceByDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.InvoiceFE;
      reader.readMessage(value,proto.billing_service.InvoiceFE.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedAccountNumbers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.PaySubscriptionInvoiceByDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.InvoiceFE.serializeBinaryToWriter
    );
  }
  f = message.getFailedAccountNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated InvoiceFE invoices = 1;
 * @return {!Array<!proto.billing_service.InvoiceFE>}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.billing_service.InvoiceFE>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.InvoiceFE, 1));
};


/**
 * @param {!Array<!proto.billing_service.InvoiceFE>} value
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} returns this
*/
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.setInvoicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.InvoiceFE=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.InvoiceFE}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.InvoiceFE, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.clearInvoicesList = function() {
  return this.setInvoicesList([]);
};


/**
 * repeated string failed_account_numbers = 2;
 * @return {!Array<string>}
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.getFailedAccountNumbersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.setFailedAccountNumbersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.addFailedAccountNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.PaySubscriptionInvoiceByDateResponse} returns this
 */
proto.billing_service.PaySubscriptionInvoiceByDateResponse.prototype.clearFailedAccountNumbersList = function() {
  return this.setFailedAccountNumbersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListProgramPricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListProgramPricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListProgramPricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramPricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    removehiddenprograms: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    removehiddenprices: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListProgramPricingRequest}
 */
proto.billing_service.ListProgramPricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListProgramPricingRequest;
  return proto.billing_service.ListProgramPricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListProgramPricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListProgramPricingRequest}
 */
proto.billing_service.ListProgramPricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemovehiddenprograms(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemovehiddenprices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListProgramPricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListProgramPricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListProgramPricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramPricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemovehiddenprograms();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRemovehiddenprices();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool RemoveHiddenPrograms = 1;
 * @return {boolean}
 */
proto.billing_service.ListProgramPricingRequest.prototype.getRemovehiddenprograms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ListProgramPricingRequest} returns this
 */
proto.billing_service.ListProgramPricingRequest.prototype.setRemovehiddenprograms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool RemoveHiddenPrices = 2;
 * @return {boolean}
 */
proto.billing_service.ListProgramPricingRequest.prototype.getRemovehiddenprices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.billing_service.ListProgramPricingRequest} returns this
 */
proto.billing_service.ListProgramPricingRequest.prototype.setRemovehiddenprices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.billing_service.ListProgramPricingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.billing_service.ListProgramPricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.billing_service.ListProgramPricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.billing_service.ListProgramPricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramPricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    programsList: jspb.Message.toObjectList(msg.getProgramsList(),
    proto.billing_service.Program.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.billing_service.ListProgramPricingResponse}
 */
proto.billing_service.ListProgramPricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.billing_service.ListProgramPricingResponse;
  return proto.billing_service.ListProgramPricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.billing_service.ListProgramPricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.billing_service.ListProgramPricingResponse}
 */
proto.billing_service.ListProgramPricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.billing_service.Program;
      reader.readMessage(value,proto.billing_service.Program.deserializeBinaryFromReader);
      msg.addPrograms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.billing_service.ListProgramPricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.billing_service.ListProgramPricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.billing_service.ListProgramPricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.billing_service.ListProgramPricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.billing_service.Program.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Program programs = 1;
 * @return {!Array<!proto.billing_service.Program>}
 */
proto.billing_service.ListProgramPricingResponse.prototype.getProgramsList = function() {
  return /** @type{!Array<!proto.billing_service.Program>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.billing_service.Program, 1));
};


/**
 * @param {!Array<!proto.billing_service.Program>} value
 * @return {!proto.billing_service.ListProgramPricingResponse} returns this
*/
proto.billing_service.ListProgramPricingResponse.prototype.setProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.billing_service.Program=} opt_value
 * @param {number=} opt_index
 * @return {!proto.billing_service.Program}
 */
proto.billing_service.ListProgramPricingResponse.prototype.addPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.billing_service.Program, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.billing_service.ListProgramPricingResponse} returns this
 */
proto.billing_service.ListProgramPricingResponse.prototype.clearProgramsList = function() {
  return this.setProgramsList([]);
};


/**
 * @enum {number}
 */
proto.billing_service.PromotionSource = {
  PARTNER: 0,
  CUSTOMER: 1
};

/**
 * @enum {number}
 */
proto.billing_service.PromotionTarget = {
  START_PAYMENT: 0,
  FIRST_MONTH: 1
};

/**
 * @enum {number}
 */
proto.billing_service.ActivityType = {
  APPLIED_TO_ORDER: 0,
  REMOVED_FROM_ORDER: 1,
  CONVERTED: 2,
  ATTRIBUTED: 3
};

/**
 * @enum {number}
 */
proto.billing_service.InvoiceType = {
  RESERVATION: 0,
  SECURITY_DEPOSIT: 1,
  DRIVE_OFF: 2,
  MONTHLY: 3,
  ONE_TIME: 4
};

/**
 * @enum {number}
 */
proto.billing_service.InvoiceStatus = {
  ISSUED: 0,
  DUE: 1,
  PAID_IN_FULL: 2,
  PAID_PARTIAL: 3,
  PAST_DUE: 4,
  VOIDED: 5
};

/**
 * @enum {number}
 */
proto.billing_service.LineItemType = {
  RESERVATION_FEE: 0,
  SECURITY_DEPOSIT_TYPE: 1,
  START_PAYMENT_TYPE: 2,
  VEHICLE_RENT: 3,
  OPTION_RENT: 4,
  INSURANCE_PREMIUM: 5,
  ONE_TIME_CHARGE: 6,
  TAX: 7,
  DISCOUNT: 8,
  DELIVERY: 9,
  CITATION: 10
};

goog.object.extend(exports, proto.billing_service);
