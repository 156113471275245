import { NexusService } from 'global-apis/nexus-service'
import { useQuery } from 'react-query'
import {
  ListRecurringBillableItemsResponse,
  RecurringBillableItem,
} from '@nxcr-org/web-api-interface/lib/billing_service_pb'
import { useState } from 'react'
import { cancelInsurancePolicy } from 'global-apis/nexus-service-insurance'

export function useCustomerBilling(customerId: string, subscriptionId: string) {
  const [isEditingSubscription, setIsEditingSubscription] = useState(false)
  const [subscriptionAmount, setSubscriptionAmount] = useState(0)

  function useRecurringBillableItems() {
    const {
      data: recurringBillableItems,
      error: recurringBillableItemsError,
      refetch,
    } = useQuery(
      ['/recurringBillableItems'],
      () =>
        NexusService.listRecurringBillableItems(
          customerId,
          subscriptionId
        ).then((res) => {
          setSubscriptionAmount(
            res.recurringBillableItemsList.find((rbi) => rbi.productType === 1)
              .amount
          )
          return res
        }),
      {
        placeholderData: {} as ListRecurringBillableItemsResponse.AsObject,
        onError() {
          console.log('Error: ', recurringBillableItemsError)
        },
        enabled: !!subscriptionId,
      }
    )

    return {
      recurringBillableItems:
        recurringBillableItems?.recurringBillableItemsList,
      refetchBillableItems: refetch,
    }
  }

  function updateSubscriptionAmount(
    recurringBillableItems: RecurringBillableItem.AsObject[]
  ) {
    const {
      data: updateRecurringBillableItems,
      error: updateRecurringBillableItemsError,
      refetch,
    } = useQuery(
      ['/updateRecurringBillableItems'],
      () =>
        NexusService.updateRecurringBillableItems(
          customerId,
          subscriptionId,
          buildRecurringBillableItems(recurringBillableItems)
        ).then((res) => {
          return res
        }),
      {
        placeholderData: {} as ListRecurringBillableItemsResponse.AsObject,
        onError() {
          console.log('Error: ', updateRecurringBillableItemsError)
        },
        refetchOnWindowFocus: false,
        enabled: !!subscriptionId,
      }
    )

    function buildRecurringBillableItems(
      recurringBillableItems: RecurringBillableItem.AsObject[]
    ) {
      const subscriptionRecurringBillableItems = recurringBillableItems.find(
        (rbi) => rbi.productType === 1
      )
      const newRecurringBillableItem: RecurringBillableItem =
        new RecurringBillableItem()
      newRecurringBillableItem.setAmount(subscriptionAmount)
      newRecurringBillableItem.setId(subscriptionRecurringBillableItems.id)
      newRecurringBillableItem.setProductType(
        subscriptionRecurringBillableItems.productType
      )
      return [newRecurringBillableItem]
    }

    return {
      updatedRecurringBillableItems:
        updateRecurringBillableItems?.recurringBillableItemsList,
      doUpdateSubscriptionAmount: refetch,
    }
  }

  function cancelInsurance() {
    const {
      data: success,
      error: cancelInsuranceError,
      refetch,
    } = useQuery(
      ['/cancelInsurance'],
      () =>
        cancelInsurancePolicy(customerId).then((res) => {
          return res
        }),
      {
        onError() {
          console.log('Error: ', cancelInsuranceError)
        },
        refetchOnWindowFocus: false,
        enabled: false,
      }
    )
    return {
      success,
      doCancelInsurance: refetch,
    }
  }

  function deleteMileagePackage(
    recurringBillableItems: RecurringBillableItem.AsObject[]
  ) {
    const {
      data: success,
      error: deleteMileagePackageError,
      refetch,
    } = useQuery(
      ['/deleteMileagePackage'],
      () =>
        NexusService.removeMileageRecurringBillableItem(
          customerId,
          subscriptionId,
          getMileagePackageId(recurringBillableItems)
        ).then((res) => {
          return res
        }),
      {
        onError() {
          console.log('Error: ', deleteMileagePackageError)
        },
        refetchOnWindowFocus: false,
        enabled: false,
      }
    )

    function getMileagePackageId(
      recurringBillableItems: RecurringBillableItem.AsObject[]
    ) {
      const mileageRecurringBillableItems = recurringBillableItems.find(
        (rbi) => rbi.productType === 23
      )
      return mileageRecurringBillableItems.id
    }

    return {
      success,
      doDeleteMileagePackage: refetch,
    }
  }

  return {
    useRecurringBillableItems,
    updateSubscriptionAmount,
    isEditingSubscription,
    setIsEditingSubscription,
    subscriptionAmount,
    setSubscriptionAmount,
    cancelInsurance,
    deleteMileagePackage,
  }
}
