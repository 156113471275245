import { Button, Grid, IconButton, Typography } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import {
  GetSubscriptionMileagePurchasesResponse,
  GetSubscriptionMileageResponse,
} from '@nxcr-org/web-api-interface/lib/fleet_service_pb'
import {
  getSubscriptionMileage,
  getSubscriptionMileagePurchases,
} from 'global-apis/nexus-service-fleet'
import React, { Fragment } from 'react'
import { useQuery } from 'react-query'
import { formatMoney } from '../../../utils/currency-formatter'
import { formatDate } from '../../../utils/date-formatter'
import { getGridRowClass } from '../../../utils/getGridRowClass'
import { intlFormatNumber } from '../../fleet-management/utils'
import { format, parseISO } from 'date-fns'

import DeleteIcon from '@mui/icons-material/Delete'

import DeleteDialog from 'shared/DeleteDialog'
import toast from 'react-hot-toast'
import { useCreatePunitiveMiles } from './useCreatePunitiveMiles'
import { useCustomerContext } from '../CustomerContext'
import { PunitiveMilesModal } from './punitive-miles-modal/PunitiveMilesModal'

interface MileageError {
  code: number
  message: string
  metadata?: object
}

function SubscriptionMileage({ subscriptionId }) {
  const [open, setOpen] = React.useState(false)
  const [milePurchaseRow, setMilePurchaseRow] = React.useState(null)
  const { deleteMilesPurchasedById } = useCreatePunitiveMiles(subscriptionId)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { customerName, customerInfo } = useCustomerContext()
  const purchaseColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Miles',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Paid',
      flex: 1,
    },
    {
      field: 'time',
      headerName: 'Purchase Date',
      flex: 1,
    },
    {
      field: 'referenceId',
      headerName: 'Reference ID',
      flex: 3,
    },
    {
      field: 'delete',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <IconButton
          className="xsIcon"
          onClick={() => {
            handleOpenDeleteMiles(params?.row)
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]
  const handleOpenDeleteMiles = (row) => {
    setMilePurchaseRow(row)
  }
  const onDeleteMilesConfirm = async () => {
    if (!milePurchaseRow || !milePurchaseRow.id) {
      toast.error('Please select a record to delete.')
      return
    }

    try {
      const result = await deleteMilesPurchasedById(milePurchaseRow.id)
      if (result.success) {
        setMilePurchaseRow(null)
        toast.success('Record deleted.')
      }
    } catch (error) {
      console.error('Error deleting mileage: ', error)
      toast.error('An error occurred while deleting the record.')
    }
  }

  const handleCloseDeleteMiles = () => setMilePurchaseRow(null)
  const { data, error, isLoading } = useQuery(
    ['subscriptionMileage', subscriptionId],
    () => getSubscriptionMileage(subscriptionId),
    {
      placeholderData: {} as GetSubscriptionMileageResponse.AsObject,
      onError(err) {
        console.error('Error fetching mileage: ', err)
      },
    }
  )
  const purchasesQuery = useQuery(
    ['subscriptionMileagePurchases', subscriptionId],
    () => getSubscriptionMileagePurchases(subscriptionId),
    {
      placeholderData: {} as GetSubscriptionMileagePurchasesResponse.AsObject,
      onError(err) {
        console.error('Error fetching mileage: ', err)
      },
    }
  )

  if (isLoading || purchasesQuery.isLoading) {
    return <div>Loading...</div>
  }

  function isMileageError(error: any): error is MileageError {
    return (
      error &&
      typeof error === 'object' &&
      'code' in error &&
      'message' in error
    )
  }

  if (isMileageError(error)) {
    return <div>Error: {error?.message}</div>
  } else if (purchasesQuery.error) {
    let message = 'Unknown Error'
    if (purchasesQuery.error instanceof Error)
      message = purchasesQuery.error.message
    return <div>Error getting mileage purchases: {message}</div>
  }

  const allottedMinusDriven = data.totalAllotted - data.totalDriven
  const overAllottedMiles = allottedMinusDriven < 0
  const milesDrivenSummary = overAllottedMiles
    ? 'Over Allottment'
    : 'Within Allottment'
  purchasesQuery.data.purchasesList

  let prepaidExtraSection: React.JSX.Element
  if (data.prepaidExtra) {
    prepaidExtraSection = (
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="h4">Mileage Purchases</Typography>
        </Grid>
        <MileageGridItem
          label="Total Miles"
          value={intlFormatNumber(data.prepaidExtra.totalAmount)}
        />
        <MileageGridItem
          label="Total Paid (including tax)"
          value={formatMoney(data.prepaidExtra.totalPrice)}
        />
        <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
          <Button onClick={handleOpen} variant="contained">
            Add Punitive Miles
          </Button>
        </Grid>
        <DataGrid
          autoHeight
          rowHeight={35}
          sx={{ pt: 2 }}
          isRowSelectable={() => true}
          getRowClassName={(params) =>
            getGridRowClass(params.indexRelativeToCurrentPage)
          }
          columns={purchaseColumns}
          rows={purchasesQuery?.data?.purchasesList?.map((purchase) => ({
            id: purchase.id,
            amount: purchase.amount,
            price: formatMoney(purchase.price),
            time: formatDate(purchase.time),
            referenceId: purchase.referenceId,
          }))}
        />
      </Fragment>
    )
  }

  let drivenByMonthListSection: React.JSX.Element
  if (data.drivenByMonthList) {
    drivenByMonthListSection = (
      <Fragment>
        <Grid item xs={12}>
          <Typography variant="h4">Last 6 Months of Miles Driven</Typography>
        </Grid>
        <DataGrid
          autoHeight
          rowHeight={35}
          columns={drivenHistoryColumns}
          rows={data.drivenByMonthList.map((month) => ({
            date: format(parseISO(month.date), 'MMM y'),
            amount: month.amount,
          }))}
          getRowId={(row) => row.date}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Fragment>
    )
  }

  return (
    <>
      {milePurchaseRow ? (
        <DeleteDialog
          open={Boolean(milePurchaseRow?.id)}
          handleClose={handleCloseDeleteMiles}
          title="Delete"
          description={`Are you sure you would like to delete the purchase of ${milePurchaseRow.amount} miles on ${milePurchaseRow.time} for customer ${customerName} ${customerInfo.accountNumber}?`}
          onConfirm={onDeleteMilesConfirm}
        />
      ) : null}
      <PunitiveMilesModal
        open={open}
        onClose={handleClose}
        subscriptionId={subscriptionId}
      />
      <Grid container spacing={2} p={3} direction="row">
        <Grid item xs={12} md={6}>
          <Typography>
            Mileage for subscription id: {subscriptionId || 'N/A'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Summary</Typography>
        </Grid>
        <MileageGridItem
          label="Miles Driven Summary"
          value={milesDrivenSummary}
        />
        <MileageGridItem
          label="Number of Miles Over"
          value={
            overAllottedMiles
              ? intlFormatNumber(Math.abs(allottedMinusDriven))
              : 'N/A'
          }
        />
        <MileageGridItem
          label="Recurring Plan"
          value={'Unknown - see Invoices tab'}
        />

        <Grid item xs={12}>
          <Typography variant="h4">Odometer</Typography>
        </Grid>
        <MileageGridItem
          label="Starting Odometer"
          value={intlFormatNumber(data.startingOdometer)}
        />
        <MileageGridItem
          label="Last Odometer"
          value={intlFormatNumber(data.lastOdometer)}
        />

        <Grid item xs={12}>
          <Typography variant="h4">Overall Stats</Typography>
        </Grid>
        <MileageGridItem
          label="Total Allotted"
          value={intlFormatNumber(data.totalAllotted)}
        />
        <MileageGridItem
          label="Total Driven"
          value={intlFormatNumber(data.totalDriven)}
        />
        <MileageGridItem
          label="Allotted Per Month"
          value={intlFormatNumber(data.allottedPerMonth)}
        />
        <MileageGridItem
          label="Driven Per Month"
          value={intlFormatNumber(data.drivenPerMonth)}
        />

        {prepaidExtraSection}

        {drivenByMonthListSection}
      </Grid>
    </>
  )
}

interface MileageGridItemksProps {
  label: string
  value?: string
}

function MileageGridItem({ label, value }: MileageGridItemksProps) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography>{label}</Typography>
      <div>{value || 'N/A'}</div>
    </Grid>
  )
}

export default SubscriptionMileage

const drivenHistoryColumns: GridColumns = [
  {
    field: 'date',
    headerName: 'Month',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Miles Driven',
    flex: 1,
  },
]
